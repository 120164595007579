import { useStyles } from "./UsersSelection.styles";
import { UserRole } from "../../utils";
import useUsers, { UserSelection } from "../../../../hooks/useUsers";
import { Grid, Typography, TextField } from "@material-ui/core";
import { useState } from "react";

interface ViewOnlyUsersProps {
	initialSelectedUserIds: UserRole[];
}

export const ViewOnlyUsers = ({ initialSelectedUserIds }: ViewOnlyUsersProps) => {
	const classes = useStyles();
	const { users } = useUsers();

	const [searchTerm, setSearchTerm] = useState<string>("");
	const [selectedLeadership, setSelectedLeadership] = useState<UserSelection[]>(() =>
		users.filter(user => initialSelectedUserIds.some(id => id.user === user.customId) && user.leadership)
	);
	const [selectedTeamLead, setSelectedTeamLead] = useState<UserSelection[]>(() =>
		users.filter(user => initialSelectedUserIds.some(id => id.user === user.customId) && user.role === "teamLead" && !user.leadership)
	);
	const [selectedSalesRep, setSelectedSalesRep] = useState<UserSelection[]>(() =>
		users.filter(user => initialSelectedUserIds.some(id => id.user === user.customId) && user.role === "salesRep")
	);

	const filteredLeadership = selectedLeadership.filter(user => user.name.toLowerCase().includes(searchTerm.toLowerCase()));
	const filteredTeamLead = selectedTeamLead.filter(user => user.name.toLowerCase().includes(searchTerm.toLowerCase()));
	const filteredSalesRep = selectedSalesRep.filter(user => user.name.toLowerCase().includes(searchTerm.toLowerCase()));

	return (
		<div>
			<TextField
				label="Search Users"
				variant="outlined"
				size="small"
				fullWidth
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				style={{ marginBottom: '20px', width: '200px', borderRadius: '5px' }}
			/>
			<div className={classes.viewContainer}>
				<Typography className={classes.viewTitle}>Leadership</Typography>
				<div style={{ maxHeight: '200px', overflowY: 'auto', display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px' }}>
					{filteredLeadership.length === 0 ? (
						<Typography variant="body2">No Leadership</Typography>
					) : (
						filteredLeadership.map(user => (
							<Typography key={user.customId} className={`${classes.userName} ${classes.userCard}`}>
								{user.name}
							</Typography>
						))
					)}
				</div>

				<Typography className={classes.viewTitle}>Team Lead</Typography>
				<div style={{ maxHeight: '200px', overflowY: 'auto', display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px' }}>
					{filteredTeamLead.length === 0 ? (
						<Typography variant="body2">No Team Leads</Typography>
					) : (
						filteredTeamLead.map(user => (
							<Typography key={user.customId} className={`${classes.userName} ${classes.userCard}`}>
								{user.name}
							</Typography>
						))
					)}
				</div>

				<Typography className={classes.viewTitle}>Sales Reps</Typography>
				<div style={{ maxHeight: '200px', overflowY: 'auto', display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px' }}>
					{filteredSalesRep.length === 0 ? (
						<Typography variant="body2">No Sales Reps</Typography>
					) : (
						filteredSalesRep.map(user => (
							<Typography key={user.customId} className={`${classes.userName} ${classes.userCard}`}>
								{user.name}
							</Typography>
						))
					)}
				</div>
			</div>
		</div>
	);
};
