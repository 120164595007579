import { TextField, FormControl, FormLabel, Typography, Checkbox, FormControlLabel } from "@material-ui/core";
import { DateRange } from "react-date-range";
import { Item, isIncentive } from "../../../types/commonObjIncTypes";
import { useState } from "react";
import { useStyles } from "./NameDescriptionPeriodComponent.styles";
import moment from "moment-timezone";
import { Incentive } from "../../../types/incentivesTypes";
import { Objective } from "../../../types/objectiveTypes";
import { useFormContext } from "react-hook-form";
import { formatTime } from "../../../../objectivesIncentives/utils/utils";
import { formatDate } from "../../../../objectivesIncentives/utils/utils";
import { Schedule } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";

interface NameDescriptionPeriodComponentProps<T> {
	item: T;
	handleChange: (name: string, value: string | number | boolean) => void;
}

export const NameDescriptionPeriodComponent = <T extends Item>({
	item,
	handleChange,
}: NameDescriptionPeriodComponentProps<T>) => {
	const classes = useStyles();
	const { register, errors } = useFormContext();

	const isTeamLead = useSelector(
		(state: RootState) => state.session.role === "teamLead"
	);

	const [periodDates, setPeriodDates] = useState<any[]>([
		{
			startDate: item.customPeriodStart
				? moment(item.customPeriodStart).tz("America/Chicago").toDate()
				: new Date(),
			endDate: item.customPeriodEnd
				? moment(item.customPeriodEnd).tz("America/Chicago").toDate()
				: new Date(),
			key: "selection",
		},
	]);

	const handlePeriodChange = (item: any) => {
		const { startDate, endDate } = item.selection;

		setPeriodDates([item.selection]);

		if (startDate && endDate) {
			handleChange("customPeriodStart", startDate);
			handleChange("customPeriodEnd", endDate);
		}
	};

	const isSubItem =
		(isIncentive(item) && (item as Incentive).isSubIncentive) ||
		(!isIncentive(item) && (item as Objective).isSubObjective);

	return (
		<div className={classes.container}>
			<div className={classes.nameDescriptionContainer}>
				<TextField
					size="small"
					error={!!errors.name}
					helperText={errors.name && errors.name.message}
					name="name"
					id="name"
					variant="outlined"
					label="Name"
					type="text"
					fullWidth
					defaultValue={item.name}
					onChange={(event) => handleChange("name", event.target.value)}
					inputRef={register({
						required: "Name is required",
					})}
					disabled={isTeamLead}
				/>
				<TextField
					margin="dense"
					id="description"
					label="Description"
					variant="outlined"
					multiline
					minRows={7}
					maxRows={14}
					fullWidth
					name="description"
					value={item.description}
					onChange={(event) => handleChange("description", event.target.value)}
					disabled={isTeamLead}
				/>
				{item?.lastRunAt && (
					<Typography variant="body2" className={classes.lastRunAt}>
						<Schedule fontSize="small" style={{ verticalAlign: 'middle', marginRight: '4px' }} />
						Last run at:{" "}
						{`${formatDate(item.lastRunAt)} at ${formatTime(item.lastRunAt)} CST`}
					</Typography>
				)}
			</div>
			<div>
				{isSubItem && (
					<FormControl component="fieldset">
						<FormControlLabel
							control={<Checkbox
								checked={item.isCustomPeriod}
								onChange={(event) => handleChange("isCustomPeriod", event.target.checked)}
							/>}
							label="Custom period from parent"
							style={{ marginLeft: '10px', marginBottom: '10px' }}
						/>
					</FormControl>
				)}
				{(!isSubItem || !item.isCustomPeriod) && (
					<FormControl
						component="fieldset"
						className={classes.periodFormControl}
					>
						<FormLabel component="legend">Period</FormLabel>
						<div style={{ position: 'relative' }}>
							<DateRange
								editableDateInputs={true}
								onChange={(item) => handlePeriodChange(item)}
								moveRangeOnFirstSelection={false}
								ranges={periodDates}
							/>
							{isTeamLead && (
								<div
									style={{
										position: 'absolute',
										top: 0,
										left: 0,
										right: 0,
										bottom: 0,
										backgroundColor: 'rgba(255, 255, 255, 0.5)',
										cursor: 'not-allowed',
									}}
								/>
							)}
						</div>
					</FormControl>
				)}
			</div>
		</div>
	);
};
