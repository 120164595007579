import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { AppThunk } from "../../app/store";
import { ISliceStatus, IUser, IWholesaler } from "../../interfaces";
import { usd, volume, delta } from "../../utils/format";
import { batch } from "react-redux";
import exportCsv from "../../utils/exportCsv";
import {
	fetched as usersWarehouseFetched,
	statusChanged as usersWarehouseStatusChanged,
} from "../../warehouse/usersWarehouse";
import { IGeneralQuery } from "../../hooks/useQuery";

type cellColor = "success" | "error";
export interface ITableCell {
	content: string;
	value: number | string | { name: string; customId: string }[];
	tooltip?: string;
	color?: cellColor;
}
export type ITableRows = ITableCell[][];
export type IExecutiveReportGroup = "leadership" | "teams" | "salesreps";
export const groups: IExecutiveReportGroup[] = [
	"leadership",
	"teams",
	"salesreps",
];
type ISortDirection = "asc" | "desc";
export type ITableName =
	| "summary"
	| "mtdGp"
	| "mtdVolume"
	| "ytdGp"
	| "ytdVolume";

interface IInfoBlock {
	visible: boolean;
	title: string;
	columns: string[];
	rows: ITableRows;
	sortColumnName?: string;
	sortDirection: ISortDirection;
	hiddenColumns: number[];
	total: ITableCell[];
}
interface IExecutiveReportState {
	group: IExecutiveReportGroup;
	target: ITargetOption;
	hoverId?: string;
	selectId?: string;
	summary: {
		title: string;
		columns: string[];
		rows: ITableRows;
		sortColumnName?: string;
		sortDirection: ISortDirection;
		hiddenColumns: number[];
		total: ITableCell[];
	};
	time?: {
		daysPast: number;
		daysLeft: number;
		lastTransacionDate: Date;
	};
	mtdGp: IInfoBlock;
	mtdVolume: IInfoBlock;
	ytdGp: IInfoBlock;
	ytdVolume: IInfoBlock;
	status: ISliceStatus;
}

export type ITargetOption = "vs LY" | "vs Goal";
export const targetOptions: ITargetOption[] = ["vs LY", "vs Goal"];

const initialState: IExecutiveReportState = {
	group: "salesreps",
	target: "vs LY",
	hoverId: undefined,
	selectId: undefined,
	summary: {
		title: "Executive Summary",
		sortColumnName: undefined,
		sortDirection: "asc",
		hiddenColumns: [1, 6],
		columns: [
			"Name",
			"GP PEM",
			"GP PEM vs LY",
			"GP YTD",
			"GP Goal",
			"GP LY",
			"Vol PEM",
			"Vol PEM vs LY",
			"Vol YTD",
			"Vol Goal",
			"Vol LY",
		],
		rows: [],
		total: [],
	},
	mtdGp: {
		visible: true,
		title: "MTD Gross Profit",
		columns: ["Name", "Realized", "Goal", "Last Year", "% of LY", "% of Goal"],
		sortColumnName: undefined,
		sortDirection: "asc",
		hiddenColumns: [2, 5],
		rows: [],
		total: [],
	},
	mtdVolume: {
		visible: true,
		title: "MTD Vol",
		columns: ["Name", "Realized", "Goal", "Last Year", "% of LY", "% of Goal"],
		sortColumnName: undefined,
		sortDirection: "asc",
		hiddenColumns: [2, 5],
		rows: [],
		total: [],
	},
	ytdGp: {
		visible: true,
		title: "YTD Gross Profit",
		columns: ["Name", "Realized", "Last Year", "% of LY"],
		sortColumnName: undefined,
		sortDirection: "asc",
		hiddenColumns: [],
		rows: [],
		total: [],
	},
	ytdVolume: {
		visible: true,
		title: "YTD Vol",
		columns: ["Name", "Realized", "Last Year", "% of LY"],
		sortColumnName: undefined,
		sortDirection: "asc",
		hiddenColumns: [],
		rows: [],
		total: [],
	},
	status: "idle",
};

interface ISetOrderPayload {
	table: ITableName;
	sortColumnName: string;
	sortDirection: ISortDirection;
}

export const executiveReportSlice = createSlice({
	name: "executiveReport",
	initialState,
	reducers: {
		setTarget: (state, action: PayloadAction<ITargetOption>) => {
			state.target = action.payload;
			if (action.payload === "vs Goal") {
				state.ytdGp.visible = false;
				state.ytdVolume.visible = false;
				state.summary.hiddenColumns = [2, 3, 5, 7, 8, 10];
				state.mtdGp.hiddenColumns = [3, 4];
				state.mtdVolume.hiddenColumns = [3, 4];
			} else {
				state.ytdGp.visible = true;
				state.ytdVolume.visible = true;
				state.summary.hiddenColumns = [1, 6];
				state.mtdGp.hiddenColumns = [2, 5];
				state.mtdVolume.hiddenColumns = [2, 5];
			}
		},
		startLoading: (state) => {
			state.status = "loading";
		},
		loadingSuccess: (state) => {
			state.status = "ready";
		},
		loadingEmpty: (state) => {
			state.status = "empty";
		},
		setSelectIndex: (state, action: PayloadAction<string>) => {
			state.selectId = action.payload;
		},
		setHoverIndex: (state, action: PayloadAction<string>) => {
			state.hoverId = action.payload;
		},
		setGroup: (state, action: PayloadAction<IExecutiveReportGroup>) => {
			state.summary.sortColumnName = undefined;
			state.mtdGp.sortColumnName = undefined;
			state.mtdVolume.sortColumnName = undefined;
			state.ytdGp.sortColumnName = undefined;
			state.ytdVolume.sortColumnName = undefined;
			state.group = action.payload;
		},
		usersFetched: (state, action: PayloadAction<IUser[]>) => {
			let summaryRows: ITableRows = [];
			let mtdGpRows: ITableRows = [];
			let mtdVolumeRows: ITableRows = [];
			let ytdGpRows: ITableRows = [];
			let ytdVolumeRows: ITableRows = [];

			action.payload.forEach((user) => {
				summaryRows.push([
					{
						content: user.name,
						value: user.name,
						tooltip: `Routes: ${user.routeNumber}`,
					},
					{
						content: delta(user.gp.achievedSellingDaysMonth),
						value: user.gp.achievedSellingDaysMonth,
						color:
							user.gp.achievedSellingDaysMonth >= 100 ? "success" : "error",
					},
					{
						content: delta(user.gp.pemVsLyMonth),
						value: user.gp.pemVsLyMonth,
						color: user.gp.pemVsLyMonth >= user.gp.goal ? "success" : "error",
					},
					{
						content: delta(user.gp.deltaYTD),
						value: user.gp.deltaYTD,
						color: user.gp.deltaYTD >= 100 ? "success" : "error",
					},
					{
						content: delta(user.gp.goal),
						value: user.gp.goal,
					},
					{
						content: usd(user.gp.lastYearMonth),
						value: user.gp.lastYearMonth,
					},
					{
						content: delta(user.volume.achievedSellingDaysMonth),
						value: user.volume.achievedSellingDaysMonth,
						color:
							user.volume.achievedSellingDaysMonth >= 100 ? "success" : "error",
					},
					{
						content: delta(user.volume.pemVsLyMonth),
						value: user.volume.pemVsLyMonth,
						color:
							user.volume.pemVsLyMonth >= user.volume.goal
								? "success"
								: "error",
					},
					{
						content: delta(user.volume.deltaYTD),
						value: user.volume.deltaYTD,
						color: user.volume.deltaYTD >= 100 ? "success" : "error",
					},
					{
						content: delta(user.volume.goal),
						value: user.volume.goal,
					},
					{
						content: volume(user.volume.lastYearMonth),
						value: user.volume.lastYearMonth,
					},
				]);

				mtdGpRows.push([
					{ content: user.name, value: user.name },
					{ content: usd(user.gp.thisYearMonth), value: user.gp.thisYearMonth },
					{
						content: usd((user.gp.goal / 100) * user.gp.lastYearMonth),
						value: (user.gp.goal / 100) * user.gp.lastYearMonth,
					},
					{ content: usd(user.gp.lastYearMonth), value: user.gp.lastYearMonth },
					{
						content: delta(
							(user.gp.thisYearMonth / user.gp.lastYearMonth) * 100
						),
						value: (user.gp.thisYearMonth / user.gp.lastYearMonth) * 100,
					},
					{
						content: delta(
							(user.gp.thisYearMonth /
								(user.gp.lastYearMonth * (user.gp.goal / 100))) *
								100
						),
						value:
							(user.gp.thisYearMonth /
								(user.gp.lastYearMonth * (user.gp.goal / 100))) *
							100,
					},
				]);
				mtdVolumeRows.push([
					{ content: user.name, value: user.name },
					{
						content: volume(user.volume.thisYearMonth),
						value: user.volume.thisYearMonth,
					},
					{
						content: volume(
							(user.volume.goal / 100) * user.volume.lastYearMonth
						),
						value: (user.volume.goal / 100) * user.volume.lastYearMonth,
					},
					{
						content: volume(user.volume.lastYearMonth),
						value: user.volume.lastYearMonth,
					},
					{
						content: delta(
							(user.volume.thisYearMonth / user.volume.lastYearMonth) * 100
						),
						value:
							(user.volume.thisYearMonth / user.volume.lastYearMonth) * 100,
					},
					{
						content: delta(
							(user.volume.thisYearMonth /
								(user.volume.lastYearMonth * (user.volume.goal / 100))) *
								100
						),
						value:
							(user.volume.thisYearMonth /
								(user.volume.lastYearMonth * (user.volume.goal / 100))) *
							100,
					},
				]);
				ytdGpRows.push([
					{ content: user.name, value: user.name },
					{ content: usd(user.gp.thisYearYTD), value: user.gp.thisYearYTD },
					{
						content: usd(user.gp.lastYearYTD),
						value: user.gp.lastYearYTD,
					},
					{
						content: `${delta(user.gp.deltaYTD)}`,
						value: user.gp.deltaYTD,
					},
				]);
				ytdVolumeRows.push([
					{ content: user.name, value: user.name },
					{
						content: volume(user.volume.thisYearYTD),
						value: user.volume.thisYearYTD,
					},
					{
						content: volume(user.volume.lastYearYTD),
						value: user.volume.lastYearYTD,
					},
					{
						content: `${delta(user.volume.deltaYTD)}`,
						value: user.volume.deltaYTD,
					},
				]);
			});

			state.summary.rows = summaryRows;
			state.mtdGp.rows = mtdGpRows;
			state.mtdVolume.rows = mtdVolumeRows;
			state.ytdGp.rows = ytdGpRows;
			state.ytdVolume.rows = ytdVolumeRows;
		},
		setOrder: (state, action: PayloadAction<ISetOrderPayload>) => {
			const { table, sortColumnName, sortDirection } = action.payload;
			const sortColumnIndex = state[table].columns.indexOf(sortColumnName);
			state[table].sortColumnName = sortColumnName;
			state[table].sortDirection = sortDirection;
			const currentRows = current(state[table].rows).slice();
			state[table].rows = currentRows.sort((a, b) => {
				const aVal = a[sortColumnIndex].value;
				const bVal = b[sortColumnIndex].value;
				if (aVal > bVal) {
					return sortDirection === "asc" ? -1 : 1;
				} else if (aVal < bVal) {
					return sortDirection === "asc" ? 1 : -1;
				} else {
					return 0;
				}
			});
		},
	},
	extraReducers: {
		"session/logOut": () => initialState,
		"wholesalerWarehouse/detailsLoaded": (
			state,
			action: PayloadAction<IWholesaler>
		) => {
			const wh = action.payload;
			const firstCell: ITableCell = {
				content: "Total",
				value: "Total",
			};
			const summaryTotal: ITableCell[] = [
				firstCell,
				{
					content: delta(wh.gp?.pemVsGoal),
					value: wh.gp?.pemVsGoal,
				},
				{
					content: delta(wh.gp?.delta + 100),
					value: wh.gp?.delta + 100,
				},
				{
					content: delta(wh.gp?.deltaYTD + 100),
					value: wh.gp?.deltaYTD + 100,
				},
				{
					content: delta(wh.gpCompanyGoal),
					value: wh.gpCompanyGoal,
				},
				{
					content: usd(wh.gp?.lastYear),
					value: wh.gp?.lastYear,
				},
				{
					content: delta(wh.volume?.delta + 100),
					value: wh.volume?.delta + 100,
				},
				{
					content: delta((wh.volume?.thisYear / wh.volume?.lastYearMTD) * 100),
					value: (wh.volume?.thisYear / wh.volume?.lastYearMTD) * 100,
				},
				{
					content: delta(wh.volume?.deltaYTD + 100),
					value: wh.volume?.deltaYTD + 100,
				},
				{
					content: delta(wh.volumeCompanyGoal),
					value: wh.volumeCompanyGoal,
				},
				{
					content: volume(wh.volume?.lastYear),
					value: wh.volume?.lastYear,
				},
			];

			const mtdGpTotal: ITableCell[] = [
				firstCell,
				{ content: usd(wh.gp?.thisYear), value: wh.gp?.thisYear },
				{
					content: usd((wh.gpCompanyGoal / 100) * wh.gp?.lastYear),
					value: (wh.gpCompanyGoal / 100) * wh.gp?.lastYear,
				},
				{ content: usd(wh.gp?.lastYear), value: wh.gp?.lastYear },
				{
					content: delta((wh.gp?.thisYear / wh.gp?.lastYear) * 100),
					value: (wh.gp?.thisYear / wh.gp?.lastYear) * 100,
				},
				{
					content: delta(wh.gp?.vsGoal),
					value: wh.gp?.vsGoal,
				},
			];
			const mtdVolumeTotal: ITableCell[] = [
				firstCell,
				{
					content: volume(wh.volume?.thisYear),
					value: wh.volume?.thisYear,
				},
				{
					content: volume((wh.volumeCompanyGoal / 100) * wh.volume?.lastYear),
					value: (wh.volumeCompanyGoal / 100) * wh.volume?.lastYear,
				},
				{
					content: volume(wh.volume?.lastYear),
					value: wh.volume?.lastYear,
				},
				{
					content: delta((wh.volume?.thisYear / wh.volume?.lastYear) * 100),
					value: (wh.volume?.thisYear / wh.volume?.lastYear) * 100,
				},
				{
					content: delta(wh.volume?.vsGoal),
					value: wh.volume?.vsGoal,
				},
			];
			const ytdGpTotal: ITableCell[] = [
				firstCell,
				{ content: usd(wh.gp?.thisYearYTD), value: wh.gp?.thisYearYTD },
				{
					content: usd(wh.gp?.lastYearYTD),
					value: wh.gp?.lastYearYTD,
				},
				{
					content: `${delta(wh.gp?.deltaYTD + 100)}`,
					value: wh.gp?.deltaYTD + 100,
				},
			];
			const ytdVolumeTotal: ITableCell[] = [
				firstCell,
				{
					content: volume(wh.volume?.thisYearYTD),
					value: wh.volume?.thisYearYTD,
				},
				{
					content: volume(wh.volume?.lastYearYTD),
					value: wh.volume?.lastYearYTD,
				},
				{
					content: `${delta(wh.volume?.deltaYTD + 100)}`,
					value: wh.volume?.deltaYTD + 100,
				},
			];

			state.summary.total = summaryTotal;
			state.mtdGp.total = mtdGpTotal;
			state.mtdVolume.total = mtdVolumeTotal;
			state.ytdGp.total = ytdGpTotal;
			state.ytdVolume.total = ytdVolumeTotal;
		},
	},
});

export const {
	setGroup,
	usersFetched,
	setSelectIndex,
	setHoverIndex,
	startLoading,
	loadingSuccess,
	loadingEmpty,
	setOrder,
	setTarget,
} = executiveReportSlice.actions;

export const getUsers =
	(query: IGeneralQuery): AppThunk =>
	async (dispatch, getState) => {
		dispatch(startLoading());
		const { executiveReport, usersWarehouse } = getState();

		let users: IUser[] = [];
		if (
			usersWarehouse.status !== "ready" ||
			usersWarehouse.db !== query.db ||
			usersWarehouse.wholesalerId !== query.wholesalerId
		) {
			dispatch(usersWarehouseStatusChanged("loading"));
			const res = await api.fetch({
				path: "/users",
				method: "GET",
				query,
			});
			dispatch(
				usersWarehouseFetched({
					items: res.payload,
					wholesalerId: query.wholesalerId,
					db: query.db,
				})
			);
			users = res.payload;
		} else {
			Object.keys(usersWarehouse.usersById).forEach((userId) => {
				users.push(usersWarehouse.usersById[userId]);
			});
		}

		const filter = (() => {
			switch (executiveReport.group) {
				case "leadership":
					return (user: IUser) => user.role === "teamLead" && user.leadership;
				case "teams":
					return (user: IUser) => user.role === "teamLead" && !user.leadership;
				default:
					return (user: IUser) => user.role === "salesRep";
			}
		})();

		users = users.filter(filter);
		batch(() => {
			if (users.length) {
				dispatch(loadingSuccess());
			} else {
				dispatch(loadingEmpty());
			}
			dispatch(usersFetched(users));
		});
	};

export const downloadCsv = (): AppThunk => async (_, getState) => {
	const { summary, mtdGp, ytdGp, mtdVolume, ytdVolume } =
		getState().executiveReport;
	const tables = [summary, mtdGp, ytdGp, mtdVolume, ytdVolume];
	tables.forEach((table) => {
		const rows = table.rows.map((row) => row.map((cell) => cell.content));
		exportCsv(table.title, [table.columns].concat(rows));
	});
};

export default executiveReportSlice.reducer;
