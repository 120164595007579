import { useEffect, useState } from "react";
import {
	Button,
	DialogContent,
	Dialog,
	DialogTitle,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
	CircularProgress,
	Checkbox,
	Typography,
	Box,
	FormControlLabel,
	Tooltip,
} from "@material-ui/core";
import {
	MeasureState,
	ObjectiveTypeEnum,
	ObjectiveUser,
} from "../../types/objectiveTypes";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { capitalizeFirstLetter } from "../../utils";
import { useStyles } from "./EditComponent.styles";
import {
	CustomObjIncAccountFilters,
	CustomObjIncProductFilters,
	Item,
	MeasureSymbol,
} from "../../types/commonObjIncTypes";
import { NameDescriptionPeriodComponent } from "./nameDescriptionPeriod/NameDescriptionPeriodComponent";
import { PayoutDetails, getPayoutType, initializeMeasures } from "./utils";
import { TargetComponent } from "./targetComponent";
import { PayoutComponent } from "./payoutComponents";
import { ExpandMore } from "@material-ui/icons";
import { ManualAccountsSelection } from "./accountsProductsUsersSelect/accountsSelect/ManualAccountsSelection";
import { QuantityForm } from "../quantityObjective/QuantityForm";
import { useFormContext } from "react-hook-form";
import { CustomAccountsSelection } from "./accountsProductsUsersSelect/accountsSelect/CustomAccountsSelection";
import { CustomProductsSelection } from "./accountsProductsUsersSelect/productsSelect/CustomProductsSelection";
import { IncentiveUser } from "../../types/incentivesTypes";
import SelectionTabs from "./accountsProductsUsersSelect/SelectionTabs";
import ManualProductsSelection from "./accountsProductsUsersSelect/productsSelect/ManualProductsSelection";
import MinimumQuantityComponent from "./minimumQuantityComponent/MinimumQuantityComponent";
import { RootState } from "../../../../app/store";
import { useSelector } from "react-redux";
import { MaxImagesPerAcc } from "./maxImagesComponent/MaxImagesPerAcc";

interface EditSubItemComponentProps<T> {
	initialSubItem: T;
	open: boolean;
	itemType: "objective" | "incentive";
	onSave: (item: T) => void;
	onClose: () => void;
	parentUsers: any;
	subItemLoading: boolean;
	isDependencies: boolean;
}

export const EditSubItemComponent = <T extends Item>({
	initialSubItem,
	itemType,
	open,
	onSave,
	onClose,
	parentUsers,
	subItemLoading,
	isDependencies,
}: EditSubItemComponentProps<T>) => {
	const classes = useStyles();
	const { handleSubmit } = useFormContext();

	const isTeamLead = useSelector(
		(state: RootState) => state.session.role === "teamLead"
	);

	const [subItem, setSubItem] = useState<T>(initialSubItem);
	const [selectionType, setSelectionType] = useState<boolean>(
		subItem.customAccountsSelected ?? true
	);

	const handleTabChange = (newValue: boolean) => {
		setSelectionType(newValue);
	};

	const [selectionTypeProd, setSelectionTypeProd] = useState<boolean>(
		subItem.customProductsSelected ?? true
	);
	const handleProdTabChange = (newValue: boolean) => {
		setSelectionTypeProd(newValue);
	};
	const [selectedProductsFilters, setSelectedProductsFilters] =
		useState<CustomObjIncProductFilters>({
			...{
				supplier: [],
				brandFams: [],
				brand: [],
				packageType: [],
				size: [],
				productType: [],
			},
			...Object.fromEntries(
				Object.entries(initialSubItem.productsFilters || {}).filter(
					([key]) => key !== "premise"
				)
			),
			brandFams: initialSubItem.productsFilters?.brandFams || [],
		});
	const [selectedAccountsFilters, setSelectedAccountsFilters] =
		useState<CustomObjIncAccountFilters>(
			subItem.accountsFilters || {
				accountType: [],
				premise: [],
				chain: [],
				location: [],
			}
		);

	const [measures, setMeasures] = useState(
		initializeMeasures(subItem.measure, subItem.isAccountSpecific || false)
	);

	useEffect(() => {
		setMeasures(initializeMeasures(subItem.measure, subItem.isAccountSpecific));
	}, [subItem.isAccountSpecific, subItem.measure]);

	const [payoutDetails, setPayoutDetails] = useState<PayoutDetails>({
		target: subItem?.target || 0,
		payoutType: subItem?.payoutType || "perAction",
		allOrNothingAmount: subItem?.quantityPayout || 0,
		perActionAmount: subItem?.quantityPayout || 0,
		atRiskAmount: subItem?.quantityPayout || 0,
		threshold: subItem?.threshold || {
			units: subItem?.isOpenEnded ? "raw" : "percentage",
			value: 0,
		},
		cap: subItem?.cap || { units: "percentage", value: 100 },
		isOpenEnded: subItem?.isOpenEnded || false,
		isAccountSpecific: subItem?.isAccountSpecific || false,
		isFullAmount: subItem?.isFullAmount || false,
		cappedStatus: subItem?.cappedStatus || true,
	});

	const [minQuantityValue, setMinQuantityValue] = useState(
		subItem.minQuantityValue || 0
	);

	const [isQualifier, setIsQualifier] = useState(subItem.isQualifier || false);

	const handleMinQuantityValueChange = (value: number) => {
		setMinQuantityValue(value);
		setSubItem((prev) => ({
			...prev,
			minQuantityValue: value,
		}));
	};

	const handleMeasureChange = (
		label?: string,
		startDate?: string,
		endDate?: string,
		checked?: boolean,
		symbol?: MeasureSymbol,
		trackingOption?: string,
		trackingPeriodStart?: string,
		trackingPeriodEnd?: string,
		referenceTrackingPeriod?: string
	) => {
		setMeasures((prevMeasures) =>
			prevMeasures.map((measure) =>
				measure.label === label
					? {
							...measure,
							startDate,
							endDate,
							checked,
							symbol,
							trackingOption,
							trackingPeriodStart,
							trackingPeriodEnd,
							referenceTrackingPeriod,
					  }
					: { ...measure, checked: false }
			)
		);
	};

	const handleMaxImagesPerAccountChange = (value: number) => {
		setSubItem((prevItem) => ({
			...prevItem,
			maxImagesPerAccount: value,
		}));
	};
	const handleChange = (name: string, value: string | number | boolean) => {
		setSubItem((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const getMeasuresForSave = (measures: MeasureState[]) => {
		return measures.filter((measure: MeasureState) => measure.checked)[0];
	};
	const handleTargetChange = (value: number) => {
		setPayoutDetails((prev) => ({
			...prev,
			target: value,
		}));
	};

	const handleIsOpenEndedChange = (checked: boolean) => {
		setSubItem((prevSubItem) => ({
			...prevSubItem,
			isOpenEnded: checked,
		}));

		setPayoutDetails((prevItem) => ({
			...prevItem,
			isOpenEnded: checked,
			payoutType: "perAction",
		}));
	};

	const handleIsAccountSpecificChange = (checked: boolean) => {
		setSubItem((prevSubItem) => ({
			...prevSubItem,
			isAccountSpecific: checked,
		}));

		setPayoutDetails((prevItem) => ({
			...prevItem,
			isAccountSpecific: checked,
		}));
	};

	const handleCappedStatusChange = (checked: boolean) => {
		setSubItem((prevSubItem) => ({
			...prevSubItem,
			cappedStatus: checked,
		}));

		setPayoutDetails((prev) => ({
			...prev,
			cappedStatus: checked,
		}));
	};

	const handleSubItemSubmit = () => {
		let updatedItem = {
			...subItem,
			measure: getMeasuresForSave(measures),
			payoutType: payoutDetails.payoutType,
			quantityPayout: getPayoutType(payoutDetails) || 0,
			threshold: payoutDetails.threshold,
			cap: { ...payoutDetails.cap, units: payoutDetails.threshold.units },
			payout: payoutDetails.target,
			target: payoutDetails.target,
			isValid: true,
			productsFilters: selectedProductsFilters,
			accountsFilters: selectedAccountsFilters,
			minQuantityValue: minQuantityValue,
			customAccountsSelected: selectionType,
			customProductsSelected: selectionTypeProd,
			isQualifier: isQualifier,
			cappedStatus: payoutDetails.cappedStatus,
		};
		updatedItem.users =
			updatedItem.users?.filter((existingUser) =>
				parentUsers.some(
					(parentUser: IncentiveUser | ObjectiveUser) =>
						parentUser.user === existingUser.user
				)
			) || [];

		parentUsers.forEach((parentUser: IncentiveUser | ObjectiveUser) => {
			const existingUser = updatedItem.users?.find(
				({ user }) => user === parentUser.user
			);

			if (!existingUser) {
				updatedItem.users = [
					...(updatedItem.users || []),
					{
						user: parentUser.user,
						isTeamLead: parentUser?.isTeamLead || false,
						isLeadership: parentUser?.isLeadership || false,
						images: [],
						quantity: null,
						validation: null,
						completed: false,
						goal: null,
						isEdited: false,
						target: Number(payoutDetails.target),
						payout: getPayoutType(payoutDetails) || 0,
						payoutType: payoutDetails.payoutType,
						isOpenEnded: updatedItem.isOpenEnded,
						cap: { ...payoutDetails.cap, units: payoutDetails.threshold.units },
						threshold: updatedItem.threshold,
						minQuantityValue: updatedItem.minQuantityValue,
						isAccountSpecific: updatedItem.isAccountSpecific,
						isFullAmount: updatedItem.isFullAmount,
						cappedStatus: updatedItem.cappedStatus,
					},
				];
			} else {
				existingUser.isTeamLead = parentUser?.isTeamLead || false;
				existingUser.isLeadership = parentUser?.isLeadership || false;
			}
		});
		console.log("updatedItem subItem", updatedItem);
		onSave(updatedItem);
	};

	if (initialSubItem === undefined) return null;

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="form-dialog-title"
			className={classes.dialogSubObj}
			maxWidth="md"
		>
			{subItemLoading ? (
				<div className={classes.loadingContainer}>
					<CircularProgress />
				</div>
			) : (
				<>
					<DialogTitle
						id="form-dialog-title"
						className={classes.dialogTitleSubItem}
					>
						{`SUB-${itemType.toLocaleUpperCase()}: ${capitalizeFirstLetter(
							subItem.type
						)} ${capitalizeFirstLetter(itemType)}`}
						{isDependencies && (
							<Box
								className={classes.flexRow}
								style={{ alignItems: "center", alignSelf: "flex-start" }}
							>
								<Tooltip
									title="Select this if this item is a qualifier for dependencies"
									arrow
								>
									<FormControlLabel
										control={
											<Checkbox
												checked={isQualifier}
												onChange={(event) =>
													setIsQualifier(event.target.checked)
												}
												color="primary"
											/>
										}
										label={
											<Typography
												variant="body1"
												style={{ fontWeight: "bold", fontSize: "1rem" }}
											>
												Is Qualifier
											</Typography>
										}
									/>
								</Tooltip>
							</Box>
						)}
					</DialogTitle>
					<DialogContent className={classes.dialogContentSubObj}>
						<NameDescriptionPeriodComponent
							item={subItem}
							handleChange={handleChange}
						/>
						<>
							<TargetComponent
								name={"Target"}
								target={payoutDetails?.target || 0}
								isOpenEnded={subItem.isOpenEnded}
								onTargetChange={handleTargetChange}
								onIsOpenEndedChange={handleIsOpenEndedChange}
								itemType={subItem.type}
								isAccountSpecific={subItem.isAccountSpecific}
								onIsAccountSpecificChange={handleIsAccountSpecificChange}
								cappedStatus={payoutDetails.cappedStatus}
								onCappedStatusChange={handleCappedStatusChange}
							/>
						</>
						{subItem.type === "imageValidation" && (
							<MinimumQuantityComponent
								minQuantityValue={minQuantityValue}
								onMinQuantityValueChange={handleMinQuantityValueChange}
							/>
						)}
						<Divider className={classes.divider} />
						<div className={classes.flexColumn}>
							<PayoutComponent
								payoutDetails={payoutDetails}
								setPayoutDetails={setPayoutDetails}
							/>
						</div>

						{subItem.type === ObjectiveTypeEnum.Quantity && (
							<QuantityForm
								measures={measures}
								handleMeasureChange={handleMeasureChange}
								customPeriodStart={subItem.customPeriodStart}
								customPeriodEnd={subItem.customPeriodEnd}
							/>
						)}

						{(subItem.type === ObjectiveTypeEnum.Image ||
							subItem.type === ObjectiveTypeEnum.ImageValidation) && (
							<MaxImagesPerAcc
								maxImagesPerAccount={subItem.maxImagesPerAccount || 1}
								onMaxImagesPerAccountChange={handleMaxImagesPerAccountChange}
								isTeamLead={isTeamLead}
							/>
						)}

						{subItem.type !== ObjectiveTypeEnum.Multi && (
							<>
								<Accordion>
									<AccordionSummary
										aria-expanded={true}
										expandIcon={<ExpandMore />}
										aria-controls="panel1-content"
										id="panel1-header"
										className={classes.accordeonFontSize}
									>
										Accounts:
									</AccordionSummary>
									<AccordionDetails className={classes.flexColumn}>
										<SelectionTabs
											selectionType={selectionType}
											setSelectionType={handleTabChange}
										/>
										{selectionType && (
											<CustomAccountsSelection
												selectedAccountsFilters={selectedAccountsFilters}
												setSelectedAccountsFilters={setSelectedAccountsFilters}
												item={subItem}
											/>
										)}
										{!selectionType && (
											<ManualAccountsSelection item={subItem} />
										)}
									</AccordionDetails>
								</Accordion>

								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMore />}
										aria-controls="panel1-content"
										id="panel1-header"
										className={classes.accordeonFontSize}
									>
										Products:
									</AccordionSummary>
									<AccordionDetails className={classes.flexColumn}>
										<SelectionTabs
											selectionType={selectionTypeProd}
											setSelectionType={handleProdTabChange}
										/>
										{selectionTypeProd && (
											<CustomProductsSelection
												selectedProductsFilters={selectedProductsFilters}
												setSelectedProductsFilters={setSelectedProductsFilters}
												item={subItem}
											/>
										)}
										{!selectionTypeProd && (
											<ManualProductsSelection item={subItem} />
										)}
									</AccordionDetails>
								</Accordion>
							</>
						)}
					</DialogContent>
				</>
			)}
			<div className={classes.buttonsWrapper}>
				<Button onClick={onClose} color="primary">
					Cancel
				</Button>
				<Button
					onClick={handleSubmit(handleSubItemSubmit)}
					color="primary"
					disabled={isTeamLead}
				>
					{`Save sub-${itemType}`}
				</Button>
			</div>
		</Dialog>
	);
};
