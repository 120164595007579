import { Button } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
import exportCsv from "../../../utils/exportCsv";
import { formatImagesDataToRows, mapImagesDataForExport } from "./utils";

interface ExportImagesCSVProps {
	rows?: any[];
}

export const ExportImagesCSV = ({ rows }: ExportImagesCSVProps) => {
	const imagesData = mapImagesDataForExport(rows || []);

	const csvRows = formatImagesDataToRows(imagesData || []);

	const handleExport = () => {
		exportCsv("Images", csvRows);
	};

	return (
		<Button
			size="small"
			variant="outlined"
			startIcon={<DownloadIcon />}
			onClick={handleExport}
			style={{
				width: "100%",
				color: "#3D4EA1",
				backgroundColor: "#e8eaf6",
				borderColor: "#D0D5ED",
			}}
		>
			Images CSV
		</Button>
	);
};
