import { ImageListCell } from "./ImageListCell";
import { ProductsList } from "./ProductsList";
import AccountsList from "./AccountsList";
import { formatDate, formatTypeLabel, ObjIncentiveImage } from "./utils";
import { useState } from "react";
import { Chip, TableCell } from "@material-ui/core";
import AccountsResultsList from "./AccountsResultsList";
import { IRow } from "../store/commonObjIncStore";

const getStatusCell = (progress: number | string) => {
	let statusColor = "black";
	if (progress === "∞") {
		return <span>{progress}</span>;
	} else if (!isNaN(Number(progress))) {
		let progressNum = Number(progress);
		if (progressNum < 0) {
			progressNum = 0;
		}
		if (progressNum < 50) statusColor = "red";
		else if (progressNum >= 50 && progressNum < 80) statusColor = "#F4C01D";
		else if (progressNum >= 80) statusColor = "#4E915E";
		return <span style={{ color: statusColor }}>{`${progressNum}%`}</span>;
	}
	return <span style={{ color: "red" }}>{`0 %`}</span>;
};

const RouteCell = ({ route }: { route: number[] }) => {
	const [isHovered, setIsHovered] = useState(false);
	const maxLength = 20;

	const formattedRoute = Array.isArray(route) ? route.join(", ") : "";

	return (
		<div
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			style={{ position: "relative", display: "inline-block" }}
		>
			<span>
				{formattedRoute !== ", "
					? formattedRoute.length <= maxLength || isHovered
						? formattedRoute
						: `${formattedRoute.substring(0, maxLength)}...`
					: "-"}
			</span>
		</div>
	);
};

export const mapRowToColumns = (
	row: IRow,
	columns: string[],
	isObjective: boolean,
	setRefetchRows: (refetchRows: boolean) => void,
	classes?: any,
	onRowClick?: (row: IRow) => void,
	allSelectedRows?: any,
	groupByTeams?: boolean,
	expandedGroups?: boolean
) => {
	const orderedRow: any[] = [];

	let combinedImages: ObjIncentiveImage[] = [];
	if (Array.isArray(allSelectedRows)) {
		combinedImages = allSelectedRows.flatMap(
			(selectedRow) => selectedRow.images || []
		);
	}

	let combinedApprovedImages: ObjIncentiveImage[] = [];
	if (Array.isArray(allSelectedRows)) {
		combinedApprovedImages = allSelectedRows.flatMap(
			(selectedRow) => selectedRow.approvedImages || []
		);
	}

	let combinedRejectedImages: ObjIncentiveImage[] = [];
	if (Array.isArray(allSelectedRows)) {
		combinedRejectedImages = allSelectedRows.flatMap(
			(selectedRow) => selectedRow.rejectedImages || []
		);
	}

	columns.forEach((column) => {
		switch (column) {
			case "Item ID":
				orderedRow.push(row.itemId);
				break;
			case "Item Name":
				orderedRow.push(row.name);
				break;
			case "User Name":
				orderedRow.push(row.userName);
				break;
			case "Start Date":
				orderedRow.push(row.startDate ? formatDate(row.startDate) : "-");
				break;

			case "End Date":
				orderedRow.push(row.endDate ? formatDate(row.endDate) : "-");
				break;

			case "Status":
				orderedRow.push(getStatusCell(row.status));
				break;

			case "New Images":
				orderedRow.push(
					row.images && row.images.length > 0 ? (
						<ImageListCell
							images={row.images}
							itemId={row.itemId}
							combinedImages={combinedImages}
							setRefetchRows={setRefetchRows}
						/>
					) : (
						"-"
					)
				);
				break;

			case "Approved Images":
				orderedRow.push(
					row.approvedImages && row.approvedImages.length > 0 ? (
						<ImageListCell
							images={row.approvedImages}
							itemId={row.itemId}
							combinedImages={combinedApprovedImages}
							setRefetchRows={setRefetchRows}
						/>
					) : (
						"-"
					)
				);
				break;

			case "Rejected Images":
				orderedRow.push(
					row.rejectedImages && row.rejectedImages.length > 0 ? (
						<ImageListCell
							images={row.rejectedImages}
							itemId={row.itemId}
							combinedImages={combinedRejectedImages}
							setRefetchRows={setRefetchRows}
						/>
					) : (
						"-"
					)
				);
				break;

			case "Route":
				orderedRow.push(<RouteCell route={row.route || []} />);
				break;

			case "Name":
				orderedRow.push(row.name);
				break;

			case "Type":
				orderedRow.push(formatTypeLabel(row.type));
				break;

			case "Measure":
				orderedRow.push(row.measure || "-");
				break;

			case "Percentage Achieved":
				orderedRow.push(`${row.percentageAchieved}%`);
				break;

			case "Quantity":
				orderedRow.push(row.quantity || "0");
				break;

			case "Eligible Quantity":
				orderedRow.push(row.eligibleQuantity || "-");
				break;
			case "Minimum Quantity":
				orderedRow.push(row.minQuantityValue || "-");
				break;

			case "Target":
				orderedRow.push(row.userTarget !== "∞" ? row.userTarget : "∞");
				break;

			case "Potential":
				orderedRow.push(
					row.potential !== "∞" && !row.isOpenEnded ? `$ ${row.potential}` : "∞"
				);
				break;

			case "Payout MTD":
				orderedRow.push(row.payoutMTD ? `$ ${row.payoutMTD}` : "$ 0");
				break;

			case "Parent Item ID":
				orderedRow.push(row.parentItemId || "-");
				break;

			case "Parent Item Name":
				orderedRow.push(row.parentItemName || "-");
				break;

			case "Supplier Contribution":
				orderedRow.push(
					row.supplierContribution ? `$ ${row.supplierContribution}` : "-"
				);
				break;

			case "Tag":
				orderedRow.push(
					row.tagName ? (
						<Chip
							label={row.tagName}
							size="small"
							style={{
								backgroundColor: `${row.tagColor}1A`,
								color: row.tagColor,
								borderRadius: "15px",
								height: "28px",
								padding: "0 12px",
								fontWeight: "normal",
							}}
						/>
					) : (
						"-"
					)
				);
				break;

			case "Accounts":
				orderedRow.push(
					<AccountsList
						itemId={row.itemId}
						userId={row.userId}
						isObjective={isObjective}
						userType={row.isTeamLead ? "teamLead" : "salesRep"}
					/>
				);
				break;

			case "Products":
				orderedRow.push(
					<ProductsList
						itemId={row.itemId}
						userId={row.userId}
						isObjective={isObjective}
					/>
				);
				break;

			case "Accounts Results":
				orderedRow.push(
					row.isAccountSpecific ||
						row.measure?.includes("PODs Δ (%)") ||
						row.measure?.includes("PODs Δ") ||
						row.measure?.includes("PODs") ||
						row.measure?.includes("PODs Δ (delta) Simple") ||
						row.measure?.includes("GP Δ (%)") ||
						row.measure?.includes("GP Δ") ||
						row.measure?.includes("GP ($)") ||
						row.measure?.includes("Vol Δ (%)") ||
						row.measure?.includes("Vol Δ (CEs)") ||
						row.measure?.includes("Vol (CEs)") ||
						row.measure?.includes("Units Δ (%)") ||
						row.measure?.includes("Units(Btls/cans)") ||
						row.measure?.includes("Revenue Δ (%)") ||
						row.measure?.includes("Revenue ($)") ? (
						<AccountsResultsList
							itemId={row.itemId}
							userId={row.userId}
							isDelta={row?.measure?.includes("Δ")}
						/>
					) : (
						"-"
					)
				);
				break;

			default:
				orderedRow.push("-");
				break;
		}
	});

	if (!onRowClick) {
		return orderedRow;
	}

	return orderedRow.map((cellContent, index) => {
		const column = columns[index];
		const isInteractive = [
			"Accounts",
			"Products",
			"New Images",
			"Approved Images",
			"Rejected Images",
			"Accounts Results",
		].includes(column);

		const isSalesRepRow =
			groupByTeams &&
			expandedGroups &&
			!row.isLeadership &&
			!row.isTeamLead &&
			(column === "User Name" || column === "Route");

		return (
			<TableCell
				key={index}
				onClick={!isInteractive ? () => onRowClick(row) : undefined}
				className={`${classes.clickableRow} ${
					column === "User Name"
						? classes.stickyColumn
						: column === "Route"
						? classes.stickyColumnLeft
						: classes.tableCell
				} ${
					groupByTeams
						? row.isLeadership
							? classes.leadershipRow
							: row.isTeamLead
							? classes.teamLeadRow
							: isSalesRepRow
							? classes.salesRepRow
							: ""
						: ""
				}`}
			>
				{cellContent}
			</TableCell>
		);
	});
};
