import { makeStyles } from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";

export const useStyles = makeStyles({
	container: {
		overflow: "hidden",
		flexWrap: "wrap",
	},
	filterBox: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		flexWrap: "wrap",
	},
	box: {
		display: "flex",
		gap: "1rem",
		alignItems: "center",
		flexWrap: "wrap",
	},
	button: {
		color: "#3D4EA1",
		backgroundColor: "#e8eaf6",
		borderColor: "#D0D5ED",
	},
	typography: {
		fontSize: "16px",
		textTransform: "none",
	},
	grayTypography: {
		color: "gray",
	},
	datePicker: {
		display: "flex",
		gap: "1rem",
		alignItems: "center",
	},
	exportButton: {
		display: "flex",
		gap: 2,
		alignItems: "center",
		marginLeft: "auto",
	},
	dialogTitle: {
		backgroundColor: indigo[300],
		color: "white",
		textAlign: "center",
	},
	modalContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		paddingTop: "3rem",
		paddingBottom: "3rem",
	},
	modalActions: {
		display: "flex",
		justifyContent: "space-between",
		padding: "1rem",
	},
	checkbox: {
		"&.Mui-checked": {
			color: indigo[300],
		},
	},
});
