import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		marginLeft: "0",
	},
	button: {
		flex: 1,
		maxWidth: "17%",
	},
	marginTop: {
		marginTop: 10,
	},
	buttons: {
		display: "flex",
		flexDirection: "row",
		gap: "2rem",
		alignItems: "center",
		marginBottom: "1rem",
	},
	card: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: theme.shape.borderRadius,
		boxShadow: theme.shadows[2],
		textAlign: "center",
		height: "50px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "0.8rem",
		letterSpacing: "0.03rem",
	},
	viewContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "1rem",
	},
	viewTitle: {
		fontSize: "1.2rem",
		fontWeight: 500,
	},
	userName: {
		fontSize: "0.8rem",
	},
	userCard: {
		padding: '10px',
		borderRadius: '5px',
		backgroundColor: '#f0f0f0',
		boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
		textAlign: 'center',
		transition: 'background-color 0.3s',
		'&:hover': {
			backgroundColor: '#e0e0e0',
		},
	},
}));
