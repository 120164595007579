import { columnKeyMap, formatDate } from "./utils";

export const mapForExport = (row: any, columns: string[]): string[] => {
	const orderedRow: string[] = [];
	columns.forEach((column) => {
		const key = columnKeyMap[column];

		switch (column) {
			case "Start Date":
			case "End Date":
				orderedRow.push(formatDate(row[key] || ""));
				break;
			case "Status":
				if (
					row.type === "image" ||
					row.type === "binary" ||
					row.type === "quantity"
				) {
					const progress = row.status || row.progress;
					if (progress === "∞") {
						orderedRow.push(progress);
					} else if (!isNaN(Number(progress))) {
						const progressNum = Number(progress);
						orderedRow.push(`${progressNum}`);
					} else {
						orderedRow.push("0");
					}
				} else {
					orderedRow.push("Not defined");
				}
				break;
			case "New Images":
				if (row.images && row.images.length > 0) {
					const acceptedImages = row.images.filter(
						(image: { rejected: boolean }) => !image.rejected
					);

					const imageDetails = acceptedImages.map(
						(image: { imageUrl: string; notes: string }) => {
							if (image.notes) {
								return `${image.imageUrl} (${image.notes})`;
							}
							return image.imageUrl;
						}
					);
					orderedRow.push(imageDetails.join("; "));
				} else {
					orderedRow.push("");
				}
				break;
			case "Approved Images":
				if (row.approvedImages && row.approvedImages.length > 0) {
					const approvedImages = row.approvedImages.filter(
						(image: { rejected: boolean }) => !image.rejected
					);

					const imageDetails = approvedImages.map(
						(image: { imageUrl: string; notes: string }) => {
							if (image.notes) {
								return `${image.imageUrl} (${image.notes})`;
							}
							return image.imageUrl;
						}
					);
					orderedRow.push(imageDetails.join("; "));
				} else {
					orderedRow.push("");
				}
				break;
			case "Rejected Images":
				if (row.rejectedImages && row.rejectedImages.length > 0) {
					const rejectedImages = row.rejectedImages.filter(
						(image: { rejected: boolean }) => image.rejected
					);

					const imageDetails = rejectedImages.map(
						(image: { imageUrl: string; rejectedReason: string }) => {
							if (image.rejectedReason) {
								return `${image.imageUrl} (${image.rejectedReason})`;
							}
							return image.imageUrl;
						}
					);
					orderedRow.push(imageDetails.join("; "));
				} else {
					orderedRow.push("");
				}
				break;
			case "Input Value Type":
				const valueTypeMap: Record<string, string> = {
					gr: "Greater than",
					lt: "Less than",
					gre: "Greater than or equal",
					lte: "Less than or equal",
				};
				orderedRow.push(valueTypeMap[row[key]]);
				break;
			case "Quantity":
				orderedRow.push(row.quantity || "0");
				break;
			case "Minimum Quantity":
				orderedRow.push(row.minQuantityValue);
				break;
			case "Eligible Quantity":
				orderedRow.push(row.eligibleQuantity === "-" ? "" : row.eligibleQuantity);
				break;
			case "Target":
				orderedRow.push(row.userTarget !== "∞" ? row.userTarget : "∞");
				break;
			case "Measure":
				orderedRow.push(row[key]);
				break;
			case "Payout MTD":
				orderedRow.push(row.payoutMTD ? `$${row.payoutMTD}` : "$0");
				break;
			case "Supplier Contribution":
				orderedRow.push(
					row.supplierContribution === "-" ? "" : `$${row.supplierContribution}`
				);
				break;
			case "Potential":
				orderedRow.push(
					row.potential !== "∞" && !row.isOpenEnded ? `$${row.potential}` : "∞"
				);
				break;
			case "Route":
				orderedRow.push(Array.isArray(row.route) ? row.route.join(", ") : "");
				break;
			case "Item Name":
				orderedRow.push(row.name);
				break;
			case "Parent Item ID":
				orderedRow.push(row.parentItemId || "-");
				break;

			case "Parent Item Name":
				orderedRow.push(row.parentItemName || "-");
				break;
			case "Tag":
				orderedRow.push(row.tagName || "-");
				break;
			default:
				orderedRow.push(row[key]?.toString());
				break;
		}
	});

	return orderedRow;
};
