import { FormLabel, TextField } from "@material-ui/core";
import { DividerFormControl } from "../../../common/DividerFormControl";
import { useFormContext } from "react-hook-form";
import { useStyles } from "../EditComponent.styles";

interface MaxImagesPerAccProps {
	maxImagesPerAccount: number;
	onMaxImagesPerAccountChange: (value: number) => void;
	isTeamLead: boolean;
}

export const MaxImagesPerAcc = ({
	maxImagesPerAccount,
	onMaxImagesPerAccountChange,
	isTeamLead,
}: MaxImagesPerAccProps) => {
	const classes = useStyles();
	const { register, errors } = useFormContext();

	return (
		<DividerFormControl>
			<FormLabel className={classes.spacedCenterAlign}>
				Maximum Images Per Account:
			</FormLabel>
			<TextField
				margin="dense"
				id="maxImagesPerAccount"
				variant="outlined"
				type="number"
				fullWidth
				error={!!errors.maxImagesPerAccount}
				helperText={errors.maxImagesPerAccount?.message}
				name="maxImagesPerAccount"
				value={maxImagesPerAccount || 1}
				className={classes.flex2}
				onChange={(event) =>
					onMaxImagesPerAccountChange(Number(event.target.value))
				}
				disabled={isTeamLead}
				inputRef={register({
					validate: {
						positiveNumber: (value) => {
							if (isTeamLead) {
								return true;
							}
							return (
								parseFloat(value) > 0 ||
								"Maximum Images Per Account must be greater than 0"
							);
						},
					},
				})}
			/>
		</DividerFormControl>
	);
};
