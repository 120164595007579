export const objColumns = [
	"User Name",
	"Route",
	"Item Name",
	"Parent Item Name",
	"Type",
	"Start Date",
	"End Date",
	"User Type",
	"Status",
	"Quantity",
	"Measure",
	"Eligible Quantity",
	"Target",
	"Minimum Quantity",
	"Potential",
	"Payout MTD",
	"New Images",
	"Approved Images",
	"Rejected Images",
	"Accounts",
	"Products",
	"Accounts Results",
];

export const incColumns = [
	"User Name",
	"Route",
	"Item Name",
	"Parent Item Name",
	"Type",
	"Start Date",
	"End Date",
	"User Type",
	"Status",
	"Quantity",
	"Measure",
	"Eligible Quantity",
	"Target",
	"Minimum Quantity",
	"Potential",
	"Payout MTD",
	"New Images",
	"Approved Images",
	"Rejected Images",
	"Accounts",
	"Products",
	"Accounts Results",
];

export interface ITableCell {
	content?: string;
	value?: any;
	tooltip?: string;
}

export type ITableRows = ITableCell[][];

export type ISortDirection = "asc" | "desc";
