import { useEffect, useState } from "react";

import CreatableSelect from 'react-select/creatable';
import { FormLabel } from "@material-ui/core";
import { useStyles } from "./TagComponent.styles";
import { createOption, generateRandomColor } from "./utils";
import useAddTag from "../../../hooks/useAddTag";
import useTags from "../../../hooks/useTags";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";

interface Option {
	label: string;
	value: string;
	color: string;
}

interface TagComponentProps {
	selectedTagId?: string;
	onTagChange: (value: string) => void;
}

export const TagComponent = ({ selectedTagId, onTagChange }: TagComponentProps) => {
	const classes = useStyles();
	const isTeamLead = useSelector(
		(state: RootState) => state.session.role === "teamLead"
	);

	const {  addTag } = useAddTag();
	const { tags } = useTags();
	const transformedTags = tags?.map(tag => createOption(tag));
	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState(transformedTags);
	const [value, setValue] = useState<Option | null>(null);
	const [inputValue, setInputValue] = useState("");


	useEffect(() => {
		const updatedOptions = tags?.map(tag => createOption(tag));
		setOptions(updatedOptions);

		const selectedTag = tags?.find(tag => tag._id === selectedTagId);
		setValue(selectedTag ? createOption(selectedTag) : null);
	}, [tags, selectedTagId]);

	const handleInputChange = (newValue: string) => {
		if (newValue.length <= 15) {
			setInputValue(newValue);
		}
	};

	const handleCreate = async (inputValue: string) => {
		setIsLoading(true);
		const existingTag = options?.find(option => option.label.toLowerCase() === inputValue.toLowerCase());

		if (!existingTag) {
			const newColor = generateRandomColor();
			const newTag = await addTag(inputValue, newColor);

			const newOption = createOption(newTag);

			setOptions(prev => prev ? [...prev, newOption] : [newOption]);
			setValue(newOption);
			onTagChange(newOption.value);
		} else {
			setValue(existingTag);
			onTagChange(existingTag.value);
		}

		setIsLoading(false);
	};

	const handleChange = (newValue: Option | null) => {
		setValue(newValue);
		onTagChange(newValue ? newValue.value : "");
	};

	return (
		<div className={classes.container}>
			<FormLabel>Tag: </FormLabel>
			<CreatableSelect
				isClearable
				isDisabled={isLoading || isTeamLead}
				isLoading={isLoading}
				onChange={handleChange}
				onCreateOption={handleCreate}
				options={options}
				value={value}
				className={classes.select}
				styles={{
					menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
				}}
				menuPortalTarget={document.body}
				onInputChange={handleInputChange}
				inputValue={inputValue}
			/>
		</div>
	);
}
