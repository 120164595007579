import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
	formControl: {
		display: "flex",
		flexDirection: "row",
		margin: "20px 0",
	},
	dialog: {
		flex: 1,
		marginLeft: "2%",
		height: "80vh",
		overflow: "scroll",
		boxShadow: `0px 2px 2px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)`,
	},
	dialogContent: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		overflow: "scroll",
	},
	datePicker: {
		width: "55%",
		marginBottom: "20px",
	},
	buttonsWrapper: {
		margin: "20px",
		display: "flex",
		justifyContent: "space-between",
		position: "relative",
		bottom: 0,
	},
	listWrapper: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
	},
	objList: {
		width: "50%",
	},
	buttonContainer: {
		width: "50%",
	},
	button: {
		marginTop: "2rem",
		marginBottom: "1rem",
	},
	prizesButtonWrapper: {
		display: "flex",
		justifyContent: "flex-start",
	},
	divider: {
		marginTop: "1rem",
		marginBottom: "1rem",
	},
	spacedFormLabel: {
		marginRight: 10,
		marginTop: 20,
	},
	smallTextField: {
		marginRight: 10,
		width: "9rem",
	},
	dialogTitle: {
		textAlign: "center",
		marginBottom: "2rem",
	},
	dialogSubObj: {
		width: "100%",
		height: "100%",
	},
	dialogContentSubObj: {
		display: "flex",
		flexDirection: "column",
		width: "900px",
		"@media (max-width: 768px)": {
			width: "100%",
		},
	},
	validationError: {
		color: "red",
		fontSize: "1rem",
	},
	validationObjContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "2rem",
	},
	validationGroup: {
		display: "flex",
		flexDirection: "row",
		gap: "2rem",
	},
	flexColumn: {
		display: "flex",
		flexDirection: "column",
	},
	accordeonFontSize: {
		fontSize: 16,
	},
	modalHeight: {
		height: "100%",
	},
	spacedCenterAlign: {
		marginRight: 10,
		marginTop: 10,
		alignSelf: "center",
	},
	flex2: {
		width: "9rem",
	},
	targetContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		gap: "1rem",
		width: "100%",
	},
	target: {
		width: "9rem",
	},
	targetText: {
		paddingTop: 10,
	},
	accordionsContainer: {
		maxWidth: "100%",
		minWidth: "100%",
	},
	loadingContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		width: "100%",
		minHeight: "300px",
	},
	flexRow: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: "1rem",
		alignSelf: "flex-start",
	},
	dialogTitleSubItem: {
		textAlign: "center",
		marginBottom: "1rem",
	},
});
