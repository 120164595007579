import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { IGeneralQuery } from "../../hooks/useQuery";
import { IUser } from "../../interfaces";
import api from "../../utils/api";
import { IRole } from "../../utils/roles";
import snack from "../../utils/snack";
import { RootState } from "../../app/store";
declare global {
  interface Window {
    __PAGE_LOAD_TIME__?: number;
  }
}

type IStatus = "idle" | "loading";
interface sessionState {
  isLoggedIn: boolean;
  wholesalerId?: string;
  customId?: string;
  authToken?: string;
  name?: string;
  email?: string;
  role?: IRole;
  status: IStatus;
  avatarHash?: string;
  leadership?: boolean;
  avatarUrl?: string;
  localAvatarUrl?: string;
  localAvatarTimestamp?: number;
  wholesalerAvatarUrl?: string;
  localWholesalerAvatarUrl?: string;
  localWholesalerAvatarTimestamp?: number;
  wholesalerAvatarHash?: string;
  currentWholesalerId?: string;
}

interface ILoggedInPayload {
  token: string;
  name: string;
  email: string;
  wholesalerId: string;
  customId: string;
  role: IRole;
  leadership: boolean;
}

const initialState: sessionState = {
  isLoggedIn: false,
  status: "idle",
  leadership: false,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    logOut: (state) => {
      state.isLoggedIn = false;
      api.config.setToken();
      state.authToken = undefined;
      state.localAvatarUrl = undefined;
      
    },
    loggedIn: (state, action: PayloadAction<ILoggedInPayload>) => {
      state.status = "idle";
      state.authToken = action.payload.token;
      state.name = action.payload.name;
      state.role = action.payload.role;
      state.email = action.payload.email;
      state.wholesalerId = action.payload.wholesalerId;
      state.customId = action.payload.customId;
      state.isLoggedIn = true;
      state.leadership = action.payload.leadership;
      state.localAvatarUrl = undefined;
      
      if (state.currentWholesalerId !== action.payload.wholesalerId) {
        state.localWholesalerAvatarUrl = undefined;
        state.currentWholesalerId = action.payload.wholesalerId;
      }
    },
    profilePatched: (state, action: PayloadAction<IUser>) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
    },
    setStatus: (state, action: PayloadAction<IStatus>) => {
      state.status = action.payload;
    },
    setAvatarHash: (state) => {
      state.avatarHash = Math.random().toString();
    },
    setAvatarUrl: (state, action: PayloadAction<string>) => {
      state.avatarUrl = action.payload;
    },
    setLocalAvatarUrl: (state, action: PayloadAction<string>) => {
      state.localAvatarUrl = action.payload;
      state.localAvatarTimestamp = Date.now();
    },
    clearLocalAvatarUrl: (state) => {
      state.localAvatarUrl = undefined;
    },
    refreshAvatarState: (state) => {
      state.localAvatarUrl = undefined;
      state.avatarHash = undefined;
      state.avatarUrl = undefined;
    },
    setWholesalerAvatarUrl: (state, action: PayloadAction<string>) => {
      state.wholesalerAvatarUrl = action.payload;
    },
    setLocalWholesalerAvatarUrl: (state, action: PayloadAction<string>) => {
      state.localWholesalerAvatarUrl = action.payload;
      state.localWholesalerAvatarTimestamp = Date.now();
      state.currentWholesalerId = state.wholesalerId;
    },
    clearLocalWholesalerAvatarUrl: (state) => {
      state.localWholesalerAvatarUrl = undefined;
    },
    refreshWholesalerAvatarState: (state) => {
      state.localWholesalerAvatarUrl = undefined;
    },
    setWholesalerAvatarHash: (state) => {
      state.wholesalerAvatarHash = Math.random().toString();
    },
  },
});

export const {
  loggedIn,
  logOut,
  setStatus,
  profilePatched,
  setAvatarHash,
  setAvatarUrl,
  setLocalAvatarUrl,
  clearLocalAvatarUrl,
  refreshAvatarState,
  setWholesalerAvatarUrl,
  setLocalWholesalerAvatarUrl,
  clearLocalWholesalerAvatarUrl,
  refreshWholesalerAvatarState,
  setWholesalerAvatarHash,
} = sessionSlice.actions;

export const logInWithPassword = (
  email: string,
  password: string
): AppThunk => async (dispatch) => {
  dispatch(setStatus("loading"));
  dispatch(refreshAvatarState());
  
  const res = await api.fetch({
    path: "/login",
    method: "POST",
    headers: {
      Authorization: `Basic ${Buffer.from(`${email}:${password}`).toString(
        "base64"
      )}`,
    },
  });
  if (res.token) {
    api.config.setToken(res.token);
    dispatch(
      loggedIn({
        token: res.token,
        name: res.payload.name,
        role: res.payload.role,
        email: res.payload.email,
        wholesalerId: res.payload.wholesalerId,
        customId: res.payload.customId,
        leadership: res.payload.leadership
      })
    );
  }
  dispatch(setStatus("idle"));
};

export const postRequestPasswordReset = (
  email: string
): AppThunk => async () => {
  const res = await api.fetch({
    path: "/passwordResetRequest",
    method: "POST",
    body: { email },
  });
  if (res.ok) {
    snack.success("Check your email");
  }
};

interface IPasswordReset {
  email: string;
  password: string;
  otp: string;
}

export const postPasswordReset = (
  body: IPasswordReset,
  cb: () => void
): AppThunk => async () => {
  const res = await api.fetch({
    path: "/passwordReset",
    method: "POST",
    body,
  });
  if (res.ok) {
    snack.success("Password changes. Now you can log in.");
    cb();
  }
};

interface IPatchProfileBody {
  email: string;
  name: string;
}

export const patchProfile = (
  query: IGeneralQuery,
  body: IPatchProfileBody
): AppThunk => async (dispatch) => {
  const res = await api.fetch({
    path: "/userSelf",
    method: "PATCH",
    query,
    body,
  });
  if (res.ok) {
    dispatch(profilePatched(res.payload));
    snack.success("Profile updated!");
  }
};

interface IPostUserAvatarResponse {
  url: string;
  fields: {
    [key: string]: string;
  };
}

export const refreshServerAvatarUrl = (query: IGeneralQuery): AppThunk => async (
  dispatch
) => {
  try {
    const res = await api.fetch({
      path: "/getUserAvatarSignedUrl",
      method: "GET",
      query,
      silent: true
    });
    
    if (res.data) {
      dispatch(setAvatarUrl(res.data));
    }
  } catch (error) {
    console.error("Error getting signed avatar URL:", error);
  }
};

export const postUserAvatar = (query: IGeneralQuery): AppThunk => async (
  dispatch,
) => {
  
  const res = await api.fetch({
    path: "/userAvatar",
    method: "POST",
    query,
  });
  if (res.ok) {
    const payload: IPostUserAvatarResponse = res.payload;
    const formdata = new FormData();
    Object.keys(payload.fields).forEach((fieldName) => {
      formdata.append(fieldName, payload.fields[fieldName]);
    });
    
    var fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.click();
    
    await new Promise((resolve) => {
      fileInput.onchange = resolve;
    });

    if (!fileInput.files?.[0]) {
      snack.error("No picture selected");
      return;
    }
    
    const selectedFile = fileInput.files[0];
    formdata.append("file", selectedFile);
    
    const localImageUrl = URL.createObjectURL(selectedFile);
    dispatch(setLocalAvatarUrl(localImageUrl));
    
    snack.info("Saving...");
    
    await fetch(payload.url, {
      method: "POST",
      body: formdata,
    })
      .then((response) => {
        return response.text();
      })
      .then(() => {
        snack.success("Profile picture uploaded! 🚀");
        dispatch(setAvatarHash());
        dispatch(refreshServerAvatarUrl(query));
      })
      .catch((error) => {
        dispatch(clearLocalAvatarUrl());
        snack.error("Picture uploading error 😱");
        console.error("failed to upload file", error);
      });
  }
};

interface IPostWholesalerAvatarResponse {
  url: string;
  fields: {
    [key: string]: string;
  };
}

export const refreshWholesalerAvatarUrl = (query: IGeneralQuery): AppThunk => async (
  dispatch
) => {
  try {
    const res = await api.fetch({
      path: "/getWholesalerAvatarSignedUrl",
      method: "GET",
      query,
      silent: true
    });

    if (res.data) {
      dispatch(setAvatarUrl(res.data));
    }
  } catch (error) {
    console.error("Error getting signed avatar URL:", error);
  }
};

export const postWholesalerAvatar = (query: IGeneralQuery): AppThunk => async (
  dispatch
) => {
  const res = await api.fetch({
    path: "/wholesalerAvatar",
    method: "POST",
    query,
  });
  if (res.ok) {
    const payload: IPostWholesalerAvatarResponse = res.payload;
    const formdata = new FormData();
    Object.keys(payload.fields).forEach((fieldName) => {
      formdata.append(fieldName, payload.fields[fieldName]);
    });
    
    var fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.click();
    
    await new Promise((resolve) => {
      fileInput.onchange = resolve;
    });
    
    if (!fileInput.files?.[0]) {
      snack.error("No picture selected");
      return;
    }

    const selectedFile = fileInput.files[0];
    formdata.append("file", selectedFile);
    
    const localWholesalerImageUrl = URL.createObjectURL(selectedFile);
    dispatch(setLocalWholesalerAvatarUrl(localWholesalerImageUrl));
    snack.info("Saving...");

    await fetch(payload.url, {
      method: "POST",
      body: formdata,
    })
      .then((response) => {
        return response.text();
      })
      .then(() => {
        snack.success("Wholesaler avatar uploaded! 🚀");
        dispatch(setWholesalerAvatarHash());
        dispatch(refreshWholesalerAvatarUrl(query));
      })
      .catch((error) => {
        dispatch(clearLocalWholesalerAvatarUrl());
        snack.error("Picture uploading error 😱");
        console.error("failed to upload file", error);
      });
  }
};

export const isLocalAvatarStale = (state: RootState): boolean => {
  const { localAvatarTimestamp } = state.session;
  if (!localAvatarTimestamp) return true;
  
  const pageLoadTime = window.__PAGE_LOAD_TIME__ || 0;
  return localAvatarTimestamp < pageLoadTime;
};

export const isWholesalerAvatarStale = (state: RootState): boolean => {
  const { localWholesalerAvatarTimestamp, currentWholesalerId, wholesalerId } = state.session;
  
  if (!localWholesalerAvatarTimestamp) return true;
  if (currentWholesalerId !== wholesalerId) return true;
  
  const pageLoadTime = window.__PAGE_LOAD_TIME__ || 0;
  return localWholesalerAvatarTimestamp < pageLoadTime;
};

if (typeof window !== 'undefined') {
  window.__PAGE_LOAD_TIME__ = Date.now();
}

export default sessionSlice.reducer;
