import { create } from "zustand";
import api from "../../../utils/api";
import { ISortDirection, objColumns } from "../utils/types";
import {
	IGeneralQuery,
	IGeneralQueryWithCopy,
	IPaginatedQuery,
	IQueryFavorite,
	IQueryFavoriteWithSearch,
	IQueryFiltered,
} from "../../../hooks/useQuery";
import snack from "../../../utils/snack";
import { Objective } from "../../usersManagement/types/objectiveTypes";
import { IBuyer, IUser } from "../../../interfaces";
import { IWholeSaler } from "../../usersManagement/types/types";
import { AdvancedPayment } from "../../usersManagement/types/incentivesTypes";
import { ObjIncentiveImage } from "../../usersManagement/types/commonObjIncTypes";

export interface SalesRep {
	customId: string;
	name: string;
}

export interface Accounts {
	_id: string;
	name: string;
	salesRep: SalesRep;
	customId: string;
}

interface Store {
	objectives: Objective[];
	groupedObjectives: Objective[];
	filteredObjectives: Objective[];
	accounts: Accounts[];
	filteredCustomers: any;
	totalPages: number;
	totalFilteredPages: number;
	currentFilteredPage: number;
	loadingFiltered: boolean;
	totalGroupedPages: number;
	currentGroupedPage: number;
	loadingGrouped: boolean;
	currentPage: number;
	secondaryCurrentPage: number;
	reportingGroupedPage: number;
	secondaryTotalPages: number;
	reportingGroupedTotalPages: number;
	setSecondaryCurrentPage: (page: number) => void;
	setReportingGroupedPage: (page: number) => void;
	loading: boolean;
	archivedTotalPages: number;
	archivedCurrentPage: number;
	archivedSecondaryCurrentPage: number;
	archivedSecondaryTotalPages: number;
	setArchivedSecondaryCurrentPage: (page: number) => void;
	loadingArchived: boolean;
	archivedObjectives: Objective[];
	favoriteObjectives: Objective[];
	totalFavPages: number;
	currentFavPage: number;
	users: IUser[];
	products: IWholeSaler | null;
	customers: IBuyer[] | null;
	setLoading: (loading: boolean) => void;
	setLoadingFiltered: (loading: boolean) => void;
	setLoadingGrouped: (loading: boolean) => void;
	setLoadingArchived: (loading: boolean) => void;
	setCurrentPage: (page: number) => void;
	setCurrentFilteredPage: (page: number) => void;
	setCurrentGroupedPage: (page: number) => void;
	setCurrentFavPage: (page: number) => void;
	setArchivedCurrentPage: (page: number) => void;
	fetchObjectives: (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => void;
	fetchFilteredObjectives: (
		query: IQueryFiltered,
		callback?: () => void
	) => void;
	fetchSubObjectives: (
		query: IGeneralQuery,
		objectiveId: string,
		isArchived?: boolean
	) => void;
	fetchChildren: (query: IGeneralQuery, parentId: string) => void;
	fetchGroupedObjectives: (query: IQueryFavorite, objectiveId: string) => void;
	fetchArchivedObjectives: (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => void;
	fetchFavoriteObjectives: (query: IQueryFavoriteWithSearch) => void;
	editObjective: (query: IGeneralQuery, objective: Partial<Objective>) => any;
	editSubObjective: (
		query: IGeneralQuery,
		objective: Partial<Objective>,
		parentId: string,
		suppressSnack: boolean
	) => any;
	columns: string[];
	rows: any[];
	sortColumnName: string | undefined;
	sortDirection: ISortDirection;
	setDirection: (dir: ISortDirection) => void;
	setColumnName: (colName: string) => void;
	setRows: (rows: any[]) => void;
	hoveredId?: string | number;
	selectedId?: string | number;
	setHoveredId: (id: string | number) => void;
	setSelectedId: (id: string | number) => void;
	setOrder: (dir: ISortDirection, colName: string) => void;
	addObjective: (
		query: IGeneralQueryWithCopy,
		newObjective: Omit<Objective, "_id" | "customId">
	) => Promise<Objective | undefined>;
	addSubObjective: (
		query: IGeneralQueryWithCopy,
		newObjective: Omit<Objective, "_id" | "customId">,
		parentObjectiveId: string
	) => Promise<Objective>;
	addGroupedObjectives: (
		query: IGeneralQuery,
		childIds: { id: string; weight: number }[],
		parentObjectiveId: string
	) => Promise<Objective[]>;
	deleteObjective: (query: IGeneralQuery, objectiveId: string) => void;
	deleteSubObjective: (
		query: IGeneralQuery,
		objectiveId: string,
		parentId: string
	) => Promise<Objective | undefined>;
	fetchAllUsers: (query: IGeneralQuery) => void;
	fetchProducts: (query: IGeneralQuery) => void;
	fetchCustomers: (query: IGeneralQuery) => void;
	fetchCustomersPaginated: (query: IPaginatedQuery) => void;
	fetchObjectivesReportTable: (
		query: IQueryFavoriteWithSearch,
		callback?: () => void,
		callbackGrouped?: () => void
	) => void;
	runQueryForObjective: (query: IGeneralQuery, objectiveId: string) => void;
	rejectImage: (
		query: IGeneralQuery,
		itemId: string,
		imageId: string,
		rejectedReason: string
	) => void;
	unRejectImage: (
		query: IGeneralQuery,
		itemId: string,
		imageId: string
	) => void;
}

export const objectivesStoreZustand = create<Store>((set, get) => ({
	objectives: [],
	filteredObjectives: [],
	groupedObjectives: [],
	accounts: [],
	totalPages: 1,
	totalFilteredPages: 1,
	currentFilteredPage: 1,
	totalGroupedPages: 1,
	currentGroupedPage: 1,
	currentPage: 1,
	secondaryCurrentPage: 1,
	reportingGroupedPage: 1,
	secondaryTotalPages: 1,
	reportingGroupedTotalPages: 1,
	setSecondaryCurrentPage: (page: number) =>
		set({ secondaryCurrentPage: page }),
	setReportingGroupedPage: (page: number) =>
		set({ reportingGroupedPage: page }),
	loading: false,
	loadingFiltered: false,
	loadingGrouped: false,
	archivedTotalPages: 1,
	archivedCurrentPage: 1,
	archivedSecondaryCurrentPage: 1,
	archivedSecondaryTotalPages: 1,
	setArchivedSecondaryCurrentPage: (page: number) =>
		set({ archivedSecondaryCurrentPage: page }),
	loadingArchived: false,
	favoriteObjectives: [],
	totalFavPages: 1,
	currentFavPage: 1,
	archivedObjectives: [],
	users: [],
	products: null,
	customers: null,
	filteredCustomers: null,
	hoveredId: undefined,
	columns: objColumns,
	sortColumnName: undefined,
	sortDirection: "asc",
	rows: [],
	setLoading: (loading: boolean) => set({ loading }),
	setLoadingFiltered: (loadingFiltered: boolean) => set({ loadingFiltered }),
	setLoadingGrouped: (loadingGrouped: boolean) => set({ loadingGrouped }),
	setLoadingArchived: (loadingArchived: boolean) => set({ loadingArchived }),
	setCurrentPage: (page: number) => set({ currentPage: page }),
	setCurrentFilteredPage: (page: number) => set({ currentFilteredPage: page }),
	setCurrentGroupedPage: (page: number) => set({ currentGroupedPage: page }),
	setCurrentFavPage: (page: number) => set({ currentFavPage: page }),
	setArchivedCurrentPage: (page: number) => set({ archivedCurrentPage: page }),
	setDirection: (dir: ISortDirection) => set({ sortDirection: dir }),
	setRows: (rows: any) => set({ rows }),
	setColumnName: (colName: string) => set({ sortColumnName: colName }),
	setHoveredId: (id: string | number) => set({ hoveredId: id }),
	setSelectedId: (id: string | number) => set({ selectedId: id }),
	fetchObjectives: async (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => {
		set({ loading: true });
		const res = await api.fetch({
			path: "/api/v2/objectives",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ objectives: res.payload, totalPages: res?.meta?.totalPages });
			if (callback) callback();
			set({ loading: false });
		} else {
			console.log("error fetching objectives");
			set({ objectives: [] });
			set({ loading: false });
		}
	},
	fetchFilteredObjectives: async (
		query: IQueryFiltered,
		callback?: () => void
	) => {
		set({ loadingFiltered: true });
		const res = await api.fetch({
			path: "/api/v2/filteredObjectives",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({
				filteredObjectives: res.payload,
				totalFilteredPages: res?.meta?.totalPages,
			});
			if (callback) callback();
			set({ loadingFiltered: false });
		} else {
			console.log("error fetching objectives");
			set({ filteredObjectives: [] });
			set({ loadingFiltered: false });
		}
	},
	fetchSubObjectives: async (
		query: IGeneralQuery,
		objectiveId: string,
		isArchived?: boolean
	) => {
		const res = await api.fetch({
			path: `/api/v2/objective/${objectiveId}/subObjectives`,
			method: "GET",
			query,
		});

		if (res.ok === true && res.payload?.length > 0) {
			set((state) => {
				const targetObjectivesKey = isArchived
					? "archivedObjectives"
					: "objectives";

				const updatedObjectives = state[targetObjectivesKey].map(
					(objective) => {
						if (objective._id === objectiveId) {
							return {
								...objective,
								subObjectives: res.payload,
							};
						}
						return objective;
					}
				);

				return { [targetObjectivesKey]: updatedObjectives };
			});
		} else {
			console.log(
				"error fetching subObjectives or no subObjectives found",
				res
			);
		}
	},
	fetchChildren: async (query: IGeneralQuery, parentId: string) => {
		const res = await api.fetch({
			path: `/api/v2/reporting/item/${parentId}`,
			method: "GET",
			query,
		});
		if (res.ok === true) {
			set((state) => {
				const updatedObjectives = state.objectives.map((objective) => {
					if (objective._id === parentId) {
						return {
							...objective,
							groupedObjectives: res.payload.groupedChildren,
						};
					}
					return objective;
				});

				return { objectives: updatedObjectives };
			});
		} else {
			return;
		}
	},
	fetchGroupedObjectives: async (
		query: IQueryFavorite,
		objectiveId: string
	) => {
		set({ loadingGrouped: true });
		const res = await api.fetch({
			path: `/api/v2/groupedObjectives/${objectiveId}/children`,
			method: "GET",
			query,
		});
		if (res.ok === true && res.payload?.length > 0) {
			set({
				groupedObjectives: res.payload,
				totalGroupedPages: res.meta?.totalPages,
			});
			set({ loadingGrouped: false });
		} else {
			set({ groupedObjectives: [] });
			set({ loadingGrouped: false });
		}
	},
	fetchArchivedObjectives: async (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => {
		set({ loadingArchived: true });
		const res = await api.fetch({
			path: "/objectives/archived",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({
				archivedObjectives: res.payload,
				archivedTotalPages: res?.meta?.totalPages,
			});
			if (callback) callback();
			set({ loadingArchived: false });
		} else {
			console.log("error fetching objectives");
			set({ archivedObjectives: [] });
			set({ loadingArchived: false });
		}
	},
	fetchFavoriteObjectives: async (query: IQueryFavoriteWithSearch) => {
		const res = await api.fetch({
			path: "/api/v2/objectives",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({
				favoriteObjectives: res.payload,
				totalFavPages: res?.meta?.totalPages,
				currentFavPage: res?.meta?.page,
			});
		} else {
			console.log("error fetching objectives");
			set({ favoriteObjectives: [] });
		}
	},
	addObjective: async (
		query: IGeneralQueryWithCopy,
		newObjective: Omit<Objective, "_id" | "customId">
	) => {
		const res = await api.fetch({
			path: "/objective",
			method: "POST",
			query,
			body: newObjective,
		});

		if (res.ok) {
			set((state) => {
				const updatedObjectives = [...state.objectives, res.payload];

				if (
					(res.payload.type === "multi" ||
						res.payload.type === "dependencies") &&
					query.parentId
				) {
					const { parentId, ...queryWithoutParentId } = query;

					objectivesStoreZustand
						.getState()
						.fetchSubObjectives(queryWithoutParentId, res.payload._id);
				}

				return { objectives: updatedObjectives };
			});
			if (query.parentId) {
				snack.success("The objective has been successfully copied");
			}
			return res.payload;
		} else {
			console.log("error adding objective");
		}
	},
	addSubObjective: async (
		query: IGeneralQueryWithCopy,
		newObjective: Omit<Objective, "_id" | "customId">,
		parentObjectiveId: string
	) => {
		const res = await api.fetch({
			path: "/subObjective",
			method: "POST",
			query,
			body: { ...newObjective, parentObjectiveId },
		});

		if (res.ok) {
			set((state) => {
				// Find the parent objective and update its subObjectives array
				const updatedObjectives = state.objectives.map((objective) => {
					if (objective._id === parentObjectiveId) {
						return {
							...objective,
							subObjectives: [...(objective.subObjectives || []), res.payload],
						};
					}
					return objective;
				});

				return { objectives: updatedObjectives };
			});
			return res.payload as Objective;
		} else {
			console.log("error adding sub-objective", res);
			return {} as Objective;
		}
	},
	addGroupedObjectives: async (
		query: IGeneralQuery,
		childIds: { id: string; weight: number }[],
		parentObjectiveId: string
	) => {
		const res = await api.fetch({
			path: `/api/v2/addGroupedObjectivesChildren/${parentObjectiveId}`,
			method: "POST",
			query,
			body: { childIds },
		});
		if (res.ok) {
			set((state) => {
				// Find the parent objective and update its groupedObjectives array
				const updatedObjectives = state.objectives.map((objective) => {
					if (objective._id === parentObjectiveId) {
						return {
							...objective,
							groupedObjectives: [
								...(objective.groupedObjectives || []),
								...(childIds
									.map(({ id }) =>
										state.objectives.find((obj) => obj._id === id)
									)
									.filter(Boolean) as Objective[]),
							],
						};
					}
					return objective;
				});
				return { ...state, objectives: updatedObjectives };
			});
			return childIds
				.map(({ id }) => get().objectives.find((obj) => obj._id === id))
				.filter(Boolean) as Objective[];
		} else {
			console.log("error linking grouped objectives", res);
			return [];
		}
	},
	deleteObjective: async (query: IGeneralQuery, objectiveId: string) => {
		const res = await api.fetch({
			path: `/objective/${objectiveId}`,
			method: "DELETE",
			query,
		});

		if (res.ok) {
			set((state) => ({
				objectives: [
					...state.objectives.filter((obj) => obj._id !== objectiveId),
				],
			}));
		} else {
			console.log("error deleting objective");
		}
	},
	deleteSubObjective: async (
		query: IGeneralQuery,
		objectiveId: string,
		parentId: string
	) => {
		const res = await api.fetch({
			path: `/api/v2/subObjective/${objectiveId}`,
			method: "DELETE",
			query,
		});

		if (res.ok) {
			let updatedParentObjective = undefined;
			set((state) => {
				const updatedObjectives = state.objectives.map((objective) => {
					if (objective._id === parentId) {
						updatedParentObjective = {
							...objective,
							subObjectives: objective.subObjectives?.filter(
								(sub) => sub._id !== objectiveId
							),
						};
						return updatedParentObjective;
					}
					return objective;
				});
				return { objectives: updatedObjectives };
			});

			if (updatedParentObjective) {
				snack.success("Delete completed 👍");
				return updatedParentObjective;
			} else {
				throw new Error("Parent objective not found");
			}
		} else {
			snack.error("Delete failed");
		}
	},
	setOrder: (dir: ISortDirection, colName: string) => {
		set((state: Store) => {
			const updatedState: Partial<Store> = {};

			const currentRows = [...state.rows];
			updatedState.rows = currentRows.sort((a, b) => {
				const aVal = a[colName];
				const bVal = b[colName];
				if (aVal > bVal) {
					return dir === "asc" ? -1 : 1;
				} else if (aVal < bVal) {
					return dir === "asc" ? 1 : -1;
				} else {
					return 0;
				}
			});
			return updatedState;
		});
	},
	editObjective: async (
		query: IGeneralQuery,
		objective: Partial<Objective>
	) => {
		snack.info("Updating...");

		const res = await api.fetch({
			path: `/api/v2/objective/${objective._id}`,
			method: "PATCH",
			query,
			body: objective,
		});

		if (res.ok) {
			snack.success("Update completed 👍");
			console.log("Update completed 👍", res.payload);
			set((state) => {
				// Find the index of the objective being updated
				const objectiveIndex = state.objectives.findIndex(
					(o) => o._id === objective._id
				);

				// Find the index of the archived objective being updated
				const archivedObjectiveIndex = state.archivedObjectives.findIndex(
					(o) => o._id === objective._id
				);

				// Preserve existing subObjectives or similar data
				const existingSubObjectives =
					objectiveIndex !== -1
						? state.objectives[objectiveIndex].subObjectives
						: null;

				// Assuming res.payload contains the updated objective without subObjectives
				const updatedObjective =
					objectiveIndex !== -1
						? {
								...state.objectives[objectiveIndex],
								...res.payload, // Merge updated objective data
								subObjectives: existingSubObjectives, // Keep existing subObjectives unchanged
						  }
						: res.payload;

				// Clone the existing objectives array for immutability
				const updatedObjectives =
					objectiveIndex !== -1 ? [...state.objectives] : state.objectives;
				if (objectiveIndex !== -1) {
					updatedObjectives[objectiveIndex] = updatedObjective;
				}

				const updatedArchivedObjectives =
					archivedObjectiveIndex !== -1
						? state.archivedObjectives.map((o) =>
								o._id === objective._id ? { ...o, ...res.payload } : o
						  )
						: state.archivedObjectives;

				const newArchivedObjectives = res.payload.archived
					? [...updatedArchivedObjectives, res.payload]
					: updatedArchivedObjectives.filter((o) => o._id !== objective._id);

				const newObjectives = res.payload.archived
					? updatedObjectives.filter((o) => o._id !== objective._id)
					: updatedObjectives;
				// Return the new state
				return {
					objectives: newObjectives,
					archivedObjectives: newArchivedObjectives,
				};
			});

			return res.payload;
		} else {
			console.log("error updating objective", res);
		}
	},

	editSubObjective: async (
		query: IGeneralQuery,
		objective: Partial<Objective>,
		parentId: string,
		suppressSnack: boolean
	) => {
		if (!suppressSnack) {
			snack.info("Updating...");
		}

		console.log("editSubObjective objective", objective);
		const res = await api.fetch({
			path: `/api/v2/subObjective/${objective._id}`,
			method: "PATCH",
			query,
			body: objective,
		});
		if (res.ok) {
			set((state) => {
				const updatedObjectives = state.objectives.map((obj) => {
					if (obj._id === parentId) {
						return {
							...obj,
							subObjectives: obj.subObjectives?.map((sub) =>
								sub._id === objective._id ? res.payload : sub
							),
						};
					} else {
						return obj;
					}
				});

				return { objectives: updatedObjectives };
			});
			if (!suppressSnack) {
				snack.success("Update completed 👍");
			}

			return res.payload;
		} else {
			console.log("error updating objective");
		}
	},
	fetchAllUsers: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: "/users",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ users: res.payload });
		} else {
			console.log("error fetching users");
			set({ users: [] });
		}
	},
	fetchProducts: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: "/wholesaler",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ products: res.payload });
		} else {
			console.log("error fetching users");
			set({ products: null });
		}
	},
	fetchCustomers: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: "/buyersByAccountType",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ customers: res.payload });
		} else {
			console.log("error fetching users");
			set({ customers: null });
		}
	},
	fetchCustomersPaginated: async (query: IPaginatedQuery) => {
		const res = await api.fetch({
			path: "/buyersByAccountTypePaginated",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ filteredCustomers: res });
		} else {
			console.log("error fetching users");
			set({ filteredCustomers: null });
		}
	},
	fetchObjectivesReportTable: async (
		query: IQueryFavoriteWithSearch,
		callback?: () => void,
		callbackGrouped?: () => void
	) => {
		set({ loading: true });
		const res = await api.fetch({
			path: "/api/v2/objectives",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ objectives: res.payload, totalPages: res?.meta?.totalPages });
			if (callback) callback();
			if (callbackGrouped) callbackGrouped();
			set({ loading: false });
		} else {
			console.log("error fetching objectives");
			set({ objectives: [] });
			set({ loading: false });
		}
	},
	runQueryForObjective: async (query: IQueryFavorite, objectiveId: string) => {
		const res = await api.fetch({
			path: `/runQueryForObjective/${objectiveId}`,
			method: "GET",
			query,
		});

		if (res.ok) {
			snack.success("Action completed 👍", 10000);
			console.log("Action completed 👍", res.payload);
		} else {
			console.log("error");
		}
	},
	rejectImage: async (
		query: IGeneralQuery,
		itemId: string,
		imageId: string,
		rejectedReason: string
	) => {
		const res = await api.fetch({
			path: `/api/v2/item/${itemId}/rejectImage/${imageId}`,
			method: "PATCH",
			query,
			body: { rejectedReason },
		});

		if (res.ok) {
			snack.success("Image was rejected 👍");

			set(() => {
				const rows = get().rows;
				const updatedRows = rows.map((row) => {
					const updatedImages = row.image.filter(
						(image: ObjIncentiveImage) => image._id !== imageId
					);
					const rejectedImage = row.image.find(
						(image: ObjIncentiveImage) => image._id === imageId
					);

					const updatedRejectedImages = rejectedImage
						? [
								...(row.rejectedImages || []),
								{
									...rejectedImage,
									rejected: true,
									rejectedReason: rejectedReason,
								},
						  ]
						: row.rejectedImages || [];

					if (rejectedImage) {
						return {
							...row,
							image: updatedImages,
							rejectedImages: updatedRejectedImages,
							totalItems: res.payload.totalItems,
							status: res.payload.percentageAchieved,
							payoutMTD: res.payload.totalAchieved,
						};
					}

					return {
						...row,
						image: updatedImages,
						rejectedImages: updatedRejectedImages,
					};
				});

				return { rows: updatedRows };
			});
		} else {
			snack.error("Failed to reject image 👎");
		}
	},
	unRejectImage: async (
		query: IGeneralQuery,
		itemId: string,
		imageId: string
	) => {
		const res = await api.fetch({
			path: `/api/v2/item/${itemId}/unRejectImage/${imageId}`,
			method: "PATCH",
			query,
		});

		if (res.ok) {
			snack.success("Image was restored 👍");

			set(() => {
				const rows = get().rows;
				const updatedRows = rows.map((row) => {
					const unrejectedImage = row.rejectedImages?.find(
						(image: ObjIncentiveImage) => image._id === imageId
					);

					const updatedRejectedImages =
						row.rejectedImages?.filter(
							(image: ObjIncentiveImage) => image._id !== imageId
						) || [];

					const updatedImages = unrejectedImage
						? [
								...(row.image || []),
								{
									...unrejectedImage,
									rejected: false,
									rejectedReason: "",
								},
						  ]
						: row.image || [];

					if (unrejectedImage) {
						return {
							...row,
							image: updatedImages,
							rejectedImages: updatedRejectedImages,
							totalItems: res.payload.totalItems,
							status: res.payload.percentageAchieved,
							payoutMTD: res.payload.totalAchieved,
						};
					}

					return {
						...row,
						image: updatedImages,
						rejectedImages: updatedRejectedImages,
					};
				});

				return { rows: updatedRows };
			});
		} else {
			snack.error("Failed to restore image 👎");
		}
	},
}));
