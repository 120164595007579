import { useEffect, useState } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { AdvancedPayment, QuantityAdvanced } from "../../usersManagement/types/objectiveTypes";
import { commonObjIncStoreZustand } from "../store/commonObjIncStore";

const useUserUpdate = () => {
	const { query } = useQuery<IGeneralQuery>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	const updateUserDetails = async (
		isObjective: boolean,
		target: number | string,
		payout: number,
		payoutType: string,
		itemId: string,
		userId: string,
		isOpenEnded: boolean,
		threshold: { units: string; value: number },
		cap: { units: string; value: number },
		minQuantityValue: number,
		isAccountSpecific: boolean,
		isFullAmount: boolean,
		cappedStatus: boolean,
		advancedBudget?: number,
		advancedPayments?: AdvancedPayment[],
		maxImagesPerAccount?: number,
		quantityAdvanced?: QuantityAdvanced[]
	) => {
		commonObjIncStoreZustand
			.getState()
			.updateUserTargetAndPayout(
				query,
				itemId,
				userId,
				payoutType,
				payout,
				target,
				isOpenEnded,
				threshold,
				cap,
				minQuantityValue,
				isAccountSpecific,
				isFullAmount,
				isObjective,
				cappedStatus,
				advancedBudget,
				advancedPayments,
				maxImagesPerAccount,
				quantityAdvanced,
			);
	};

	return {
		updateUserDetails,
	};
};

export default useUserUpdate;
