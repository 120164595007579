import { Box, Dialog, makeStyles } from "@material-ui/core";
import { useState } from "react";
import useReportingItems from "../hooks/useReportingItems";
import { ItemsList } from "./ItemsList";
import { FilterSection } from "./FiltersSection";
import { SelectChangeEvent } from "@mui/material";
import moment from "moment";
import useReportingRows from "../hooks/useReportingRows";
import ItemsManagementReportTable from "./ItemManagementReportTable";
import { EditUserTargetPayout } from "../utils/EditUserTargetPayout";
import {
	getPayoutType,
	PayoutDetails,
} from "../../usersManagement/objectivesIncentivesComponent/editObjIncComponents/utils";
import { IRow } from "../store/commonObjIncStore";
import useUserUpdate from "../hooks/useUserUpdate";
import {
	determineColumnsToShowIncentive,
	determineColumnsToShowObjective,
	initialPayoutDetails,
} from "../utils/utils";
import useColumns from "../hooks/useColumns";

interface ItemsManagementProps {
	type: "objective" | "incentive";
	isArchived: boolean;
	setIsArchived: (isArchived: boolean) => void;
}

const useStyles = makeStyles((_) => ({
	container: {
		flex: 1,
		display: "flex",
		flexDirection: "row",
		maxHeight: "87vh",
	},
}));

export const ItemsManagement = ({
	type,
	isArchived,
	setIsArchived,
}: ItemsManagementProps) => {
	const classes = useStyles();
	const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>([]);
	const [userSearch, setUserSearch] = useState<string>("");
	const [editRow, setEditRow] = useState<IRow>();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [payoutDetails, setPayoutDetails] =
		useState<PayoutDetails>(initialPayoutDetails);
	const [username, setUsername] = useState<string | undefined>("");
	const [minQuantityValue, setMinQuantityValue] = useState<number | undefined>(
		0
	);
	const [maxImagesPerAccount, setMaxImagesPerAccount] = useState<number | undefined>(
		1
	);
	const [refetchRows, setRefetchRows] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [groupByTeams, setGroupByTeams] = useState<boolean>(false);

	const [filters, setFilters] = useState<{
		term: string;
		type: string;
		tag: string;
		periodStart: string | null;
		periodEnd: string | null;
	}>({
		term: "",
		type: "",
		tag: "",
		periodStart: null,
		periodEnd: null,
	});

	const { reportingItems, loading, totalPages } = useReportingItems({
		isObjective: type === "objective",
		searchPhrase: filters.term !== "" ? filters.term : undefined,
		type: filters.type !== "" ? filters.type : undefined,
		tag: filters.tag !== "" ? filters.tag : undefined,
		periodStart: filters.periodStart !== "" ? filters.periodStart : undefined,
		periodEnd: filters.periodEnd !== "" ? filters.periodEnd : undefined,
		isArchived,
		currentPage,
		setCurrentPage,
	});

	const { rows, loadingRows } = useReportingRows({
		itemsIds: selectedItemsIds,
		itemType: type,
		refetchRows,
		setRefetchRows,
	});

	const { columns } = useColumns({ type });

	const { updateUserDetails } = useUserUpdate();

	const [showAllColumns, setShowAllColumns] = useState(false);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newSearchTerm = event.target.value;
		setFilters((prev) => ({ ...prev, term: newSearchTerm }));
	};

	const handleFilterTypeChange = (selectedOption: SelectChangeEvent) => {
		setFilters((prev) => ({
			...prev,
			type: selectedOption.target.value as string,
		}));
		setCurrentPage(1);
	};

	const handleTagChange = (selectedOption: SelectChangeEvent) => {
		setFilters((prev) => ({
			...prev,
			tag: selectedOption.target.value as string,
		}));
		setCurrentPage(1);
	};

	const handlePeriodStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value) {
			const formattedDate = moment(value).format("YYYY-MM-DDTHH:mm:ss");
			setFilters((prev) => ({ ...prev, periodStart: formattedDate }));
		} else {
			setFilters((prev) => ({ ...prev, periodStart: null }));
		}
		setCurrentPage(1);
	};

	const handlePeriodEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value) {
			const formattedDate = moment(value).format("YYYY-MM-DDTHH:mm:ss");
			setFilters((prev) => ({ ...prev, periodEnd: formattedDate }));
		} else {
			setFilters((prev) => ({ ...prev, periodEnd: null }));
		}
		setCurrentPage(1);
	};

	const handleSearchUserChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const newSearchTerm = event.target.value;
		setUserSearch(newSearchTerm);
		setCurrentPage(1);
	};

	const columnsToShow = showAllColumns
		? columns
		: type === "objective"
		? determineColumnsToShowObjective(rows)
		: determineColumnsToShowIncentive(rows);

	const toggleShowAllColumns = () => {
		setShowAllColumns((prev) => !prev);
	};

	const handleEditClick = (row: IRow) => {
		console.log(row);
		setIsModalOpen(true);
		setEditRow(row);
		setUsername(row.userName);
		setPayoutDetails({
			target: row.target,
			payoutType: row.payoutType || "",
			threshold: row.threshold,
			cap: row.cap,
			isOpenEnded: row.isOpenEnded,
			allOrNothingAmount: row.payout,
			perActionAmount: row.payout,
			atRiskAmount: row.payout,
			advancedBudget: row.advancedBudget,
			advancedPayments: row.advancedPayments,
			quantityAdvanced: row.quantityAdvanced,
			isAccountSpecific: row.isAccountSpecific,
			isFullAmount: row.isFullAmount,
			cappedStatus: row.cappedStatus,
		});
		setMinQuantityValue(row.minQuantityValue);
		setMaxImagesPerAccount(row.maxImagesPerAccount);
	};

	const handleSave = (
		updatedPayoutDetails: PayoutDetails,
		minQuantityValue: number,
		maxImagesPerAccount: number
	) => {
		const row = rows.find(
			(row) => row.itemId === editRow?.itemId && row.userId === editRow?.userId
		);
		updateUserDetails(
			type === "objective" ? true : false,
			updatedPayoutDetails.target,
			getPayoutType(updatedPayoutDetails),
			updatedPayoutDetails.payoutType,
			row?.itemId ?? "",
			row?.userId ?? "",
			updatedPayoutDetails.isOpenEnded,
			updatedPayoutDetails.threshold,
			updatedPayoutDetails.cap,
			minQuantityValue,
			updatedPayoutDetails.isAccountSpecific,
			updatedPayoutDetails.isFullAmount,
			updatedPayoutDetails.cappedStatus,
			updatedPayoutDetails.advancedBudget,
			updatedPayoutDetails.advancedPayments,
			maxImagesPerAccount,
			updatedPayoutDetails.quantityAdvanced
		);
		setIsModalOpen(false);
	};

	const handleClearFilters = () => {
		setCurrentPage(1);
		setUserSearch("");
		setSelectedItemsIds([]);
		setIsArchived(false);
		setFilters({
			term: "",
			type: "",
			tag: "",
			periodStart: null,
			periodEnd: null,
		});
	};

	return (
		<Box
			className={classes.container}
			style={{ overflow: "auto", maxHeight: "100vh" }}
		>
			<ItemsList
				itemType={type === "objective" ? "Objectives" : "Incentives"}
				loading={loading}
				items={reportingItems}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				totalPages={totalPages}
				onItemSelect={setSelectedItemsIds}
				selectedItemsIds={selectedItemsIds}
				showArchived={isArchived}
				setShowArchived={setIsArchived}
				searchTerm={filters.term}
				onSearchChange={handleSearchChange}
			/>
			<Box
				maxWidth="90vw"
				width="100%"
				minWidth="65vw"
				sx={{ px: 3 }}
				style={{ maxHeight: "100vh" }}
			>
				<FilterSection
					filterType={filters.type}
					onTypeChange={handleFilterTypeChange}
					selectedTag={filters.tag}
					onTagChange={handleTagChange}
					periodStart={filters.periodStart}
					periodEnd={filters.periodEnd}
					onPeriodStartChange={handlePeriodStartChange}
					onPeriodEndChange={handlePeriodEndChange}
					userSearch={userSearch}
					onSearchUserChange={handleSearchUserChange}
					columns={columnsToShow}
					rows={rows}
					itemType={type}
					showAllColumns={showAllColumns}
					toggleShowAllColumns={toggleShowAllColumns}
					handleClearFilters={handleClearFilters}
					selectedItemsIds={selectedItemsIds}
					groupByTeams={groupByTeams}
					setGroupByTeams={setGroupByTeams}
				/>
				<ItemsManagementReportTable
					itemType={type}
					loading={loadingRows}
					columns={columnsToShow}
					rows={rows}
					onEdit={handleEditClick}
					searchValue={userSearch.toLocaleLowerCase()}
					setRefetchRows={setRefetchRows}
					groupByTeams={groupByTeams}
					setGroupByTeams={setGroupByTeams}
				/>
			</Box>
			<Dialog
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				fullWidth
				disableEnforceFocus
				PaperProps={{
					style: {
						position: "absolute",
						right: 0,
						minWidth: "50vw",
						maxHeight: "100vh",
						height: "100%",
						margin: 0,
						borderRadius: 0,
					},
				}}
			>
				<EditUserTargetPayout
					username={username}
					initialPayoutDetails={payoutDetails}
					onSave={handleSave}
					editRow={editRow}
					initialMinQuantityValue={minQuantityValue}
					itemType={type === "objective" ? "Objectives" : "Incentives"}
					initialMaxImagesPerAccount={maxImagesPerAccount}
				/>
			</Dialog>
		</Box>
	);
};
