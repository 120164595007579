import { useMemo, useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { mapRowToColumns } from "../utils/mapRowToColumns";
import { Box, CircularProgress, Paper, styled } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import { useStyles } from "./ItemManagementReportTable.styles";
import { Stack } from "@mui/material";
import { commonObjIncStoreZustand, IRow } from "../store/commonObjIncStore";
import {
	filterRows,
	calculateTableTotals,
	transformColumnName,
} from "../utils/utils";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import React from "react";

interface IProps {
	itemType: string;
	loading: boolean;
	columns: string[];
	rows: IRow[];
	onEdit: (row: any) => void;
	searchValue: string;
	setRefetchRows: (refetchRows: boolean) => void;
	groupByTeams: boolean;
	setGroupByTeams: (groupByTeams: boolean) => void;
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
	maxHeight: "calc(100vh - 300px)",
	overflowX: "visible",
	overflowY: "visible",
	width: "100%",
	"&::-webkit-scrollbar": {
		// display: "none",
	},
	"& .MuiTableCell-head": {
		backgroundColor: "#D0D5ED",
		fontWeight: 600,
		color: "#3D4EA1",
	},
	"& .MuiTableCell-root": {
		padding: theme.spacing(1.2),
		maxWidth: "250px",
	},
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	padding: theme.spacing(1.5),
}));

const ScrollContainer = styled(Box)({
	maxHeight: "calc(100vh - 300px)",
	overflowX: "auto",
	overflowY: "auto",
	width: "100%",
});

interface ISalesRepData {
	salesRep: IRow;
}

interface ITeamLeadData {
	teamLead: IRow;
	salesReps: ISalesRepData[];
}

interface ILeadershipData {
	leadership: IRow;
	teamLeads: ITeamLeadData[];
}

// Add new interface for item grouping
interface IItemGroup {
	itemId: string;
	itemName: string;
	leadershipGroups: ILeadershipData[];
	teamLeadGroups: ITeamLeadData[];
	ungroupedSalesReps: ISalesRepData[];
}

// Helper function to create unique group key
const createGroupKey = (userId: string, itemId: string) => `${itemId}-${userId}`;

export default function ItemsManagementReportTable(props: IProps) {
	const { itemType, loading, columns, rows, onEdit, searchValue, setRefetchRows, groupByTeams, setGroupByTeams } = props;

	const isObjective = itemType === "objective";

	const {
		sortColumnName,
		sortDirection,
		setDirection,
		setColumnName,
		setOrder,
		orderedColumns,
		setOrderedColumns,
	} = commonObjIncStoreZustand();

	const classes = useStyles();
	
	const [fixedColumns, setFixedColumns] = useState<string[]>([]);
	const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});

	const filteredRows = useMemo(
		() => filterRows(rows, searchValue),
		[rows, searchValue]
	);

	const totals = useMemo(() => {
		return calculateTableTotals(filteredRows, itemType);
	}, [filteredRows, itemType]);
	
	const groupedRows = useMemo(() => {
		// First, group all rows by itemId
		const itemGroups = new Map<string, IRow[]>();
		
		filteredRows.forEach(row => {
			if (!itemGroups.has(row.itemId)) {
				itemGroups.set(row.itemId, []);
			}
			itemGroups.get(row.itemId)?.push(row);
		});

		// Now process each item group separately
		const processedItemGroups: IItemGroup[] = Array.from(itemGroups.entries()).map(([itemId, itemRows]) => {
			// Track assigned users within this item group
			const assignedTeamLeadIds = new Set<string>();
			const assignedSalesRepIds = new Set<string>();

			// Find leadership users for this item
			const leadershipUsers = itemRows.filter(row => row.isLeadership);
			
			// Find team leads for this item
			const teamLeads = itemRows.filter(row => 
				row.isTeamLead  && 
				!row.isLeadership
			);

			// Process leadership hierarchy
			const leadershipGroups = leadershipUsers.map(leadership => {
				// Find teamLeads under this leadership
				const leadersTeamLeads = teamLeads.filter(teamLead => 
					!assignedTeamLeadIds.has(teamLead.userId)
				);
				
				leadersTeamLeads.forEach(tl => assignedTeamLeadIds.add(tl.userId));
				
				// For each teamLead, find their salesReps
				const teamLeadsData = leadersTeamLeads.map(teamLead => {
					const teamLeadRoutes = Array.isArray(teamLead.route) 
						? teamLead.route 
						: [teamLead.route];
					
					const salesReps = itemRows.filter(row => 
						!row.isTeamLead && 
						!row.isLeadership &&
						!assignedSalesRepIds.has(row.userId) &&
						(Array.isArray(row.route) 
							? row.route.some(route => teamLeadRoutes.includes(route))
							: teamLeadRoutes.includes(row.route))
					);
					
					salesReps.forEach(rep => assignedSalesRepIds.add(rep.userId));
					
					return {
						teamLead,
						salesReps: salesReps.map(salesRep => ({ salesRep }))
					};
				});
				
				return {
					leadership,
					teamLeads: teamLeadsData
				};
			});

			// Process remaining teamLeads for this item
			const remainingTeamLeads = teamLeads
				.filter(teamLead => !assignedTeamLeadIds.has(teamLead.userId))
				.map(teamLead => {
					const teamLeadRoutes = Array.isArray(teamLead.route) 
						? teamLead.route 
						: [teamLead.route];
					
					const salesReps = itemRows.filter(row => 
						!row.isTeamLead && 
						!row.isLeadership &&
						!assignedSalesRepIds.has(row.userId) &&
						(Array.isArray(row.route) 
							? row.route.some(route => teamLeadRoutes.includes(route))
							: teamLeadRoutes.includes(row.route))
					);
					
					salesReps.forEach(rep => assignedSalesRepIds.add(rep.userId));
					
					return {
						teamLead,
						salesReps: salesReps.map(salesRep => ({ salesRep }))
					};
				});

			// Get remaining unassigned salesReps for this item
			const ungroupedSalesReps = itemRows
				.filter(row => 
					!row.isTeamLead && 
					!row.isLeadership &&
					!assignedSalesRepIds.has(row.userId)
				)
				.map(salesRep => ({ salesRep }));

			return {
				itemId,
				itemName: itemRows[0]?.name || itemId,
				leadershipGroups,
				teamLeadGroups: remainingTeamLeads,
				ungroupedSalesReps
			};
		});

		return processedItemGroups;
	}, [filteredRows]);
	
	// Initialize expanded state for all groups
	useEffect(() => {
		const initialExpanded: Record<string, boolean> = {};
		
		groupedRows.forEach(itemGroup => {
			// Leadership groups
			itemGroup.leadershipGroups.forEach(group => {
				initialExpanded[createGroupKey(group.leadership.userId, itemGroup.itemId)] = true;
				
				group.teamLeads.forEach(teamLead => {
					initialExpanded[createGroupKey(teamLead.teamLead.userId, itemGroup.itemId)] = true;
				});
			});
			
			// TeamLead groups
			itemGroup.teamLeadGroups.forEach(group => {
				initialExpanded[createGroupKey(group.teamLead.userId, itemGroup.itemId)] = true;
			});
		});
		
		setExpandedGroups(initialExpanded);
	}, [groupedRows]);
	
	useMemo(() => {
		if (orderedColumns.length === 0 || !columns.every(col => orderedColumns.includes(col))) {
			setOrderedColumns(columns);
		}
		
		if (columns.length >= 2) {
			setFixedColumns(columns.slice(0, 2));
		}
	}, [columns, orderedColumns, setOrderedColumns]);

	const toggleGroupExpanded = (userId: string, itemId: string) => {
		const groupKey = createGroupKey(userId, itemId);
		setExpandedGroups(prev => ({
			...prev,
			[groupKey]: !prev[groupKey]
		}));
	};

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index < 2) {
			return;
		}

		if (result.source.index < 2) {
			return;
		}

		const reorderedColumns = Array.from(orderedColumns);
		const [removed] = reorderedColumns.splice(result.source.index, 1);
		
		reorderedColumns.splice(result.destination.index, 0, removed);

		setOrderedColumns(reorderedColumns);
	};

	const handleSort = (column: string) => {
		const transformedColName = transformColumnName(column);
		setDirection(sortDirection === "asc" ? "desc" : "asc");
		setColumnName(column);
		setOrder(sortDirection === "asc" ? "desc" : "asc", transformedColName);
	};

	// useEffect(() => {
	// 	if (groupByTeams && sortColumnName) {
	// 		setGroupByTeams(false);
	// 	}
	// }, [groupByTeams, sortColumnName, setGroupByTeams]);

	return (
		<Box sx={{ my: 2 }}>
			<Stack spacing={2}>
				{!loading ? (
					<Paper elevation={2}>
						<ScrollContainer>
							<StyledTableContainer>
								<Table
									stickyHeader
									size="medium"
									style={{ width: "max-content", borderCollapse: "collapse" }}
								>
									<DragDropContext onDragEnd={onDragEnd}>
										<Droppable 
											droppableId="table-header" 
											direction="horizontal"
											renderClone={(provided, snapshot, rubric) => (
												<StyledTableCell
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={{
														...provided.draggableProps.style,
														zIndex: 10,
														backgroundColor: "#B8C0E5",
														width: "auto",
														color: "#3D4EA1",
														boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
													}}
												>
													<TableSortLabel>
														{orderedColumns[rubric.source.index]}
													</TableSortLabel>
												</StyledTableCell>
											)}
										>
											{(provided) => (
												<TableHead ref={provided.innerRef} {...provided.droppableProps}>
													<TableRow>
														{/* Column for expand/collapse icon */}
														{groupByTeams && (
															<StyledTableCell style={{ width: '40px' }}></StyledTableCell>
														)}
														
														{fixedColumns.map((column, index) => (
															<StyledTableCell
																key={column}
																className={column === "User Name" ? classes.stickyColumn : column === "Route" ? classes.stickyColumnLeft : ""}
																style={{
																	zIndex: (column === "User Name" || column === "Route" ? 2 : 1),
																}}
															>
																<TableSortLabel
																	active={sortColumnName === column}
																	direction={sortDirection}
																	onClick={() => handleSort(column)}
																>
																	{column}
																</TableSortLabel>
															</StyledTableCell>
														))}
														
														{orderedColumns.slice(2).map((column, index) => (
															<Draggable key={column} draggableId={column} index={index + 2}>
																{(provided, snapshot) => (
																	<StyledTableCell
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		style={{
																			...provided.draggableProps.style,
																			zIndex: snapshot.isDragging ? 3 : 1,
																			backgroundColor: snapshot.isDragging ? "transparent" : undefined,
																			opacity: snapshot.isDragging ? 0.5 : 1,
																			width: snapshot.isDragging ? "auto" : undefined,
																		}}
																	>
																		<TableSortLabel
																			active={sortColumnName === column}
																			direction={sortDirection}
																			onClick={() => {
																				const transformedColName =
																					transformColumnName(column);
																				setDirection(
																					sortDirection === "asc" ? "desc" : "asc"
																				);
																				setColumnName(column);
																				setOrder(
																					sortDirection === "asc" ? "desc" : "asc",
																					transformedColName
																				);
																			}}
																		>
																			{column}
																		</TableSortLabel>
																	</StyledTableCell>
																)}
															</Draggable>
														))}
														{provided.placeholder}
													</TableRow>
												</TableHead>
											)}
										</Droppable>
									</DragDropContext>
									{groupByTeams ? (
										<TableBody>
										{groupedRows.map((itemGroup) => (
											<React.Fragment key={`item-${itemGroup.itemId}`}>
												{/* Leadership groups within this item */}
												{itemGroup.leadershipGroups.map((leadershipGroup) => (
													<React.Fragment key={`leadership-${leadershipGroup.leadership.userId}-${itemGroup.itemId}`}>
														{/* Leadership row */}
														<TableRow 
															className={[classes.clickableLeadershipRow, classes.leadershipRow].join(" ")}
														>
															<TableCell style={{ width: '40px', textAlign: 'center' }}>
																<div 
																	onClick={() => toggleGroupExpanded(leadershipGroup.leadership.userId, itemGroup.itemId)}
																	style={{ cursor: 'pointer' }}
																>
																	{expandedGroups[createGroupKey(leadershipGroup.leadership.userId, itemGroup.itemId)] 
																		? <KeyboardArrowDownIcon /> 
																		: <KeyboardArrowRightIcon />}
																</div>
															</TableCell>
															{mapRowToColumns(
																leadershipGroup.leadership,
																orderedColumns,
																isObjective,
																setRefetchRows,
																classes,
																onEdit,
																filteredRows,
																groupByTeams
															)}
														</TableRow>

														{/* TeamLeads under this leadership */}
														{expandedGroups[createGroupKey(leadershipGroup.leadership.userId, itemGroup.itemId)] && 
															leadershipGroup.teamLeads.map((teamLeadGroup) => (
																<React.Fragment key={`teamlead-${teamLeadGroup.teamLead.userId}-${itemGroup.itemId}`}>
																	{/* TeamLead row */}
																	<TableRow 
																		className={classes.teamLeadRow}
																	>
																		<TableCell style={{ width: '40px', textAlign: 'center' }}>
																			<div 
																				onClick={() => toggleGroupExpanded(teamLeadGroup.teamLead.userId, itemGroup.itemId)}
																				style={{ cursor: 'pointer' }}
																			>
																				{expandedGroups[createGroupKey(teamLeadGroup.teamLead.userId, itemGroup.itemId)] 
																					? <KeyboardArrowDownIcon /> 
																					: <KeyboardArrowRightIcon />}
																			</div>
																		</TableCell>
																		{mapRowToColumns(
																			teamLeadGroup.teamLead,
																			orderedColumns,
																			isObjective,
																			setRefetchRows,
																			classes,
																			onEdit,
																			filteredRows,
																			groupByTeams
																		)}
																	</TableRow>
																	
																	{/* SalesReps under this TeamLead (only if expanded) */}
																	{expandedGroups[createGroupKey(teamLeadGroup.teamLead.userId, itemGroup.itemId)] && 
																		teamLeadGroup.salesReps.map((salesRepData, index) => (
																			<TableRow 
																				key={`salesrep-${salesRepData.salesRep.userId}-${index}`}
																				className={classes.clickableHoveredRow}
																			>
																				<TableCell padding="none" style={{ width: '40px', paddingLeft: '40px' }}></TableCell>
																				{mapRowToColumns(
																					salesRepData.salesRep,
																					orderedColumns,
																					isObjective,
																					setRefetchRows,
																					classes,
																					onEdit,
																					filteredRows,
																					groupByTeams,
																				)}
																			</TableRow>
																		))
																	}
																</React.Fragment>
															))}
													</React.Fragment>
												))}

												{/* Remaining TeamLead groups for this item */}
												{itemGroup.teamLeadGroups.map((teamLeadGroup) => (
													<React.Fragment key={`standalone-teamlead-${teamLeadGroup.teamLead.userId}-${itemGroup.itemId}`}>
														<TableRow 
															className={classes.teamLeadRow}
														>
															<TableCell style={{ width: '40px', textAlign: 'center' }}>
																<div 
																	onClick={() => toggleGroupExpanded(teamLeadGroup.teamLead.userId, itemGroup.itemId)}
																	style={{ cursor: 'pointer' }}
																>
																	{expandedGroups[createGroupKey(teamLeadGroup.teamLead.userId, itemGroup.itemId)] 
																		? <KeyboardArrowDownIcon /> 
																		: <KeyboardArrowRightIcon />}
																</div>
															</TableCell>
															{mapRowToColumns(
																teamLeadGroup.teamLead,
																orderedColumns,
																isObjective,
																setRefetchRows,
																classes,
																onEdit,
																filteredRows,
																groupByTeams
															)}
														</TableRow>
														
														{/* SalesReps under this TeamLead */}
														{expandedGroups[createGroupKey(teamLeadGroup.teamLead.userId, itemGroup.itemId)] && 
															teamLeadGroup.salesReps.map((salesRepData, index) => (
																<TableRow 
																	key={`standalone-salesrep-${salesRepData.salesRep.userId}-${index}`}
																	className={[classes.clickableHoveredRow, classes.salesRepRow].join(" ")}
																>
																	<TableCell padding="none" style={{ width: '40px' }}></TableCell>
																	{mapRowToColumns(
																		salesRepData.salesRep,
																		orderedColumns,
																		isObjective,
																		setRefetchRows,
																		classes,
																		onEdit,
																		filteredRows,
																		groupByTeams,
																		true
																	)}
																</TableRow>
															))
														}
													</React.Fragment>
												))}

												{/* Ungrouped SalesReps for this item */}
												{itemGroup.ungroupedSalesReps.map((salesRepData, index) => (
													<TableRow 
														key={`ungrouped-salesrep-${salesRepData.salesRep.userId}-${index}`}
														className={classes.clickableHoveredRow}
													>
														<TableCell padding="none" style={{ width: '40px' }}></TableCell>
														{mapRowToColumns(
															salesRepData.salesRep,
															orderedColumns,
															isObjective,
															setRefetchRows,
															classes,
															onEdit,
															filteredRows,
															groupByTeams
														)}
													</TableRow>
												))}
											</React.Fragment>
										))}
										
										{/* Total row */}
										{filteredRows.length > 0 && (
											<TableRow className={classes.totalRow}>
												<TableCell padding="none" style={{ width: '40px' }}></TableCell>
												<StyledTableCell>Total</StyledTableCell>
												{orderedColumns.slice(1).map((column, index) => (
													<StyledTableCell key={index}>
														{column === "Quantity" && totals.quantity.toFixed(2)}
														{column === "Eligible Quantity" &&
															totals.eligibleQuantity.toFixed(2)}
														{column === "Target" &&
															(isNaN(totals.target)
																? "∞"
																: totals.target.toFixed(2))}
														{column === "Payout MTD" &&
															(isNaN(totals.payoutMTD)
																? "∞"
																: `$ ${totals.payoutMTD.toFixed(2)}`)}
														{column === "Potential" &&
															(isNaN(totals.payout)
																? "∞"
																: `$ ${totals.payout.toFixed(2)}`)}
														{column === "Supplier Contribution" &&
															itemType === "incentive" &&
															(isNaN(totals.supplierContribution)
																? "0"
																: `$ ${totals.supplierContribution.toFixed(2)}`)}
													</StyledTableCell>
												))}
											</TableRow>
										)}
									</TableBody>
									) : (
											<TableBody>
												{filteredRows.map((row, index) => (
													<TableRow key={index} className={classes.clickableHoveredRow}>
														{mapRowToColumns(
															row,
															orderedColumns,
															isObjective,
															setRefetchRows,
															classes,
															onEdit,
															filteredRows,
															groupByTeams
														)}
													</TableRow>
												))}
												{filteredRows.length > 0 && (
													<TableRow className={classes.totalRow}>
														<StyledTableCell>Total</StyledTableCell>
														{orderedColumns.slice(1).map((column, index) => (
															<StyledTableCell key={index}>
																{column === "Quantity" && totals.quantity.toFixed(2)}
																{column === "Eligible Quantity" &&
																	totals.eligibleQuantity.toFixed(2)}
																{column === "Target" &&
																	(isNaN(totals.target)
																		? "∞"
																		: totals.target.toFixed(2))}
																{column === "Payout MTD" &&
																	(isNaN(totals.payoutMTD)
																		? "∞"
																		: `$ ${totals.payoutMTD.toFixed(2)}`)}
																{column === "Potential" &&
																	(isNaN(totals.payout)
																		? "∞"
																		: `$ ${totals.payout.toFixed(2)}`)}
																{column === "Supplier Contribution" &&
																	itemType === "incentive" &&
																	(isNaN(totals.supplierContribution)
																		? "0"
																		: `$ ${totals.supplierContribution.toFixed(2)}`)}
															</StyledTableCell>
														))}
													</TableRow>
												)}
											</TableBody>
									)}
								</Table>
								{filteredRows.length === 0 && (
									<Box sx={{ p: 4, textAlign: "center" }}>
										<Typography variant="subtitle1">No data</Typography>
									</Box>
								)}
							</StyledTableContainer>
						</ScrollContainer>
					</Paper>
				) : (
					<Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
						<CircularProgress />
					</Box>
				)}
			</Stack>
		</Box>
	);
}
