import React from "react";
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
} from "@material-ui/core";
import { Item } from "../types/commonObjIncTypes";
interface DuplicateModalProps {
	onClose: () => void;
	onConfirm: () => void;
	item: Item | null;
	open: boolean;
	copyImages?: boolean;
	setCopyImages: (copyImages: boolean) => void;
}

const DuplicateModal: React.FC<DuplicateModalProps> = ({
	open,
	item,
	onClose,
	onConfirm,
	copyImages,
	setCopyImages,
}) => {
	return (
		<Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
			<DialogContent>
				<DialogTitle>Confirm Duplicate</DialogTitle>
				<DialogContent>
					<DialogContentText>Do you want to copy images for {item?.name}?</DialogContentText>
					<FormControlLabel
						control={
							<Checkbox
								checked={copyImages}
								onChange={(e) => setCopyImages(e.target.checked)}
								color="primary"
							/>
						}
						label="Copy Images"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Cancel</Button>
					<Button onClick={onConfirm} color="primary">
						Duplicate
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};

export default DuplicateModal;
