import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles({
	container: {
		maxHeight: "100px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	list: {
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		maxHeight: "300px",
		overflowY: "auto",
	},
	title: {
		fontSize: '1.5rem',
		fontWeight: 600,
		marginBottom: '16px',
		textAlign: 'center',
	},
	info: {
		padding: '24px',
		display: 'flex',
		flexDirection: 'column',
		gap: '12px',
	},
	infoRow: {
		display: 'flex',
		gap: '8px',
		alignItems: 'baseline',
	},
	infoLabel: {
		fontWeight: 500,
		minWidth: '140px',
	},
	notesSection: {
		padding: '24px',
		display: 'flex',
		flexDirection: 'column',
		gap: '12px',
	},
	notesTitle: {
		fontSize: '1.1rem',
		fontWeight: 500,
		marginBottom: '8px',
	},
	notes: {
		padding: '12px',
		backgroundColor: '#f5f5f5',
		borderRadius: '4px',
		maxHeight: 'none',
		margin: 0,
		whiteSpace: 'pre-wrap',
		wordBreak: 'break-word',
	},
	notesInfo: {
		margin: "1rem 3.5rem 0.5rem 3.5rem"
	},
	dialogContent: {
		padding: '0 !important',
		display: "flex",
		height: '90vh',
	},
	content: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: '100%',
		height: '100%',
		position: 'relative',
	},
	navigationButton: {
		position: 'absolute',
		zIndex: 1,
		backgroundColor: 'rgba(255, 255, 255, 0.3)',
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.5)',
		},
		color: '#fff',
		'&.left': {
			left: 16,
		},
		'&.right': {
			right: 16,
		},
	},
	buttons: {
		display: "flex",
		justifyContent: "space-between",
		margin: "1rem 4.3rem",
	},
	rejectContainer: {
		padding: '24px',
	},
	image: {
		width: "80px",
    	height: "auto",
    	objectFit: "cover",
	},
	leftButtons: {
		display: "flex",
		gap: "8px",
		flexGrow: 1,
	},
	textArea: {
		width: '100%', 
		padding: '8px', 
		marginTop: '8px'
	},
	modalLayout: {
		display: 'flex',
		width: '100%',
		height: '100%',
		gap: 0,
	},
	imageSection: {
		flex: '1 1 50%',
		position: 'relative',
		backgroundColor: '#000',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	detailsSection: {
		flex: '1 1 50%',
		backgroundColor: '#fff',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	detailsContent: {
		flex: 1,
		overflowY: 'auto',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '16px 24px',
		borderTop: '1px solid rgba(0, 0, 0, 0.12)',
	},
	dialogImage: {
		width: '100%',
		height: '100%',
		objectFit: 'contain',
		backgroundColor: '#000',
	},
});