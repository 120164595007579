import React, { useState, useEffect } from "react";
import {
	Dialog,
	DialogContent,
	Button,
	List,
	ListItem,
	Avatar,
	Typography,
	IconButton,
	TextareaAutosize,
} from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { useStyles } from "./ImageListCell.styles";
import { ObjIncentiveImage } from "../../usersManagement/types/commonObjIncTypes";
import useImages from "../hooks/useImages";
import { formatDateAndTime } from "./utils";

interface ImageListCellProps {
	images: ObjIncentiveImage[];
	itemId: string;
	setRefetchRows: (refetchRows: boolean) => void;
	combinedImages?: any;
}

export const ImageListCell: React.FC<ImageListCellProps> = ({
	images: propImages,
	itemId,
	combinedImages,
	setRefetchRows
}) => {
	const [initialImages, setInitialImages] = useState<any>(combinedImages);
	const [images, setImages] = useState<ObjIncentiveImage[]>(propImages);
	const [selectedImageId, setSelectedImageId] = useState<string | null>(null);
	const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
	const [open, setOpen] = useState(false);
	const [showRejectReason, setShowRejectReason] = useState(false);
	const [rejectReason, setRejectReason] = useState("");

	const { rejectImage, unRejectImage, approveImage, unApproveImage } = useImages({
		itemId,
		isApproved: selectedImageIndex !== null ? initialImages[selectedImageIndex]?.approved : false
	});

	const classes = useStyles();

	useEffect(() => {
		setImages(propImages);
	}, [propImages]);

	const handleClickOpen = (imageId: string) => {
		if (imageId) {
			const index = initialImages.findIndex((image: ObjIncentiveImage) => image._id === imageId);
			setSelectedImageIndex(index !== -1 ? index : null);
			setSelectedImageId(imageId);
			setOpen(true);
		}
	};

	const handleClose = () => {
		setShowRejectReason(false);
		setOpen(false);
		setRefetchRows(true);
	};

	const handlePrevImage = () => {
		setShowRejectReason(false);
		if (selectedImageIndex !== null) {
			const newIndex = selectedImageIndex > 0 ? selectedImageIndex - 1 : initialImages.length - 1;
			setSelectedImageIndex(newIndex);
			setSelectedImageId(initialImages[newIndex]._id);
		}
	};

	const handleNextImage = () => {
		setShowRejectReason(false);
		if (selectedImageIndex !== null) {
			const newIndex = selectedImageIndex < initialImages.length - 1 ? selectedImageIndex + 1 : 0;
			setSelectedImageIndex(newIndex);
			setSelectedImageId(initialImages[newIndex]._id);
		}
	};

	const handleRejectClick = () => {
		setShowRejectReason(true);
	};

	const handleRejectSave = () => {
		if (selectedImageId) {
			const updatedImages = initialImages.map((image: ObjIncentiveImage) => {
				if (image._id === selectedImageId) {
					return { ...image, rejected: true, rejectedReason: rejectReason };
				}
				return image;
			});
			setInitialImages(updatedImages);
			rejectImage(selectedImageId, rejectReason);
		}
		setShowRejectReason(false);
		setRejectReason("");
	};

	const handleUnreject = () => {
		if (selectedImageId) {
			const updatedImages = initialImages.map((image: ObjIncentiveImage) => {
				if (image._id === selectedImageId) {
					return { ...image, rejected: false, rejectedReason: "" };
				}
				return image;
			});
			setInitialImages(updatedImages);
			unRejectImage(selectedImageId);
		}
	};

	const handleApproveClick = () => {
		if (selectedImageId) {
			const updatedImages = initialImages.map((image: ObjIncentiveImage) => {
				if (image._id === selectedImageId) {
					return { ...image, approved: true };
				}
				return image;
			});
			setInitialImages(updatedImages);
			approveImage(selectedImageId);
		}
	};

	const handleUnapprove = () => {
		if (selectedImageId) {
			const updatedImages = initialImages.map((image: ObjIncentiveImage) => {
				if (image._id === selectedImageId) {
					return { ...image, approved: false };
				}
				return image;
			});
			setInitialImages(updatedImages);
			unApproveImage(selectedImageId);
		}
	};

	return (
		<div className={classes.container}>
			<List className={classes.list}>
				{images.map((image, index) => (
					<ListItem
						key={index}
						button
						onClick={() => image._id && handleClickOpen(image._id)}
						style={{
							flexDirection: "column",
						}}
					>
						<Avatar
							className={classes.image}
							variant="square"
							alt={image?.title || ""}
							src={image.imageUrl}
						/>
						<Typography variant="caption">{image?.buyerId || ""}</Typography>
					</ListItem>
				))}
			</List>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
				<DialogContent className={classes.dialogContent}>
					{selectedImageIndex !== null && (
						<div className={classes.modalLayout}>
							<div className={classes.imageSection}>
								<div className={classes.content}>
									<IconButton 
										onClick={handlePrevImage} 
										className={`${classes.navigationButton} left`}
									>
										<ArrowBack />
									</IconButton>
									<img
										src={initialImages?.[selectedImageIndex]?.imageUrl || ""}
										alt={initialImages?.[selectedImageIndex]?.title || ""}
										className={classes.dialogImage}
									/>
									<IconButton 
										onClick={handleNextImage}
										className={`${classes.navigationButton} right`}
									>
										<ArrowForward />
									</IconButton>
								</div>
							</div>
							<div className={classes.detailsSection}>
								<div className={classes.detailsContent}>
									<div className={classes.info}>
										<Typography className={classes.title}>
											{initialImages[selectedImageIndex]?.title || ""}
										</Typography>

										<div className={classes.infoRow}>
											<Typography className={classes.infoLabel}>Item Name:</Typography>
											<Typography>
												{initialImages[selectedImageIndex]?.itemName || "N/A"}
											</Typography>
										</div>
										{initialImages[selectedImageIndex]?.parentItemName && initialImages[selectedImageIndex]?.parentItemName !== "-" && (
											<div className={classes.infoRow}>
												<Typography className={classes.infoLabel}>Parent Name:</Typography>
												<Typography>
													{initialImages[selectedImageIndex]?.parentItemName || "N/A"}
												</Typography>
											</div>
										)}

										<div className={classes.infoRow}>
											<Typography className={classes.infoLabel}>User Name:</Typography>
											<Typography>
												{initialImages[selectedImageIndex]?.userName || "N/A"}
											</Typography>
										</div>

										<div className={classes.infoRow}>
											<Typography className={classes.infoLabel}>Route:</Typography>
											<Typography>
												{Array.isArray(initialImages[selectedImageIndex]?.route) 
													? initialImages[selectedImageIndex]?.route.join(", ") 
													: initialImages[selectedImageIndex]?.route || "N/A"}
											</Typography>
										</div>
										
										<div className={classes.infoRow}>
											<Typography className={classes.infoLabel}>Date:</Typography>
											<Typography>
												{(initialImages[selectedImageIndex]?.date &&
													formatDateAndTime(initialImages[selectedImageIndex]?.date)) ||
													"N/A"}
											</Typography>
										</div>

										<div className={classes.infoRow}>
											<Typography className={classes.infoLabel}>Account Name:</Typography>
											<Typography>
												{initialImages[selectedImageIndex]?.account || "N/A"}
											</Typography>
										</div>

										<div className={classes.infoRow}>
											<Typography className={classes.infoLabel}>Buyer ID:</Typography>
											<Typography>
												{initialImages[selectedImageIndex]?.buyerId || "N/A"}
											</Typography>
										</div>

										{initialImages[selectedImageIndex]?.validationQuantity && (
											<div className={classes.infoRow}>
												<Typography className={classes.infoLabel}>Validation Quantity:</Typography>
												<Typography>
													{initialImages[selectedImageIndex]?.validationQuantity || "0"}
												</Typography>
											</div>
										)}
									</div>

									<div className={classes.notesSection}>
										{!showRejectReason && (
											<Typography className={classes.notesTitle}>
												{initialImages[selectedImageIndex]?.rejected ? "Rejection reason:" : "Sales Rep notes:"}
											</Typography>
										)}
										<div className={classes.notes}>
											<Typography>
												{showRejectReason || initialImages[selectedImageIndex]?.rejected
													? initialImages[selectedImageIndex]?.rejectedReason || "-"
													: initialImages[selectedImageIndex]?.notes || "-"}
											</Typography>
										</div>
									</div>

									{showRejectReason && !initialImages[selectedImageIndex]?.rejected && (
										<div className={classes.rejectContainer}>
											<Typography variant="body1" className={classes.notesTitle}>
												Reason (required):
											</Typography>
											<TextareaAutosize
												value={rejectReason}
												onChange={(e) => setRejectReason(e.target.value)}
												placeholder="Add rejection reason..."
												minRows={4}
												className={classes.textArea}
											/>
										</div>
									)}
								</div>
								
								<div className={classes.actionsContainer}>
									<div className={classes.leftButtons}>
										{!showRejectReason && !initialImages[selectedImageIndex]?.rejected && (
											<Button
												onClick={handleRejectClick}
												variant="contained"
												color="primary"
											>
												Reject
											</Button>
										)}
										{!initialImages[selectedImageIndex]?.approved && !showRejectReason && !initialImages[selectedImageIndex]?.rejected && (
											<Button
												onClick={handleApproveClick}
												variant="contained"
												color="primary"
											>
												Approve
											</Button>
										)}
										{showRejectReason && (
											<>
												<Button
													onClick={() => setShowRejectReason(false)}
													color="primary"
												>
													Cancel
												</Button>
												<Button
													onClick={handleRejectSave}
													color="primary"
													disabled={!rejectReason.trim()}
												>
													Save
												</Button>
											</>
										)}
										{initialImages[selectedImageIndex]?.rejected && (
											<Button
												onClick={handleUnreject}
												variant="contained"
												color="primary"
											>
												Restore Image
											</Button>
										)}
										{initialImages[selectedImageIndex]?.approved && !showRejectReason && !initialImages[selectedImageIndex]?.rejected && (
											<Button
												onClick={handleUnapprove}
												variant="contained"
												color="primary"
											>
												Unapprove Image
											</Button>
										)}
									</div>
									<Button onClick={handleClose} color="primary">
										Close
									</Button>
								</div>
							</div>
						</div>
					)}
				</DialogContent>
			</Dialog>
		</div>
	);
};
