import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getFiles, filesAdded, currentMonth } from "./filesSlice";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FilesRow, { smallCellSize } from "./FilesRow";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Home from "@material-ui/icons/Home";
import FilesSkeleton from "./FilesSkeleton";
import { useLocation } from "react-router-dom";
import MonthSelect from "./MonthSelect";
import FilesEmpty from "./FilesEmpty";
import path from "parse-filepath";
import FilesFolderRow from "./FilesFolderRow";
import useQuery, { IFilesQuery } from "../../hooks/useQuery";
import uniq from "lodash/uniq";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((_) => ({
  table: {
    minWidth: 320,
  },
  container: {
    width: "100%",
    maxWidth: 1800,
    margin: "0 auto",
  },
  buttonCell: {
    width: smallCellSize,
    textAlign: "center",
    display: "flex",
  },
  nameCell: {
    width: "100%",
  },
}));

export default function FilesView() {
  const location = useLocation();
  const isArchive = location.pathname === "/archive";
  const { query, pushQuery } = useQuery<IFilesQuery>();
  const queryPath = query.path || "";
  const classes = useStyles();
  const dispatch = useDispatch();
  const isReady = useSelector(
    (state: RootState) => state.files.status === "ready"
  );
  const selectedMonth = useSelector((state: RootState) =>
    state.files.selectedMonth === "All"
      ? currentMonth
      : state.files.selectedMonth
  );
  const [fileNames, folderNames] = useSelector((state: RootState) => {
    let allFileNames: string[] = [];
    if (isArchive) {
      if (state.files.selectedMonth === "All") {
        allFileNames = Object.keys(state.files.byId)
          .filter((fileName) => fileName.includes("archive/"))
          .reverse();
      } else {
        allFileNames =
          state.files.namesByMonth[state.files.selectedMonth]
            ?.slice()
            .reverse() || [];
      }
      return [allFileNames, []];
    } else {
      allFileNames = Object.keys(state.files.byId)
        .filter((fileName) => !fileName.includes("archive/"))
        .reverse();
    }
    const files = allFileNames.map((fileName) => path(fileName));
    let folderNames: string[] = [];
    let thisFolderFiles: string[] = [];
    
    files.forEach((file) => {
      const relativePath = file.dir.startsWith('/') ? file.dir.slice(1) : file.dir;
      if (relativePath === queryPath) {
        thisFolderFiles.push(file.path);
      } else if (relativePath.startsWith(queryPath)) {
        const remainingPath = relativePath.slice(queryPath ? queryPath.length + 1 : 0);
        const firstFolder = remainingPath.split('/')[0];
        if (firstFolder) {
          folderNames.push(firstFolder);
        }
      }
    });
    return [thisFolderFiles, uniq(folderNames)];
  });

  React.useEffect(() => {
    dispatch(getFiles(query.wholesalerId));
  }, [dispatch, query.wholesalerId]);
  return (
    <div className={classes.container}>
      {isArchive && <MonthSelect />}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.buttonCell}>
                <IconButton
                  size="small"
                  onClick={() => pushQuery({ path: undefined })}
                >
                  <Tooltip title="Home">
                    <Home />
                  </Tooltip>
                </IconButton>
                <IconButton
                  size="small"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    const pathParts = queryPath.split('/');
                    pathParts.pop();
                    const newPath = pathParts.join('/') || undefined;
                    pushQuery({ path: newPath });
                  }}
                >
                  <Tooltip title="Back">
                    <ArrowBack />
                  </Tooltip>
                </IconButton>
              </TableCell>
              <TableCell className={classes.nameCell}>File name</TableCell>
              {!isArchive && <TableCell>Access</TableCell>}
              <TableCell width={smallCellSize} align="right">
                <IconButton
                  size="small"
                  onClick={() => {
                    const fileName = prompt("Enter file name");
                    dispatch(
                      filesAdded([
                        {
                          name: isArchive
                            ? `archive/${fileName}.pdf`
                            : (queryPath ? `${queryPath}/` : "") +
                              `${fileName}.pdf`,
                          access: ["manager", "teamLead", "salesRep"],
                          month: selectedMonth,
                          size: 0,
                          date: String(new Date()),
                          isNew: true,
                        },
                      ])
                    );
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {folderNames.map((folderName) => (
              <FilesFolderRow
                key={folderName}
                folderName={folderName}
                onClick={() =>
                  pushQuery({
                    path: queryPath ? queryPath + "/" + folderName : folderName,
                  })
                }
              />
            ))}
            {fileNames.map((fileName) => (
              <FilesRow
                isArchive={isArchive}
                key={fileName}
                fileName={fileName}
              />
            ))}
            {!fileNames.length && !isReady && (
              <FilesSkeleton isArchive={isArchive} />
            )}
          </TableBody>
        </Table>
        {!fileNames.length && !folderNames.length && isReady && <FilesEmpty />}
      </TableContainer>
    </div>
  );
}
