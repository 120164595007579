import React, { useEffect, useState } from 'react';
import {
	Divider,
	FormControl,
	FormControlLabel,
	FormLabel,
	InputLabel,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Typography,
} from '@material-ui/core';
import { MeasureComponent } from './MeasureComponent';
import { MeasureState } from '../../types/objectiveTypes';
import { MeasureSymbol } from '../../types/commonObjIncTypes';
import { useStyles } from './QuantityForm.styles';
import { DateRange } from 'react-date-range';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';

interface QuantityFormProps {
	measures: MeasureState[];
	handleMeasureChange: (
		label?: string,
		startDate?: string,
		endDate?: string,
		checked?: boolean,
		symbol?: MeasureSymbol,
		trackingOption?: string,
		trackingPeriodStart?: string,
		trackingPeriodEnd?: string,
		referenceTrackingPeriod?: string,
	) => void;
	customPeriodStart?: string;
	customPeriodEnd?: string;
}

export const QuantityForm: React.FC<QuantityFormProps> = ({
	measures,
	handleMeasureChange,
	customPeriodStart,
	customPeriodEnd,
}) => {
	const classes = useStyles();

	const isTeamLead = useSelector(
		(state: RootState) => state.session.role === "teamLead"
	);

	const [trackingPeriodText, setTrackingPeriodText] = useState<string>('');

	const [currentMeasure, setCurrentMeasure] = useState<MeasureState | null>(
		measures.find((measure) => measure.checked) || null
	);
	const [periodDates, setPeriodDates] = useState<any[]>([
		{
			startDate: currentMeasure?.startDate
				? moment(currentMeasure.startDate).tz("America/Chicago").toDate()
				: new Date(),
			endDate: currentMeasure?.endDate
				? moment(currentMeasure.endDate).tz("America/Chicago").toDate()
				: new Date(),
			key: 'selection',
		},
	]);
	const [trackingPeriodDates, setTrackingPeriodDates] = useState<any[]>([
		{
			startDate: currentMeasure?.trackingPeriodStart
				? moment(currentMeasure.trackingPeriodStart).tz("America/Chicago").toDate()
				: new Date(),
			endDate: currentMeasure?.trackingPeriodEnd
				? moment(currentMeasure.trackingPeriodEnd).tz("America/Chicago").toDate()
				: new Date(),
			key: 'selection',
		},
	]);
	const [selectedTrackingPeriod, setselectedTrackingPeriod] = useState<number>(
		currentMeasure?.trackingPeriod || 30
	);
	const [trackingOption, setTrackingOption] = useState<string>(
		currentMeasure?.trackingOption || 'default'
	);
	const [refTrackingPeriod, setRefTrackingPeriod] = useState<string>(
		currentMeasure?.referenceTrackingPeriod
			? moment(currentMeasure.referenceTrackingPeriod).tz("America/Chicago").format('YYYY-MM-DDTHH:mm:ss')
			: moment().format('YYYY-MM-DDTHH:mm:ss')
	);

	useEffect(() => {
		let startDate: Date | null = null;
		let endDate: Date | null = null;

		if (trackingOption === "customPeriod") {
			startDate = trackingPeriodDates[0]?.startDate;
			endDate = trackingPeriodDates[0]?.endDate;
		} else if (trackingOption === "trackingFromDate" && refTrackingPeriod) {
			startDate = moment(refTrackingPeriod).tz("America/Chicago").toDate();
			endDate = moment(refTrackingPeriod).tz("America/Chicago").toDate();
			endDate.setDate(startDate.getDate() + selectedTrackingPeriod - 1);
		}

		if (startDate && endDate) {
			const startFormatted = moment(startDate).format("MMMM D, YYYY");
			const endFormatted = moment(endDate).format("MMMM D, YYYY");
			setTrackingPeriodText(`${startFormatted} - ${endFormatted}`);
		}
	}, [
		selectedTrackingPeriod,
		trackingOption,
		trackingPeriodDates,
		refTrackingPeriod,
	]);

	const updateCurrentMeasure = (updatedFields: Partial<MeasureState>) => {
		if (currentMeasure) {
			const updatedMeasure: MeasureState = {
				...currentMeasure,
				...updatedFields,
			};

			setCurrentMeasure(updatedMeasure);

			handleMeasureChange(
				updatedMeasure.label,
				updatedMeasure.startDate,
				updatedMeasure.endDate,
				updatedMeasure.checked,
				updatedMeasure.symbol,
				updatedMeasure.trackingOption,
				updatedMeasure.trackingPeriodStart,
				updatedMeasure.trackingPeriodEnd,
				updatedMeasure.referenceTrackingPeriod
			);
		}
	};

	const handlePeriodChange = (item: any) => {
		const { startDate, endDate } = item.selection;

		setPeriodDates([item.selection]);

		updateCurrentMeasure({
			startDate: startDate,
			endDate: endDate,
			trackingPeriod: selectedTrackingPeriod,
		});
	};

	const handleTrackingPeriodChange = (
		event: React.ChangeEvent<{ value: unknown }>
	) => {
		const newTrackingPeriod = event.target.value as number;
		setselectedTrackingPeriod(newTrackingPeriod);

		updateCurrentMeasure({
			trackingPeriod: newTrackingPeriod,
		});
	};

	const handleTrackingPeriodCalendarChange = (item: any) => {
		const { startDate, endDate } = item.selection;

		setTrackingPeriodDates([item.selection]);

		updateCurrentMeasure({
			trackingPeriodStart: startDate,
			trackingPeriodEnd: endDate,
		});
	};

	const handleMeasureComponentChange = (
		label?: string,
		checked?: boolean,
		symbol?: MeasureSymbol
	) => {
		const measure = measures.find((m) => m.label === label);
		if (measure) {
			measure.checked = checked;
			measure.symbol = symbol;
			updateCurrentMeasure({
				label,
				checked,
				symbol,
			});
		}
	};

	const formatForDisplay = (date: string) => {
		return moment(date).format('YYYY-MM-DD');
	};

	const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newTrackingOption = event.target.value;
		setTrackingOption(newTrackingOption);

		updateCurrentMeasure({
			trackingOption: newTrackingOption,
		});
	};

	const handleRefTrackingPeriodChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const newDate = event.target.value;
		setRefTrackingPeriod(newDate);

		updateCurrentMeasure({
			referenceTrackingPeriod: newDate,
		});
	};

	return (
		<>
			<FormLabel className={classes.formLabel} required>
				Measure:{' '}
			</FormLabel>
			<div className={classes.mainContainer}>
				<div className={classes.container}>
					<FormControl component="fieldset" className={classes.formControl}>
						<Paper className={classes.measureContainer}>
							{measures.map((measure) => (
								<MeasureComponent
									key={measure.label}
									label={measure.label}
									checked={measure.checked}
									symbol={measure.symbol}
									onMeasureChange={handleMeasureComponentChange}
								/>
							))}
						</Paper>
					</FormControl>
					{currentMeasure?.label?.includes('Δ') && (
						<div className={classes.period}>
							<div>
								<FormLabel component="legend">Reference Period</FormLabel>
								<div style={{ position: 'relative' }}>
									<DateRange
										editableDateInputs={true}
										onChange={(item) => {
											handlePeriodChange(item);
										}}
										moveRangeOnFirstSelection={false}
										ranges={periodDates}
									/>
									{isTeamLead && (
										<div
											style={{
												position: 'absolute',
												top: 0,
												left: 0,
												right: 0,
												bottom: 0,
												backgroundColor: 'rgba(255, 255, 255, 0.5)',
												cursor: 'not-allowed',
											}}
										/>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
				{currentMeasure?.label?.includes('Δ') && (
					<div className={classes.options}>
						<FormControl component="fieldset" className={classes.radioGroup}>
							<RadioGroup value={trackingOption} onChange={handleOptionChange}>
								<FormControlLabel
									value="default"
									control={<Radio />}
									label="Default"
									disabled={isTeamLead}
								/>
								<FormControlLabel
									value="customPeriod"
									control={<Radio />}
									label="Custom Period"
									disabled={isTeamLead}
								/>
								<FormControlLabel
									value="trackingFromDate"
									control={<Radio />}
									label="Tracking from Date"
									disabled={isTeamLead}
								/>
							</RadioGroup>
						</FormControl>
						<div>
							<div className={classes.trackingText}>
								{trackingOption !== "default" && (
									<>
										<Typography>{trackingPeriodText}</Typography>
										<Divider />
									</>
								)}
							</div>
							{trackingOption === "default" && (
								<div className={classes.option}>
										<Typography variant="subtitle1">
											{currentMeasure?.startDate
												? moment(
														currentMeasure.startDate
												  ).format("MMMM D, YYYY")
												: "N/A"}{" "}
											-{" "}
											{currentMeasure?.endDate
												? moment(
														currentMeasure.endDate
												  ).format("MMMM D, YYYY")
												: "N/A"}
										</Typography>
										<Typography variant="subtitle1">
											{customPeriodStart
												? moment(
														customPeriodStart
												  ).format("MMMM D, YYYY")
												: "N/A"}{" "}
											-{" "}
											{customPeriodEnd
												? moment(
														customPeriodEnd
												  ).format("MMMM D, YYYY")
												: "N/A"}
										</Typography>
								</div>
							)}
							{trackingOption === 'customPeriod' && (
								<div className={classes.option}>
									<div className={classes.period}>
										<div>
											<FormLabel component="legend">Tracking Period</FormLabel>
											<div style={{ position: 'relative' }}>
												<DateRange
													editableDateInputs={true}
													onChange={(item) => {
														handleTrackingPeriodCalendarChange(item);
													}}
													moveRangeOnFirstSelection={false}
													ranges={trackingPeriodDates}
												/>
												{isTeamLead && (
													<div
														style={{
															position: 'absolute',
															top: 0,
															left: 0,
															right: 0,
															bottom: 0,
															backgroundColor: 'rgba(255, 255, 255, 0.5)',
															cursor: 'not-allowed',
														}}
													/>
												)}
											</div>
										</div>
									</div>
								</div>
							)}
							{trackingOption === 'trackingFromDate' && (
								<div className={classes.option}>
									<div>
										<FormControl className={classes.formControl}>
											<TextField
												id="date"
												label="Select date"
												type="date"
												value={formatForDisplay(refTrackingPeriod)}
												onChange={handleRefTrackingPeriodChange}
												InputLabelProps={{
													shrink: true,
												}}
												disabled={isTeamLead}
											/>
										</FormControl>
									</div>
									<div>
										<FormControl className={classes.formControl}>
											<InputLabel>Select Tracking Period</InputLabel>
											<Select
												className={classes.excludeSelect}
												value={selectedTrackingPeriod}
												onChange={handleTrackingPeriodChange}
												disabled={isTeamLead}
											>
												<MenuItem value={30}>30 days</MenuItem>
												<MenuItem value={60}>60 days</MenuItem>
												<MenuItem value={90}>90 days</MenuItem>
											</Select>
										</FormControl>
									</div>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
};
