import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import makeAvatarUrl from "../../utils/makeAvatarUrl";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import SpacingBoxView from "../spacingBox/SpacingBoxView";
import isEmail from "../../utils/isEmail";
import Divider from "@material-ui/core/Divider";
import { Box } from "@material-ui/core";
import useQuery, { IGeneralQuery } from "../../hooks/useQuery";
import { patchProfile, postUserAvatar, clearLocalAvatarUrl, isLocalAvatarStale } from "../session/sessionSlice";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 375,
    width: 375,
    minWidth: 375,
    display: "flex",
    flexDirection: "column",
  },
  blockWrapper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  avatarWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: {
    width: 200,
    height: 200,
    alignSelf: "center",
    fontSize: 100,
  },
  card: {},
  cameraIcon: {
    position: "absolute",
    bottom: 10,
    right: 50,
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: "50%",
    padding: 5,
    pointerEvents: "auto",
    cursor: "pointer",
    transition: "opacity 0.3s",
    height: 40,
    width: 40,
    "&:hover": {
      opacity: 0.8,
    },
  },
}));

export default function ProfileView() {
  const classes = useStyles();
  const session = useSelector((state: RootState) => state.session);
  const isStale = useSelector(isLocalAvatarStale);
  const { register, errors, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { query } = useQuery<IGeneralQuery>();
  
  useEffect(() => {
    if (session.customId && isStale && session.localAvatarUrl) {
      dispatch(clearLocalAvatarUrl());
    }
  }, [dispatch, query, session.customId, isStale, session.localAvatarUrl]);
  
  if (!session.customId) {
    return <></>;
  }

  const avatarUrl = session.localAvatarUrl || session.avatarUrl || makeAvatarUrl(
    session.wholesalerId,
    session.customId,
    session.avatarHash
  );

  return (
    <Paper className={classes.container}>
      <Box className={classes.blockWrapper}>
        <div className={classes.avatarWrapper}>
          <Avatar
            className={classes.avatar}
            sizes="300px"
            key={session.avatarUrl || session.localAvatarUrl || session.avatarHash || "avatar"}
            src={avatarUrl}
          >
            {session.name
              ?.match(/\b(\w)/g)
              ?.join("")
              .substring(0, 2)
              .toUpperCase()}
          </Avatar>
          <CameraAltIcon
            className={classes.cameraIcon}
            onClick={() =>
              dispatch(
                postUserAvatar({
                  wholesalerId: query.wholesalerId,
                  db: query.db,
                })
              )
            }
          />
        </div>
      </Box>
      <Divider />
      <Box className={classes.blockWrapper}>
        <TextField
          size="small"
          defaultValue={session.name}
          error={errors.name}
          name="name"
          inputRef={register({
            validate: (value: string) =>
              /^[a-zA-Z0-9 ']{1,30}$/.test(value) ||
              "Should be 1 to 30 letters and spaces",
          })}
          label="Name"
          helperText={errors.name ? errors.name.message : ""}
          fullWidth
          variant="outlined"
        />
        <SpacingBoxView />
        <TextField
          size="small"
          defaultValue={session.email}
          error={errors.email}
          name="email"
          inputRef={register({
            validate: (val: string) =>
              isEmail(val) || "It doesn't look like an email",
          })}
          label="Email"
          helperText={errors.email ? errors.email.message : ""}
          fullWidth
          variant="outlined"
        />
      </Box>
      <Divider />
      <Box className={classes.blockWrapper}>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleSubmit((vals) => {
            dispatch(
              patchProfile(
                {
                  wholesalerId: query.wholesalerId,
                  db: query.db,
                },
                { email: vals.email, name: vals.name }
              )
            );
          })}
        >
          Save
        </Button>
      </Box>
    </Paper>
  );
}
