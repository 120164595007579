import React, { useEffect, useState } from "react";
import { Item } from "../../types/commonObjIncTypes";
import ItemComponent from "../../common/ItemComponent";
import { Incentive } from "../../types/incentivesTypes";
import moment from "moment";
import useHandleItemsUpdate from "../../hooks/useHandleItemsUpdate";
import useItems from "../../hooks/useItems";
import useItem from "../../hooks/useItem";

export const IncentivesComponent: React.FC = () => {
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [selectedTag, setSelectedTag] = useState<string>("");
	const [periodStart, setPeriodStart] = useState<string | null>(null);
	const [periodEnd, setPeriodEnd] = useState<string | null>(null);
	const [selectedType, setSelectedType] = useState<string | undefined>("");
	const [currentPage, setCurrentPage] = useState(1);

	const [selectedIncentiveId, setSelectedIncentiveId] = useState<string>("");
	const [pendingAction, setPendingAction] = useState<{
		id: string;
		type: "archive" | "favorite";
	} | null>(null);

	const { item, itemLoading } = useItem({
		itemId: selectedIncentiveId,
		isObjective: false,
		isArchived: false,
	});

	const { items, totalPages, loading } = useItems({
		searchPhrase: searchTerm !== "" ? searchTerm : undefined,
		type: selectedType,
		tag: selectedTag || undefined,
		periodStart,
		periodEnd,
		itemsType: "incentives",
		isObjective: false,
		isArchived: false,
		isFavorite: false,
		currentPage,
		setCurrentPage,	
	});

	const { addItem, editItem, deleteItem, runQuery, resetValues } =
		useHandleItemsUpdate({ itemType: "incentive", isObjective: false });

	useEffect(() => {
		if (
			pendingAction &&
			!itemLoading &&
			item &&
			item._id === pendingAction.id
		) {
			const updatedItem = {
				...item,
				archived:
					pendingAction.type === "archive" ? !item.archived : item.archived,
				favorite:
					pendingAction.type === "favorite" ? !item.favorite : item.favorite,
			};

			editItem(
				pendingAction.id,
				updatedItem,
				updatedItem.isGrouped,
				pendingAction.type === "archive" ? true : false
			);
			setPendingAction(null);
		}
	}, [pendingAction, itemLoading, item, editItem]);

	const handleAddIncentive = async (
		newIncentiveData: Omit<Item, "_id">,
		parentId?: string,
		copyImages?: boolean
	) => {
		return await addItem(newIncentiveData as Omit<Incentive, "_id">, parentId, copyImages);
	};

	const handleEditIncentive = (id: string, updatedIncentive: Partial<Item>) => {
		editItem(id, updatedIncentive as Partial<Incentive>);
	};

	const handleDeleteIncentive = (id: string) => {
		deleteItem(id);
	};

	const handleArchiveIncentive = (id: string) => {
		if (id) {
			setSelectedIncentiveId(id);
			setPendingAction({ id, type: "archive" });
		}
	};

	const handleFavoriteIncentive = (id: string) => {
		if (id) {
			setSelectedIncentiveId(id);
			setPendingAction({ id, type: "favorite" });
		}
	};

	const handleDuplicateIncentive = (id: string, copyImages?: boolean) => {
		const incentive = items.find((incentive) => incentive._id === id);

		if (incentive) {
			const newName = `${incentive.name} copy`;
			const {
				_id: id,
				customId,
				users: originalUsers,
				...restOfObject
			} = incentive;

			const initializedUsers = (originalUsers || []).map((user) => ({
				...user,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				progress: 0,
				totalPods: 0,
				payout: 0,
				totalAchieved: 0,
				percentageAchieved: 0,
				totalItems: 0,
				totalPayout: 0,
				checks: [],
				isEdited: false,
			}));

			handleAddIncentive(
				{
					...restOfObject,
					name: newName,
					favorite: false,
					users: initializedUsers,
				},
				id,
				copyImages
			);
		}
	};

	const handleAction = (id: string) => {
		const incentive = items.find((incentive) => incentive._id === id);
		if (incentive) {
			runQuery(incentive._id);
		}
	};

	const handleReset = (id: string) => {
		const incentive = items.find((incentive) => incentive._id === id);
		if (incentive) {
			resetValues(incentive._id);
		}
	};
	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
		setCurrentPage(1);
	};

	const handleTypeChange = (selectedOption: any) => {
		if (selectedOption.value === selectedType) {
			setSelectedType("");
		} else {
			setSelectedType(selectedOption.value);
		}
		setCurrentPage(1);
	};

	const handleTagChange = (selectedOption: any) => {
		if (selectedOption.value === selectedTag) {
			setSelectedTag("");
		} else {
			setSelectedTag(selectedOption.value);
		}
		setCurrentPage(1);
	};

	const handlePeriodStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value) {
			const formattedDate = moment(value).format("YYYY-MM-DD");
			setPeriodStart(formattedDate);
		} else {
			setPeriodStart(null);
		}
		setCurrentPage(1);
	};

	const handlePeriodEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value) {
			const formattedDate = moment(value).format("YYYY-MM-DD");
			setPeriodEnd(formattedDate);
		} else {
			setPeriodEnd(null);
		}
		setCurrentPage(1);
	};

	const handleClearFilters = () => {
		setCurrentPage(1);
		setSearchTerm("");
		setSelectedTag("");
		setSelectedType("");
		setPeriodStart(null);
		setPeriodEnd(null);
	};

	return (
		<ItemComponent
			currentPage={currentPage}
			setCurrentPage={setCurrentPage}
			totalPages={totalPages}
			items={items}
			loading={loading}
			itemType="incentive"
			onAdd={handleAddIncentive}
			onEdit={handleEditIncentive}
			onDelete={handleDeleteIncentive}
			onFavorite={handleFavoriteIncentive}
			onDuplicate={handleDuplicateIncentive}
			onArchive={handleArchiveIncentive}
			onAction={handleAction}
			onReset={handleReset}
			searchTerm={searchTerm}
			onSearchChange={handleSearchChange}
			selectedTag={selectedTag}
			onTagChange={handleTagChange}
			periodStart={periodStart}
			periodEnd={periodEnd}
			onPeriodStartChange={handlePeriodStartChange}
			onPeriodEndChange={handlePeriodEndChange}
			selectedType={selectedType}
			onTypeChange={handleTypeChange}
			handleClearFilters={handleClearFilters}
		/>
	);
};
