import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	success: {
		color: theme.palette.success.main,
	},
	error: {
		color: theme.palette.error.main,
	},
	hoveredRow: {
		backgroundColor: theme.palette.action.hover,
	},
	emptyBox: {
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing(1),
		display: "flex",
		flex: 1,
	},
	headerCell: {
		minWidth: 155,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		marginRight: theme.spacing(1),
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	accountsResultsCell: {
		minWidth: 200,
	},
	firstHeaderCell: {
		minWidth: 155,
		zIndex: 3,
		backgroundColor: "#FAFAFA",
	},
	totalRow: {
		backgroundColor: "#E8E8E8",
		position: "sticky",
		bottom: 0,
		zIndex: 1,
		"& > *": {
			fontWeight: "bold",
			textTransform: "uppercase",
		},
	},
	buttonGroupContainer: {
		display: "flex",
		alignItems: "center",
		gap: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(0.5),
	},
	clickableRow: {
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
	},
	clickableHoveredRow: {
		"&:hover": {
			backgroundColor: "#F5F5F5",
			"& $stickyColumn, & $stickyColumnLeft": {
				backgroundColor: "#F5F5F5",
				"&:hover": {
					backgroundColor: "#E9E9E9",
				},
			},
		},
	},
	statusRed: {
		color: "red",
	},
	statusYellow: {
		color: "#F4C01D",
	},
	statusGreen: {
		color: "#4E915E",
	},
	tableCell: {
		minWidth: 105,
	},
	overflowHidden: {
		overflow: "hidden",
	},
	tableContainerHeight: {
		maxHeight: "76.5vh",
	},
	loading: {
		alignItems: "center",
		width: "16px",
	},
	loadingFlex: {
		display: "flex",
	},
	stickyColumn: {
		position: "sticky",
		left: 0,
		backgroundColor: theme.palette.background.paper,
		zIndex: 1,
		width: "150px",
		cursor: "pointer",
	},
	stickyColumnLeft: {
		position: "sticky",
		left: "150px",
		backgroundColor: theme.palette.background.paper,
		zIndex: 1,
		width: "150px",
		cursor: "pointer",
	},
	leadershipRow: {
		backgroundColor: "#e3e6f5",
	},
	teamLeadRow: {
		backgroundColor: "#EBEEF7",
	},
	clickableLeadershipRow: {
		backgroundColor: "#e3e6f5",
	},
	salesRepRow: {
		backgroundColor: "#FAFAFA",
	},
}));
