import { useState } from "react";
import { formatDate, formatTypeLabel } from "./utils";
import { Box, Collapse, Grid, IconButton, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Item } from "../../usersManagement/types/commonObjIncTypes";
import SubItemCard from "./SubItemCard";
import { useStyles } from "./ItemCard.styles";
import { capitalizeFirstLetter } from "../../usersManagement/utils";
import { PlaylistAddCheck } from "@material-ui/icons";

interface ItemCardProps<T extends Item> {
	item: T;
	handleItemSelect: (itemId: string) => void;
	subItems?: any[];
	groupedChildren?: any[];
	selectedItemsIds?: string[];
}

export const ItemCard = <T extends Item>({
	item,
	handleItemSelect,
	subItems,
	groupedChildren,
	selectedItemsIds,
}: ItemCardProps<T>) => {
	const classes = useStyles();
	const [isExpanded, setIsExpanded] = useState(false);
	const toggleExpand = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setIsExpanded(!isExpanded);
	};

	const handleSelect = () => {
		if (item.type === "multi" || item.type === "dependencies") {
			setIsExpanded(!isExpanded);
		} else {
			handleItemSelect(item._id);
		}
	};

	const handleSubSelect = (subItem: any, event?: React.MouseEvent<Element>) => {
		if (event) {
			event.stopPropagation();
		}
		handleItemSelect(subItem._id);
	};

	const checkAllSubItemsSelected = () => {
		if (item.type === "multi" || item.type === "dependencies") {
			if (subItems) {
				return subItems.every((sub) => selectedItemsIds?.includes(sub._id));
			}
		}
		return false;
	};

	const getBackgroundColor = (
		itemId: string,
		checkAllSubItemsSelected: () => boolean,
		selectedItemsIds?: string[]
	) => {
		if (item.type === "multi" || item.type === "dependencies") {
			return checkAllSubItemsSelected() ? "#e8eaf6" : "";
		} else {
			return selectedItemsIds?.includes(itemId) ? "#e8eaf6" : "";
		}
	};

	return (
		<Box
			className={classes.card}
			onClick={handleSelect}
			style={{
				backgroundColor: getBackgroundColor(
					item._id,
					checkAllSubItemsSelected,
					selectedItemsIds
				),
			}}
		>
			<div className={classes.mainBox}>
				<div>
					<div className={classes.title}>
						{capitalizeFirstLetter(item.name)}
					</div>
					{item.isGrouped && (
						<div className={classes.groupedItem}>
							<PlaylistAddCheck
								fontSize="small"
								style={{ fontWeight: "normal" }}
							/>{" "}
							<span>Part of a Group</span>
						</div>
					)}
				</div>
				<IconButton onClick={toggleExpand}>
					{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
				</IconButton>
			</div>
			<Collapse in={isExpanded} timeout="auto" unmountOnExit>
				<Box className={classes.expandedContent}>
					<Grid container spacing={2}>
						<Grid container spacing={2} className={classes.expandedText}>
							<Grid item xs={12}>
								<Typography variant="body2">
									<b>Type:</b> {formatTypeLabel(item.type)}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2">
									<b>Period:</b>{" "}
									{item.customPeriodStart &&
										formatDate(item.customPeriodStart, true)}{" "}
									-{" "}
									{item.customPeriodEnd &&
										formatDate(item.customPeriodEnd, true)}
								</Typography>
							</Grid>
						</Grid>
						{(item.type === "multi" || item.type === "dependencies") &&
							subItems && (
								<Grid item xs={12}>
									<Typography variant="subtitle2">Sub Items:</Typography>
									<Box className={classes.subItemContainer}>
										{subItems.map((sub) => (
											<SubItemCard
												key={sub._id}
												subItem={sub}
												handleSubSelect={handleSubSelect}
												isSelected={selectedItemsIds?.includes(sub._id)}
											/>
										))}
									</Box>
								</Grid>
							)}
						{item.type === "grouped" && groupedChildren && (
							<Grid item xs={12}>
								<Typography variant="subtitle2">Grouped Children:</Typography>
								<Box className={classes.subItemContainer}>
									{groupedChildren.map((gr) => (
										<SubItemCard
											key={gr._id}
											subItem={gr}
											handleSubSelect={handleSubSelect}
											isSelected={selectedItemsIds?.includes(gr._id)}
										/>
									))}
								</Box>
							</Grid>
						)}
					</Grid>
				</Box>
			</Collapse>
		</Box>
	);
};
