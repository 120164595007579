import { useState } from "react";
import { ItemsManagement } from "./ItemsManagement";

export const IncentivesReportingTabView = () => {
	const [isArchived, setIsArchived] = useState(false);

	return (
		<ItemsManagement
			type={'incentive'}
			isArchived={isArchived}
			setIsArchived={setIsArchived}
		/>
	);
};
