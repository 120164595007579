import React from "react";
import { FormLabel, TextField } from "@material-ui/core";
import { DividerFormControl } from "../../../common/DividerFormControl";
import { useStyles } from "./MinimumQuantityComponent.styles";
import { useFormContext } from "react-hook-form";
import { RootState } from "../../../../../app/store";
import { useSelector } from "react-redux";

interface MinimumQuantityComponentProps {
    minQuantityValue?: number;
    onMinQuantityValueChange: (value: number) => void;
}

const MinimumQuantityComponent: React.FC<MinimumQuantityComponentProps> = ({
    minQuantityValue,
    onMinQuantityValueChange,
}) => {
    const classes = useStyles();
    const { register, errors } = useFormContext();
	const isTeamLead = useSelector(
		(state: RootState) => state.session.role === "teamLead"
	);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value) || 0;
        onMinQuantityValueChange(value);
    };

    return (
        <DividerFormControl>
            <div className={classes.container}>
                <FormLabel className={classes.spacedFormLabel}>
                    Minimum Quantity:{" "}
                </FormLabel>
                <TextField
                    type="number"
                    name="minQuantityValue"
                    error={!!errors.minQuantityValue}
                    helperText={errors.minQuantityValue && errors.minQuantityValue.message}
                    defaultValue={minQuantityValue}
                    onChange={handleInputChange}
                    className={classes.input}
                    variant="outlined"
                    margin="dense"
                    placeholder="0"
                    disabled={isTeamLead}
                    inputRef={register({
                        validate: {
                            positiveNumber: value => {
                                return parseInt(value) >= 0 || "Minimum quantity must be equal to or greater than 0";
                            }
                        }
                    })}
                />
            </div>
        </DividerFormControl>
    );
};

export default MinimumQuantityComponent;
