import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import PublishIcon from "@material-ui/icons/Publish";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  EditableCellInput,
  EditableCellMultiselect,
} from "../editableCell/EditableCell";
import { userRoles, IRole } from "../../utils/roles";
import {
  patchFile,
  filePatched,
  uploadFile,
  downloadFile,
  deleteFile,
} from "./filesSlice";
import useQuery, { IFilesQuery } from "../../hooks/useQuery";
import { ChangeCircle } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  row: {
    height: 65,
  },
  nameCell: {
    width: "100%",
  },
  accessCell: {
    minWidth: 348,
    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
    },
  },
  buttonContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

export const smallCellSize = 73;

interface IProps {
  fileName: string;
  isArchive?: boolean;
}

export default function FilesRow(props: IProps) {
  const dispatch = useDispatch();
  const { query } = useQuery<IFilesQuery>();
  const filesQuery = { wholesalerId: query.wholesalerId };
  const file = useSelector(
    (state: RootState) => state.files.byId[props.fileName]
  );

  const patch = (patch: { name?: string; access?: IRole[] }) => {
    if (file.isNew) {
      dispatch(
        filePatched({
          key: file.name,
          updatedFile: {
            ...file,
            ...patch,
          },
        })
      );
    } else {
      dispatch(
        patchFile(filesQuery, {
          key: file.name,
          ...patch,
        })
      );
    }
  };

  const classes = useStyles();

  return (
    <TableRow className={classes.row} hover selected={file.isNew}>
      <TableCell align="center" width={smallCellSize}>
        {file.isNew ? (
          <IconButton
            size="small"
            onClick={() => dispatch(uploadFile(filesQuery, file))}
            edge="end"
          >
            {file.status === "uploading" ? (
              <CircularProgress size={20} />
            ) : (
              <PublishIcon color="secondary" />
            )}
          </IconButton>
        ) : (
          <IconButton
            size="small"
            onClick={() => dispatch(downloadFile(filesQuery, file.name))}
          >
            {file.status === "downloading" ? (
              <CircularProgress size={20} />
            ) : (
              <CloudDownloadIcon />
            )}
          </IconButton>
        )}
      </TableCell>
      <EditableCellInput
        className={classes.nameCell}
        defaultValue={file.name}
        defaultToEdit={!!file.isNew}
        onAcceptChanges={(val) => patch({ name: String(val) })}
      />
      {!props.isArchive && (
        <EditableCellMultiselect
          className={classes.accessCell}
          defaultValue={file.access}
          onAcceptChanges={(val) => patch({ access: val })}
          options={userRoles}
        />
      )}

      <TableCell align="right" width={smallCellSize}>
        <div className={classes.buttonContainer}>
          <IconButton
            size="small"
            onClick={() => dispatch(uploadFile(filesQuery, file))}
            edge="end"
          >
            <ChangeCircle />
          </IconButton>
          <IconButton
            size="small"
            onClick={() =>
              dispatch(
                deleteFile(filesQuery, { fileName: file.name, isNew: file.isNew })
              )
            }
            edge="end"
          >
            {file.status === "deleting" ? (
              <CircularProgress size={20} />
            ) : (
              <DeleteIcon />
            )}
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
}
