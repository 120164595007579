import {
	Checkbox,
	FormControlLabel,
	FormLabel,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { DividerFormControl } from "../../../common/DividerFormControl";
import { useStyles } from "../EditComponent.styles";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";

interface TargetComponentProps {
	name?: string;
	target?: number | string;
	isOpenEnded?: boolean;
	isAccountSpecific?: boolean;
	onTargetChange: (value: number) => void;
	onIsOpenEndedChange: (checked: boolean) => void;
	onIsAccountSpecificChange: (checked: boolean) => void;
	itemType?: string;
	isGrouped?: boolean;
	cappedStatus?: boolean;
	onCappedStatusChange: (checked: boolean) => void;
}

export const TargetComponent = ({
	name = "Target",
	target,
	isOpenEnded,
	isAccountSpecific,
	onTargetChange,
	onIsOpenEndedChange,
	onIsAccountSpecificChange,
	itemType,
	isGrouped,
	cappedStatus,
	onCappedStatusChange,
}: TargetComponentProps) => {
	const classes = useStyles();
	const { register, errors } = useFormContext();
	console.log("cappedStatus", cappedStatus);
	const isTeamLead = useSelector(
		(state: RootState) => state.session.role === "teamLead"
	);

	useEffect(() => {
		if (isGrouped) {
			if (isOpenEnded) {
				onIsOpenEndedChange(false);
			}
		}
		if (itemType !== "quantity") {
			if (isAccountSpecific) {
				onIsAccountSpecificChange(false);
			}
		}
	}, [
		isGrouped,
		isOpenEnded,
		onIsOpenEndedChange,
		itemType,
		onIsAccountSpecificChange,
		isAccountSpecific,
	]);

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onIsOpenEndedChange(event.target.checked);
	};

	const handleCappedStatusChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		console.log("cappedStatus", event.target.checked);
		onCappedStatusChange(event.target.checked);
	};

	const handleAccountSpecificCheckboxChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		onIsAccountSpecificChange(event.target.checked);
	};

	const handleTargetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = parseFloat(event.target.value);
		onTargetChange(newValue);
	};

	const helperText =
		errors.target && errors.target.message
			? errors.target.message
			: itemType === "imageValidation"
			? "Note: for Image Validation type, the target will be tied to the number of cases"
			: "";

	return (
		<DividerFormControl>
			<div className={classes.targetContainer}>
				<FormLabel className={classes.targetText}>{name}: </FormLabel>
				<div>
					{isOpenEnded && typeof target === "string" ? (
						<TextField
							disabled
							value="∞"
							variant="outlined"
							fullWidth
							margin="dense"
							className={classes.target}
						/>
					) : (
						<TextField
							required
							error={!!errors.target}
							helperText={helperText}
							margin="dense"
							variant="outlined"
							placeholder="0"
							type="number"
							fullWidth
							className={classes.target}
							name="target"
							defaultValue={target}
							disabled={isOpenEnded || isTeamLead}
							onChange={handleTargetChange}
							inputRef={register({
								validate: {
									positiveNumber: (value) => {
										if (isOpenEnded || isTeamLead) {
											return true;
										}
										return (
											parseFloat(value) > 0 || "Target must be greater than 0"
										);
									},
								},
							})}
						/>
					)}
				</div>
				{itemType === "quantity" && (
					<FormControlLabel
						control={
							<Tooltip
								title={
									isOpenEnded
										? "This option is disabled because Open-ended is selected."
										: ""
								}
								arrow
								disableHoverListener={!isOpenEnded}
							>
								<span>
									<Checkbox
										checked={isAccountSpecific}
										onChange={handleAccountSpecificCheckboxChange}
										disabled={isOpenEnded || isTeamLead}
									/>
								</span>
							</Tooltip>
						}
						label={
							<Typography
								variant="body2"
								color={isOpenEnded ? "textSecondary" : "textPrimary"}
							>
								Account specific
							</Typography>
						}
					/>
				)}
				<FormControlLabel
					control={
						<Tooltip
							title={
								isGrouped
									? "This option is disabled because the item is grouped."
									: isAccountSpecific
									? "This option is disabled because Account specific is selected."
									: ""
							}
							arrow
							disableHoverListener={!isGrouped && !isAccountSpecific}
						>
							<span>
								<Checkbox
									checked={isOpenEnded}
									onChange={handleCheckboxChange}
									disabled={isGrouped || isAccountSpecific || isTeamLead}
								/>
							</span>
						</Tooltip>
					}
					label={
						<Typography
							variant="body2"
							color={isGrouped ? "textSecondary" : "textPrimary"}
						>
							Open-ended
						</Typography>
					}
				/>
				<FormControlLabel
					control={
						<Tooltip
							title={
								isGrouped
									? "This option is disabled because the item is grouped."
									: ""
							}
							arrow
							disableHoverListener={!isGrouped}
						>
							<span>
								<Checkbox
									disabled={isGrouped}
									checked={cappedStatus}
									onChange={handleCappedStatusChange}
								/>
							</span>
						</Tooltip>
					}
					label={
						<Typography
							variant="body2"
							color={isGrouped ? "textSecondary" : "textPrimary"}
						>
							Capped Status
						</Typography>
					}
				/>
			</div>
		</DividerFormControl>
	);
};
