import { Checkbox, TableCell, TableRow } from "@material-ui/core";

interface ManualProductsListItemProps {
	product: any;
	selected?: boolean;
	handleCheck: (productId: string) => void;
	isTeamLead?: boolean;
}

export default function ManualProductsListItem({
	product,
	selected,
	handleCheck,
	isTeamLead
}: ManualProductsListItemProps) {

	return (
		<TableRow>
			<TableCell>
				<Checkbox
					checked={selected}
					onChange={() => handleCheck(product._id)}
					color="primary"
					disabled={isTeamLead}
				/>
			</TableCell>
			<TableCell>{product.name}</TableCell>
			<TableCell>{product.size}</TableCell>
			<TableCell>{product.supplier}</TableCell>
			<TableCell>{product.brand}</TableCell>
			<TableCell>{product.packageType}</TableCell>
			<TableCell>{product.tags}</TableCell>
			{/* <TableCell>{product.units}</TableCell> */}
			<TableCell>{product.brandFam ? product.brandFam : "-"}</TableCell>
		</TableRow>
	);
}
