import { FormGroup, FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@mui/material';
import { MeasureSymbol } from '../../types/commonObjIncTypes';
import { useStyles } from './MeasureComponent.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';

interface MeasureComponentProps {
	label?: string;
	checked?: boolean;
	symbol?: MeasureSymbol;
	onMeasureChange: (
		label?: string,
		checked?: boolean,
		symbol?: MeasureSymbol
	) => void;
}
export const MeasureComponent: React.FC<MeasureComponentProps> = ({
	label,
	checked,
	symbol,
	onMeasureChange,
}) => {
	const classes = useStyles();

	const isTeamLead = useSelector(
		(state: RootState) => state.session.role === "teamLead"
	);

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const isChecked = event.target.checked;
		onMeasureChange(label, isChecked, symbol);
	};

	return (
		<div style={{ padding: 0 }}>
			<FormGroup row className={classes.formGroup}>
				<FormControlLabel
					control={
						<Checkbox checked={checked} onChange={handleCheckboxChange} disabled={isTeamLead} />
					}
					label={label}
				/>
			</FormGroup>
		</div>
	);
};
