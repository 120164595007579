import React, { useState } from "react";
import useUsers, { UserSelection } from "../../../../hooks/useUsers";
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
} from "@material-ui/core";
import Select from "react-select";
import { useStyles } from "./UsersSelection.styles";
import { UserRole } from "../../utils";

type CustomUsersSelectionProps = {
	initialSelectedUserIds: UserRole[];
	onChange: (selectedUsers: UserRole[]) => void;
	hideFromSalesReps?: boolean;
	onCheckChange: (checked: boolean) => void;
};

export const CustomUsersSelection: React.FC<CustomUsersSelectionProps> = ({
	initialSelectedUserIds,
	onChange,
	hideFromSalesReps,
	onCheckChange,
}) => {
	const classes = useStyles();
	const { users, groupedUsers, createTeamMap, selectUserAndTeam } = useUsers();
	const teamMap = createTeamMap(users);

	const [includeTeamMembers, setIncludeTeamMembers] = useState(true);

	// Initialize selected states based on initialSelectedUserIds
	const [selectedLeadership, setSelectedLeadership] = useState<UserSelection[]>(
		() =>
			users.filter(
				(user) =>
					initialSelectedUserIds.some((id) => id.user === user.customId) &&
					user.leadership
			)
	);

	const [selectedTeamLead, setSelectedTeamLead] = useState<UserSelection[]>(
		() =>
			users.filter(
				(user) =>
					initialSelectedUserIds.some((id) => id.user === user.customId) &&
					user.role === "teamLead" &&
					!user.leadership
			)
	);

	const [selectedSalesRep, setSelectedSalesRep] = useState<UserSelection[]>(
		() =>
			users.filter(
				(user) =>
					initialSelectedUserIds.some((id) => id.user === user.customId) &&
					user.role === "salesRep"
			)
	);

	const [allSelected, setAllSelected] = useState(() => {
		const totalUserIds = [
			...groupedUsers.Leadership,
			...groupedUsers.TeamLead,
			...groupedUsers.SalesRep,
		].map((user) => user.customId);

		return totalUserIds.every((id) =>
			initialSelectedUserIds.some((user) => user.user === id)
		);
	});

	// Handle selecting leadership
	const handleLeadershipSelectChange = (selectedOptions: any) => {
		const selectedIds = selectedOptions.map((option: any) => option.value);

		if (includeTeamMembers) {
			// Recursive selection logic
			const allSelectedUsers = new Set<string>();
			selectedIds.forEach((id: string) => {
				const selectedUsers = selectUserAndTeam(id, teamMap);
				selectedUsers.forEach((userId) => allSelectedUsers.add(userId));
			});

			const newSelectedLeaders = users.filter((user) =>
				selectedIds.includes(user.customId)
			);
			const newSelectedTeamLeads = users.filter(
				(user) =>
					!selectedIds.includes(user.customId) &&
					Array.from(allSelectedUsers).includes(user.customId) &&
					user.role === "teamLead"
			);
			const newSelectedSalesReps = users.filter(
				(user) =>
					!selectedIds.includes(user.customId) &&
					Array.from(allSelectedUsers).includes(user.customId) &&
					user.role === "salesRep"
			);

			setSelectedLeadership(newSelectedLeaders);
			setSelectedTeamLead(newSelectedTeamLeads);
			setSelectedSalesRep(newSelectedSalesReps);

			updateAllSelectedState(
				newSelectedLeaders,
				newSelectedTeamLeads,
				newSelectedSalesReps
			);
		} else {
			// Individual selection logic
			const newSelectedLeaders = users.filter((user) =>
				selectedIds.includes(user.customId)
			);
			setSelectedLeadership(newSelectedLeaders);
			updateAllSelectedState(
				newSelectedLeaders,
				selectedTeamLead,
				selectedSalesRep
			);
		}
	};

	// Handle selecting team leads
	const handleTeamLeadSelectChange = (selectedOptions: any) => {
		const selectedIds = selectedOptions.map((option: any) => option.value);

		if (includeTeamMembers) {
			// Recursive selection logic
			const allSelectedUsers = new Set<string>();
			selectedIds.forEach((id: string) => {
				const selectedUsers = selectUserAndTeam(id, teamMap);
				selectedUsers.forEach((userId) => allSelectedUsers.add(userId));
			});

			const newSelectedTeamLeads = users.filter((user) =>
				selectedIds.includes(user.customId)
			);
			const newSelectedSalesReps = users.filter(
				(user) =>
					!selectedIds.includes(user.customId) &&
					Array.from(allSelectedUsers).includes(user.customId) &&
					user.role === "salesRep"
			);

			setSelectedTeamLead(newSelectedTeamLeads);
			setSelectedSalesRep(newSelectedSalesReps);

			updateAllSelectedState(
				selectedLeadership,
				newSelectedTeamLeads,
				newSelectedSalesReps
			);
		} else {
			// Individual selection logic
			const newSelectedTeamLeads = users.filter((user) =>
				selectedIds.includes(user.customId)
			);
			setSelectedTeamLead(newSelectedTeamLeads);
			updateAllSelectedState(
				selectedLeadership,
				newSelectedTeamLeads,
				selectedSalesRep
			);
		}
	};

	// Handle selecting sales reps
	const handleSalesRepSelectChange = (selectedOptions: any) => {
		const selectedIds = selectedOptions.map((option: any) => option.value);
		const newSelectedSalesReps = users.filter((user) =>
			selectedIds.includes(user.customId)
		);

		setSelectedSalesRep(newSelectedSalesReps);
		updateAllSelectedState(
			selectedLeadership,
			selectedTeamLead,
			newSelectedSalesReps
		);
	};

	const handleIncludeTeamMembersChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const checked = event.target.checked;
		setIncludeTeamMembers(checked);

		if (!checked) {
			// Keep current selections but switch to individual mode
			updateAllSelectedState(
				selectedLeadership,
				selectedTeamLead,
				selectedSalesRep
			);
		} else {
			// Reapply recursive selection for current selections
			const allSelectedUsers = new Set<string>();

			// Get all team members for selected leadership
			selectedLeadership.forEach((leader) => {
				const selectedUsers = selectUserAndTeam(leader.customId, teamMap);
				selectedUsers.forEach((userId) => allSelectedUsers.add(userId));
			});

			// Get all team members for selected team leads
			selectedTeamLead.forEach((teamLead) => {
				const selectedUsers = selectUserAndTeam(teamLead.customId, teamMap);
				selectedUsers.forEach((userId) => allSelectedUsers.add(userId));
			});

			// Update selections based on recursive relationships
			const newSelectedTeamLeads = users.filter(
				(user) =>
					!selectedLeadership.map((l) => l.customId).includes(user.customId) &&
					Array.from(allSelectedUsers).includes(user.customId) &&
					user.role === "teamLead"
			);

			const newSelectedSalesReps = users.filter(
				(user) =>
					!selectedLeadership.map((l) => l.customId).includes(user.customId) &&
					!selectedTeamLead.map((tl) => tl.customId).includes(user.customId) &&
					Array.from(allSelectedUsers).includes(user.customId) &&
					user.role === "salesRep"
			);

			setSelectedTeamLead(newSelectedTeamLeads);
			setSelectedSalesRep(newSelectedSalesReps);
			updateAllSelectedState(
				selectedLeadership,
				newSelectedTeamLeads,
				newSelectedSalesReps
			);
		}
	};

	// Update all selected state
	const updateAllSelectedState = (
		leadership: UserSelection[],
		teamLeads: UserSelection[],
		salesReps: UserSelection[]
	) => {
		const totalUserIds = [
			...groupedUsers.Leadership,
			...groupedUsers.TeamLead,
			...groupedUsers.SalesRep,
		].map((user) => user.customId);

		const selectedUserIds = [
			...leadership.map((user) => user.customId),
			...teamLeads.map((user) => user.customId),
			...salesReps.map((user) => user.customId),
		];

		setAllSelected(totalUserIds.every((id) => selectedUserIds.includes(id)));

		onChange(
			selectedUserIds.map((id) => ({
				user: id,
				isTeamLead: teamLeads.some((user) => user.customId === id),
				isLeadership: leadership.some((user) => user.customId === id),
			}))
		);
	};

	// Handle select all/remove all
	const handleSelectAllOrRemoveAll = () => {
		if (allSelected) {
			setSelectedLeadership([]);
			setSelectedTeamLead([]);
			setSelectedSalesRep([]);
			onChange([]);
		} else {
			const allLeadership = groupedUsers.Leadership.map((leader) => ({
				...leader,
				leadership: true,
			}));
			const allTeamLead = groupedUsers.TeamLead.map((teamLead) => ({
				...teamLead,
				leadership: true,
			}));
			const allSalesRep = groupedUsers.SalesRep.map((salesRep) => ({
				...salesRep,
				leadership: true,
			}));

			setSelectedLeadership(allLeadership);
			setSelectedTeamLead(allTeamLead);
			setSelectedSalesRep(allSalesRep);

			updateAllSelectedState(allLeadership, allTeamLead, allSalesRep);
		}
	};
	
	const getLastName = (fullName: string) => {
		const nameParts = fullName.split(" ");
		return nameParts.length > 1 ? nameParts[nameParts.length - 1] : nameParts[0];
	};

	const sortUsers = (users: UserSelection[], includeRoutes: boolean = false) =>
		users.sort((a, b) => getLastName(a.name).localeCompare(getLastName(b.name)))
			.map((user) => ({
				value: user.customId,
				label: includeRoutes ? `${user.name} - ${user?.routeNumber?.join(", ") || ""}` : user.name,
			}));

	return (
		<div className={classes.container}>
			<div className={classes.buttons}>
				<Button
					variant="outlined"
					color="primary"
					onClick={handleSelectAllOrRemoveAll}
					size="small"
					className={classes.button}
				>
					{allSelected ? "Remove All" : "Select All"}
				</Button>
				<FormControlLabel
					control={
						<Checkbox
							checked={hideFromSalesReps}
							onChange={(e) => onCheckChange(e.target.checked)}
						/>
					}
					label="Hide from sales reps"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={includeTeamMembers}
							onChange={handleIncludeTeamMembersChange}
							color="primary"
						/>
					}
					label="Include team members"
				/>
			</div>
			<FormControl variant="outlined" className={classes.marginTop}>
				<Select
					isMulti
					name="leadership"
					placeholder="Select Leadership"
					options={sortUsers(groupedUsers.Leadership)}
					value={sortUsers(selectedLeadership)}
					onChange={handleLeadershipSelectChange}
				/>
			</FormControl>
			<FormControl variant="outlined" className={classes.marginTop}>
				<Select
					isMulti
					name="teamLead"
					placeholder="Select Team Lead"
					options={sortUsers(groupedUsers.TeamLead)}
					value={sortUsers(selectedTeamLead)}
					onChange={handleTeamLeadSelectChange}
				/>
			</FormControl>
			<FormControl variant="outlined" className={classes.marginTop}>
				<Select
					isMulti
					name="salesRep"
					placeholder="Select Sales Rep"
					options={sortUsers(groupedUsers.SalesRep, true)}
					value={sortUsers(selectedSalesRep, true)}
					onChange={handleSalesRepSelectChange}
				/>
			</FormControl>
		</div>
	);
};
