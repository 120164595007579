import React, { useState } from "react";

import SpacingBoxView from "../spacingBox/SpacingBoxView";
import { makeStyles } from "@material-ui/core/styles";
import UserManagementSummary from "./UserManagementSummary";
import { styled } from "@mui/system";
import { FavoriteComponent } from "./favoritesComponent/FavoritesComponent";
import { ArchiveComponent } from "./archiveComponent/ArchiveComponent";
import { ObjectivesComponent } from "./objectivesIncentivesComponent/objectivesWrapper/ObjectivesComponent";
import { IncentivesComponent } from "./objectivesIncentivesComponent/incentivesWrapper/IncentivesComponent";
import { PodcastsComponent } from "./podcastLinksComponent/PodcastsComponent";
import useFetchTags from "./hooks/useFetchTags";
import useFetchProductsFilters from "./hooks/useFetchProductsFilters";
import useFetchDistinctAccounts from "./hooks/useFetchDistinctAccounts";
import useFetchUsers from "./hooks/useFetchUsers";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

const useStyles = makeStyles((theme) => ({
	summaryContainer: {
		display: "flex",
		flexDirection: "row-reverse",
		[theme.breakpoints.down("sm")]: {
			display: "block",
		},
	},
	container: {
		width: "100%",
		marginLeft: "auto",
		marginRight: "auto",
	},
}));

const TabContainer = styled("div")({
	display: "flex",
	width: "50%",
	border: "1px solid #ccc",
	borderRadius: "4px",
	marginBottom: "20px",
});

const TabButton = styled("button")(({ active }: { active: boolean }) => ({
	flex: 1,
	padding: "10px 15px",
	border: "none",
	backgroundColor: active ? "#7986cb" : "white",
	color: active ? "white" : "black",
	outline: "none",
	cursor: "pointer",
	transition: "background-color 0.3s",
	"&:hover": {
		backgroundColor: "#f0f0f0",
	},
}));

export default function UserManagementView() {
	const classes = useStyles();

	const isTeamLead = useSelector(
		(state: RootState) => state.session.role === "teamLead"
	);

	useFetchTags();
	useFetchDistinctAccounts();
	useFetchProductsFilters();
	useFetchUsers();

	const ref = React.useRef(null);
	const [activeTab, setActiveTab] = useState<string>("Objectives");

	const tabs = isTeamLead 
		? ["Objectives", "Incentives", "Archive", "Favorites"] 
		: ["VxP", "Objectives", "Incentives", "Archive", "Favorites", "Podcast"];

	const onTabClick = (tab: string) => {
		setActiveTab(tab);
	};

	const renderTabContent = () => {
		switch (activeTab) {
			case "VxP":
				return <UserManagementSummary />;
			case "Objectives":
				return <ObjectivesComponent />;
			case "Incentives":
				return <IncentivesComponent />;
			case "Archive":
				return <ArchiveComponent />;
			case "Favorites":
				return <FavoriteComponent />;
			case "Podcast":
				return <PodcastsComponent />;
			default:
				return null;
		}
	};
	return (
		<>
			<div className={classes.container} id="usersManagementRoot" ref={ref}>
				<TabContainer>
					{tabs.map((tab: string) => (
						<TabButton
							key={tab}
							active={activeTab === tab}
							onClick={() => onTabClick(tab)}
						>
							{tab}
						</TabButton>
					))}
				</TabContainer>
				<div className={classes.summaryContainer}>{renderTabContent()}</div>
				<SpacingBoxView />
			</div>
		</>
	);
}
