import { useState } from "react";
import {
	Box,
	Button,
	TextField,
	Typography,
	FormControlLabel,
	Switch,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
	Item,
	ManualProductsFilters,
} from "../../../../types/commonObjIncTypes";
import { gpRanks, trends, volumeRanks } from "../../../../../../interfaces";
import { MultiSelect, SingleSelect } from "../../../../../select/Select";
import { SelectionControls } from "../../selectComponent/SelectionControls";
import { useStyles } from "./ProductsSelection.styles";
import AllProductsComponent from "./AllProducts";
import SelectedProductsComponent from "./SelectedProducts";
import useProductsFilters from "../../../../hooks/useProductsFilters";

interface ManualProductsSelectionProps {
	item: Item;
}

export default function ManualProductsSelection({
	item,
}: ManualProductsSelectionProps) {
	const classes = useStyles();
	const { distinctProducts } = useProductsFilters();

	const isTeamLead = useSelector(
		(state: RootState) => state.session.role === "teamLead"
	);

	const [filters, setFilters] = useState<ManualProductsFilters>({});
	const [isReseted, setIsReseted] = useState<boolean>(false);
	const [showSelected, setShowSelected] = useState<boolean>(false);
	const [allSelected, setAllSelected] = useState<boolean>(false);
	const [clearSelection, setClearSelection] = useState<boolean>(false);

	const isAdmin = useSelector(
		(state: RootState) => state.session.role === "admin"
	);

	const handleShowSelectedChange = () => {
		setShowSelected((prev) => !prev);
		setAllSelected(false);
	};

	const handleSearchChange = (filterName: keyof ManualProductsFilters) => (event: any) => {
		setFilters({ ...filters, [filterName]: event.target.value });
	};

	const onSelect =
		(filterName: keyof ManualProductsFilters) => (selected: any) => {
			setFilters({
				...filters,
				[filterName]: selected,
			});
		};

	const handleReset = () => {
		setFilters({});
		setIsReseted(true);
	};

	const handleSelectAll = () => {
		setAllSelected(true);
		setClearSelection(false);
	};

	const handleClearSelection = () => {
		setClearSelection(true);
		setAllSelected(false);
	};
	return (
		<div className={classes.manualContainer}>
			<Box className={classes.flexColumn}>
				<div className={classes.flexColumn}>
					{!isTeamLead && (
						<SelectionControls
							onSelectAll={handleSelectAll}
							onClear={handleClearSelection}
						/>
					)}
					<TextField
						className={classes.inputWrapper}
						label="Search by title"
						size="small"
						variant="outlined"
						defaultValue={filters.searchPhrase}
						onChange={handleSearchChange("searchPhrase")}
					/>
					<TextField
						className={classes.inputWrapper}
						label="Search by id"
						size="small"
						variant="outlined"
						defaultValue={filters.searchId}
						onChange={handleSearchChange("searchId")}
					/>
					{!isTeamLead && (
						<FormControlLabel
							control={
								<Switch
									checked={showSelected}
									onChange={handleShowSelectedChange}
									color="primary"
								/>
							}
							label="Show Selected"
						/>
					)}
				</div>
				<div>
					<Typography variant="body1" className={classes.text}>
						Filters:
					</Typography>
					<div className={classes.filters}>
						<MultiSelect
							label="Include Brands"
							options={distinctProducts.brands}
							selected={filters.brand}
							onSelect={onSelect("brand")}
						/>
						<MultiSelect
							label="Exclude Brands"
							options={distinctProducts.brands}
							selected={filters.noBrand}
							onSelect={onSelect("noBrand")}
						/>
						<MultiSelect
							label="GP Rank"
							options={gpRanks}
							selected={filters.gpRank}
							onSelect={onSelect("gpRank")}
						/>
						<SingleSelect
							label="Package Type"
							options={distinctProducts.packageTypes}
							defaultValue={filters.packageType}
							onSelect={onSelect("packageType")}
							isReseted={isReseted}
						/>
						<MultiSelect
							label="Size"
							options={distinctProducts.sizes}
							selected={filters.size}
							onSelect={onSelect("size")}
						/>
						<MultiSelect
							label="Include Supplier Families"
							options={distinctProducts.suppliers}
							selected={filters.supplier}
							onSelect={onSelect("supplier")}
						/>
						<MultiSelect
							label="Exclude Supplier Families"
							options={distinctProducts.suppliers}
							selected={filters.noSupplier}
							onSelect={onSelect("noSupplier")}
						/>
						<MultiSelect
							label="Tags"
							options={distinctProducts.productTypes}
							selected={filters.tags}
							onSelect={onSelect("tags")}
						/>
						<SingleSelect
							label="Trend"
							options={trends}
							defaultValue={filters.trend}
							onSelect={onSelect("trend")}
							isReseted={isReseted}
						/>
						<MultiSelect
							label="Volume Rank"
							options={volumeRanks}
							selected={filters.volumeRank}
							onSelect={onSelect("volumeRank")}
						/>
						{/* <MultiSelect
							label="Units"
							options={units}
							selected={filters.units}
							onSelect={onSelect("units")}
						/> */}
						<MultiSelect
							label="Brand Family"
							options={distinctProducts.brandFams}
							selected={filters.brandFams}
							onSelect={onSelect("brandFams")}
						/>
						{isAdmin && (
							<>
								<SingleSelect
									label="Sponsored"
									options={[
										{ label: "Yes", key: "true" },
										{ label: "No", key: "false" },
									]}
									defaultValue={filters.sponsored}
									onSelect={onSelect("sponsored")}
									isReseted={isReseted}
								/>
								<SingleSelect
									label="Excluded"
									options={[
										{ label: "Yes", key: "true" },
										{ label: "No", key: "false" },
									]}
									defaultValue={filters.excluded}
									onSelect={onSelect("excluded")}
									isReseted={isReseted}
								/>
							</>
						)}
						<Button className={classes.button} onClick={handleReset}>
							Reset Filters
						</Button>
					</div>
				</div>
			</Box>
			{(showSelected || isTeamLead) ? (
				<SelectedProductsComponent
					item={item}
					filters={filters}
					allSelected={allSelected}
					clearSelection={clearSelection}
					setAllSelected={setAllSelected}
					setClearSelection={setClearSelection}
				/>
			) : (
				<AllProductsComponent
					item={item}
					filters={filters}
					allSelected={allSelected}
					clearSelection={clearSelection}
					setAllSelected={setAllSelected}
					setClearSelection={setClearSelection}
				/>
			)}
		</div>
	);
}
